export enum WithSidebarPaths {
  Blank = '',
  Loading = '/cms/loading',
  Home = '/cms/home',
  BeesCustomer = '/cms/banners-launch-list/customer',
  BeesPartner = '/cms/banners-launch-list/partner',
  MiNegocio = '/cms/mi-negocio-content-list',
  ZoneConfiguration = '/cms/zone-configuration',
  Audience = '/audiences?origin=cms',
  DeepLinkGenerator = 'https://anheuserbuschinbev.sharepoint.com/:x:/r/sites/CMTS2/Shared%20Documents/03.%20%20Capabilities/2.0%20CDC/2.0%20MagicBox%20Outputs/02.%20Digital%20Comms/21.%20Excel%20files/BEES%20Content%20Generator/Deep%20link%20Generator%20-%20Bees%20Content%20-%20Currently%20Used.xlsm?d=w49f11d51c315472f8d4700dc12706084&csf=1&web=1&e=l65wJJ',
  MonitoringDashboard = '/cms/monitoring-dashboard'
}

export enum WithSidebarIds {
  Home = 'home',
  BeesCustomer = 'bees-customer',
  BeesPartner = 'bees-partner-storefront',
  MiNegocio = 'mi-negocio',
  ZoneConfiguration = 'zone-configuration',
  Audience = 'audience',
  DeepLinkGenerator = 'deep-link-generator',
  MonitoringDashboard = 'monitoring-dashboard'
}

export enum WithSidebarQueryKeys {
  BannersList = 'sidebar-banners-list'
}
