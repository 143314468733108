import { CmsBaseUrl, EndpointFunction } from '@Utils/getEndpoints/getEndpointsDEPRECATED.types'

type GetUsers = CmsBaseUrl & { zone: string }

const getUsers: EndpointFunction = (builder) => ({
  mock: '',
  real: builder.query<User[], GetUsers>({
    query: ({ cmsBaseUrl, zone }) =>
      `${cmsBaseUrl}/contentedit/zone_${zone.toLowerCase()}/page-user-email-list.json`,
    providesTags: ['Users'],
    transformResponse: ({ data }: { data: User[] }) => data
  })
})

export default getUsers
