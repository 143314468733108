import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
  }

  body,
  input,
  textarea,
  button,
  label {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text?.primary};
  }

  h1,
  h2,
  h3, 
  h4,
  h5,
  h6,
  strong {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.typography.lineHeightMd}
  }

  button {
    cursor: pointer;
  }

  img {
    display: block;
  }

  [disabled] {
    color: ${({ theme }) => theme.palette.text?.disabled};
    opacity: 0.6;
    cursor: not-allowed;
  }

`
