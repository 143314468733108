import { useQuery } from '@tanstack/react-query'
import { UseQuery } from '../api.types'
import { useApi } from '../hooks/useApi'
import { useApplicationInfo } from '../hooks/useApplicationInfo'
import { useBuildQueryKeys } from '../hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'
import { queryFn } from '../utils/queryFn'
/**
 * TODO
 * Properly move these types once we get rid of the backend's legacy vendors api
 */
export type Vendor = {
  vendorId: string
  vendorName: string
  country: string
}

type VendorsListQueryResponse = { data?: Array<Vendor> }

type VendorResponse = {
  vendorsList?: Array<Vendor>
  isVendorsApiResponseLoading?: boolean
}

export const useVendorsListQuery = (
  { queryKeys, options }: UseQuery<VendorsListQueryResponse, Error>,
  queryParams?: { restricted: boolean }
): VendorResponse => {
  /**
   * TODO Remove the `zone` variable as it's unecessarily calling the `useApplicationInfo`
   * A separate PR will remove the `zone-cluster`, but a chart analysis must be done first
   *
   * Relates to https://ab-inbev.atlassian.net/browse/BEESCAD-13748
   */
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()
  const { api } = useApi()

  const itemsListQueryKeys = useBuildQueryKeys(queryKeys)

  const apiResponse = useQuery<VendorsListQueryResponse, Error>(
    itemsListQueryKeys,
    queryFn(api, `${cmsCoreBackend}/contentedit/vendors/zone_${zone.toLowerCase()}`, {
      headers: { ...headers, zone },
      params: queryParams
    }),
    options
  )

  return {
    vendorsList: apiResponse.data?.data,
    isVendorsApiResponseLoading: apiResponse.isLoading
  }
}
