import { Paragraph } from '@hexa-ui/components'
import { AlertOctagon } from '@hexa-ui/icons'
import Quill from 'quill'
import React, { useCallback, useState } from 'react'
import {
  ErrorTextStyles,
  LabelTextStyles,
  StyledRichHTMLLabelWrapper,
  StyledRichHTMLWrapper,
  StyledWrapper
} from './RichTextEditor.styles'
import { RichTextEditorProps } from './RichTextEditor.types'
import { useInitialValue } from './hooks/useInitialValue'
import { useOnTextChange } from './hooks/useOnTextChange'
import { addHighlightColorListener } from './utils/addHighlightColorListener'
import { createColorsPreviews } from './utils/createColorsPreviews'
import { hideLinkTooltipOnScroll } from './utils/hideLinkTooltipOnScroll'
import { parseToolbarContent } from './utils/parseToolbarContent'
import { colors, registerColors } from './utils/registerColors'
import { registerCustomBlockquote } from './utils/registerCustomBlockquote'
import { registerCustomIcons } from './utils/registerCustomIcons'
import { registerCustomLink } from './utils/registerCustomLink'
import { registerCustomList } from './utils/registerCustomList'
import { fontFamilies, registerFontFamilies } from './utils/registerFontFamilies'
import { fontSizes, registerFontSizes } from './utils/registerFontSizes'
import { registerInlineStyles } from './utils/registerInlineStyles'
import { setFontFamiliesPreviews } from './utils/setFontFamiliesPreviews'
import { updateCounter } from './utils/updateCounter'

import 'quill/dist/quill.snow.css'

registerInlineStyles()
registerCustomLink()
registerFontFamilies()
registerFontSizes()
registerColors()
registerCustomIcons()
registerCustomBlockquote()
registerCustomList()

const toolbarDefaultOptions: RichTextEditorProps['toolbarOptions'] = [
  [
    { font: fontFamilies },
    { size: fontSizes },
    { align: ['', 'right', 'center', 'justify'] },
    { color: colors },
    { background: colors }
  ],
  ['bold', 'underline', 'italic', 'strike'],
  ['link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['blockquote'],
  ['clean']
]

export const RichTextEditor = ({
  initialValue,
  onChange,
  height,
  maxWidth,
  placeholder = 'Insert your text here',
  toolbarOptions = toolbarDefaultOptions,
  maxCharacters,
  resizable,
  disabled,
  error,
  label,
  optionalText
}: RichTextEditorProps) => {
  const [quillInstance, setQuillInstance] = useState<Quill | null>(null)

  useInitialValue({ quillInstance, initialValue })
  useOnTextChange({ quillInstance, onChange })

  const editorRef = useCallback(
    (wrapper: HTMLDivElement) => {
      if (!wrapper) return

      wrapper.innerHTML = ''

      const toolbarContainer = document.createElement('div')
      const editorContainer = document.createElement('div')
      const counterContainer = document.createElement('div')

      toolbarContainer.innerHTML = parseToolbarContent(toolbarOptions)
      counterContainer.innerText = `0/${maxCharacters}`
      counterContainer.classList.add('ql-counter')

      wrapper.appendChild(toolbarContainer)
      wrapper.appendChild(editorContainer)
      wrapper.appendChild(counterContainer)

      if (maxCharacters) {
        Quill.register('modules/counter', function register(quill: Quill) {
          quill.on('text-change', function onTextChange() {
            updateCounter(quill, maxCharacters, counterContainer)
          })
        })
      }

      const quillInstance = new Quill(editorContainer, {
        debug: 'off',
        theme: 'snow',
        placeholder,
        modules: {
          toolbar: {
            container: toolbarContainer
          },
          ...(maxCharacters && { counter: true })
        }
      })

      setFontFamiliesPreviews()
      hideLinkTooltipOnScroll()
      addHighlightColorListener(quillInstance)
      createColorsPreviews()

      quillInstance.enable(!disabled)

      setQuillInstance(quillInstance)

      return quillInstance
    },
    [disabled, maxCharacters, placeholder, toolbarOptions]
  )

  return (
    <StyledWrapper maxWidth={maxWidth}>
      {label && (
        <StyledRichHTMLLabelWrapper className="rich-html-editor-label">
          <Paragraph size="small" css={LabelTextStyles} colortype={error ? 'error' : 'primary'}>
            {label}
          </Paragraph>
          {optionalText && (
            <Paragraph size="xsmall" colortype="disabled">
              {optionalText}
            </Paragraph>
          )}
        </StyledRichHTMLLabelWrapper>
      )}
      <StyledRichHTMLWrapper
        className="rich-html-editor-wrapper"
        ref={editorRef}
        maxWidth={maxWidth}
        height={height}
        maxCharacters={maxCharacters}
        resizable={resizable}
        error={!!error}
      />
      {error && (
        <Paragraph
          size="small"
          colortype="error"
          className="rich-html-editor-error"
          css={ErrorTextStyles}
        >
          <AlertOctagon size="tiny" />
          {error}
        </Paragraph>
      )}
    </StyledWrapper>
  )
}
