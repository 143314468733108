import { useGetEnv } from '@Hooks/useGetEnv/useGetEnv'
import { Environment } from '@Src/types/environment'
import { useQuery } from '@tanstack/react-query'
import { UseQuery } from '../api.types'
import { useApi } from '../hooks/useApi'
import { useBuildQueryKeys } from '../hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'
import { queryFn } from '../utils/queryFn'

export interface UseDeepLinkStoresListQuery {
  data: {
    content: { storeId: string; name: string }[]
    pagination: {
      page: number
      pageSize: number
      totalElements: number
      totalPages: number
    }
  }
}

const getServiceUrl = (env: Environment) => {
  if (env !== Environment.PRODUCTION) {
    return `https://services-${env}.bees-platform.dev/api/deeplink-service/v1/stores`
  }

  return 'https://services.bees-platform.com/api/deeplink-service/v1/stores'
}

export const useDeepLinkStoresListQuery = ({
  queryKeys,
  options,
  vendorId,
  country
}: UseQuery<UseDeepLinkStoresListQuery, Error> & { vendorId: string; country: string }) => {
  const { headers } = usePrepareHeaders()
  const env = useGetEnv()
  const { api } = useApi()

  const deeplinkStoresListQueryKeys = useBuildQueryKeys(queryKeys)

  const query = useQuery<UseDeepLinkStoresListQuery, Error>(
    deeplinkStoresListQueryKeys,
    queryFn(api, getServiceUrl(env), {
      headers: { ...headers, vendorId, country }
    }),
    options
  )

  return query
}
