import globalVars from '@Utils/constants'
import { handleOnRowClickProps } from '../ContentList.types'

export const handleOnRowClick = (props: handleOnRowClickProps) => {
  const { data, guid, navigate } = props

  const bannerTypes = [
    'brand-carousel',
    'mobile-banners',
    'streamlined-banners',
    'guest-hero-carousel',
    'hero-carousel'
  ]
  const targetLaunch = data?.find((launch: any) => {
    const { guid: launchGuid } = launch
    return launchGuid === guid
  })

  if (bannerTypes.includes(String(targetLaunch?.path))) {
    return navigate(`/banners-launch-list/edit-launch/${guid}?action=${globalVars.editAction}`)
  }

  navigate(`/web-view/web-view-editor/${guid}?action=${globalVars.viewAction}`)
}
