import Quill from 'quill'
import { toggleCounterErrorClass } from './toggleCounterErrorClass'

export const updateCounter = (
  quill: Quill,
  maxCharacters: number,
  counterContainer: HTMLDivElement
) => {
  const text = quill.getText()
  const endOfLine = '\n'
  const textLength = text.length - endOfLine.length

  counterContainer.innerText = `${textLength}/${maxCharacters}`
  toggleCounterErrorClass(textLength > maxCharacters, counterContainer)
}
