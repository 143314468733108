import { Box, Tab } from '@BarrelComponents'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { StyledTabs } from './Tabs.styles'
import { TabsProps } from './Tabs.types'

export const Tabs = ({
  tabs,
  boxSx,
  isSimple = true,
  tabProps,
  value,
  setValue,
  tabsIcons,
  tabSx,
  ...props
}: Omit<TabsProps, 'children' | 'orientation'>) => {
  const tabsId = isSimple ? 'simple-tab-container' : 'vertical-tab-container'
  const tabId = isSimple ? 'simple-tab' : 'vertical-tab'
  const tabPanelId = isSimple ? 'simple-tabpanel' : 'vertical-tabpanel'

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  const { palette } = useContext(ThemeContext)

  return (
    <Box sx={boxSx}>
      <StyledTabs
        {...props}
        value={value}
        onChange={handleChange}
        aria-label={tabsId}
        orientation={isSimple ? 'horizontal' : 'vertical'}
        className={tabsId}
      >
        {tabs?.map(({ value: tabValue, label }, index) => (
          <Tab
            {...tabProps}
            key={tabValue}
            id={`${tabId}-${index}`}
            className={`${tabId}-${index}`}
            iconPosition="end"
            aria-controls={`${tabPanelId}-${index}`}
            value={tabValue}
            label={label}
            sx={{
              backgroundColor: value === tabValue ? palette.grey![100] : 'none',
              ...tabSx
            }}
            icon={tabsIcons && tabsIcons[index]}
          />
        ))}
      </StyledTabs>
    </Box>
  )
}
