import { UseQuery } from '@Api/api.types'
import { useApplicationInfo } from '@Api/hooks/useApplicationInfo'
import { useBuildQueryKeys } from '@Api/hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '@Api/hooks/usePrepareHeaders'
import { queryFn } from '@Api/utils/queryFn'
import { useApi } from '@Src/shared/api/hooks/useApi'
import { useQuery } from '@tanstack/react-query'

export interface IMiNegocioCategoriesResponse {
  data: ICategoriesData[]
}

export interface ICategoriesData {
  id: number
  createdAt: string
  updatedAt: string | null
  updatedBy: string | null
  name: string
  isEnabled: boolean
}

export const useGetCategoriesQuery = ({
  queryKeys,
  options
}: UseQuery<IMiNegocioCategoriesResponse, Error>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()
  const { api } = useApi()

  const itemsListQueryKeys = useBuildQueryKeys([...queryKeys])

  const response = useQuery<IMiNegocioCategoriesResponse, Error>(
    itemsListQueryKeys,
    queryFn(
      api,
      `${cmsCoreBackend}/contentedit/zone_${zone.toLowerCase()}/metadata/user-categories`,
      {
        headers
      }
    ),
    options
  )

  return response
}
