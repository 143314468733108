import Quill from 'quill'

export const fontFamilies = [
  'Archivo',
  'Cabin',
  'Crimson Text',
  'DM Sans',
  'Exo 2',
  'Fira Sans',
  'IBM Plex Sans',
  'Inconsolata',
  'Inter',
  'Josefin Sans',
  'Karla',
  'Lato',
  'Lora',
  'Merriweather',
  'Montserrat',
  'Montserrat Alternates',
  'Muli',
  'Noto Sans',
  'Nunito',
  'Open Sans',
  'Oswald',
  'Playfair Display',
  'Poppins',
  'PT Sans',
  'Quicksand',
  'Raleway',
  'Roboto',
  'Roboto Condensed',
  'Rubik',
  'Source Sans Pro',
  'Ubuntu',
  'Work Sans'
]
const fontWeights = [100, 200, 300, 400, 500, 600, 700, 800, 900]
const fontUrl = 'https://fonts.googleapis.com/css2?family='

export const registerFontFamilies = () => {
  const weights = fontWeights.join(';')
  fontFamilies.forEach((fontFamily) => {
    const font = fontFamily.replace(/\s/g, '+')
    const url = `${fontUrl + font}:wght@${weights}&display=swap`

    const linkTag = document.createElement('link')
    linkTag.rel = 'stylesheet'
    linkTag.href = url

    document.head.appendChild(linkTag)
  })

  const quillFonts = Quill.import('formats/font')
  Quill.register(quillFonts, true)
  quillFonts.whitelist = fontFamilies
}
