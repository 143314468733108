import { getAuthUserCountry } from '@Utils/auth/getAuthUserCountry/getAuthUserCountry'
import { AxiosRequestConfig } from 'axios'
import { useApplicationInfo } from './useApplicationInfo'

export const usePrepareHeaders = () => {
  const { Authorization, country } = getAuthUserCountry()
  const { zone, storeId, cluster } = useApplicationInfo()

  const zoneHeader = `${zone.toLowerCase()}${
    !cluster || cluster === 'default' ? '' : `-${cluster}`
  }`

  const headers: AxiosRequestConfig['headers'] = {
    Authorization,
    country,
    requestTraceId: crypto.randomUUID(),
    zone: zoneHeader,
    store_id: storeId
  }

  return { headers }
}
