import { RootState } from '@Redux/store'
import {
  CmsBaseUrl,
  EndpointFunction,
  QueryFn
} from '@Utils/getEndpoints/getEndpointsDEPRECATED.types'

const getContentTypes: EndpointFunction = (builder) => ({
  mock: '',
  real: builder.query<ContentType[], CmsBaseUrl & { storeId: string }>({
    queryFn: async ({ cmsBaseUrl }, { getState }, _extraOptions, fetch) => {
      try {
        const { application } = getState() as RootState
        const { zone } = application
        const { data } = (await fetch(
          `${cmsBaseUrl}/contentedit/zone_${zone.toLowerCase()}/page-paths.json`
        )) as QueryFn<ContentType>
        const { data: contentTypes } = data
        return { data: contentTypes as ContentType[] }
      } catch (error) {
        const { message } = error as Error
        return { error: { status: 500, data: message } }
      }
    },
    providesTags: ['ContentTypes']
  })
})

export default getContentTypes
