import { SkeletonScreen } from '@Components/templates/EditorPage/components/utils/SkeletonScreen'
import globalVars from '@Utils/constants'
import React, { useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const LoadingScreen = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const queries = useMemo(
    () => ({
      guid: searchParams.get('guid')
    }),
    [searchParams]
  )

  useEffect(() => {
    navigate(`/web-view/web-view-editor/${queries.guid}?action=${globalVars.viewAction}`)
  }, [navigate, queries.guid])

  return <SkeletonScreen />
}
