import { UseMutation } from '@Api/api.types'
import { useApplicationInfo } from '@Api/hooks/useApplicationInfo'
import { useBuildQueryKeys } from '@Api/hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '@Api/hooks/usePrepareHeaders'
import { AudienceObject } from '@dlb-shared-business-components/audience'
import { useApi } from '@Src/shared/api/hooks/useApi'
import { useMutation } from '@tanstack/react-query'

interface ChallengeContent {
  title: string
  startDate: string | Date
  endDate: string | Date
  startRegistrationDate: string | Date
  endRegistrationDate: string | Date
  leaderboardDate: string | Date
}

export type ModuleData = ChallengeContent

export interface ChallengePayloadModules {
  updated_by_user_email: string
  guid: string
  module_name: string
  schema_name: string
  launch_time: string | Date
  zone: string
  page_sort: number | null
  is_default_display: boolean
  module_data: Record<string, ChallengeContent>
  selected_audience: AudienceObject[] | null
}

export interface UseAddChallengeMutationBody {
  guid: string
  page_name: string
  is_active: boolean
  page_template: string
  user_email: string
  launch_time: string | Date
  store_id: string
  modules: ChallengePayloadModules[]
}

export interface UseAddChallengeMutationFn {
  data: UseAddChallengeMutationBody
  pagePath: string
}

export interface UseAddChallengeMutationResponse {
  data: {
    message: string
    data: {
      guid: string
      name: string
    }
  }
}

export const useUploadChallengeMutation = ({
  mutationKeys,
  options
}: UseMutation<UseAddChallengeMutationResponse, Error, UseAddChallengeMutationFn>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()
  const { api } = useApi()

  const uploadChallengeKeys = useBuildQueryKeys(mutationKeys)

  return useMutation<UseAddChallengeMutationResponse, Error, UseAddChallengeMutationFn>(
    uploadChallengeKeys,
    {
      mutationFn: ({ pagePath, data }: UseAddChallengeMutationFn) => {
        return api({
          url: `${cmsCoreBackend}/contentedit/zone_${zone.toLowerCase()}/page/${pagePath}`,
          method: 'PUT',
          data,
          headers
        })
      },
      ...options
    }
  )
}
