import { Locales, Store, Zone } from '@Api/api.types'
import { useMutation } from '@tanstack/react-query'
import { useApi } from '../hooks/useApi'
import { useApplicationInfo } from '../hooks/useApplicationInfo'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'

export interface GetZoneBody {
  zone: Zone
  locales: Locales[]
  stores: Store[]
}

export const useZoneMutation = () => {
  const { cmsCoreBackend, zone: zoneKey } = useApplicationInfo()
  const { headers } = usePrepareHeaders()
  const { api } = useApi()

  return useMutation<GetZoneBody, Error, { id: number }>({
    async mutationFn({ id }: { id: number }) {
      const {
        data: { locales, pagePaths, stores, zone }
      } = await api.get(`${cmsCoreBackend}/contentedit/client/zone/zone_${zoneKey}/${id}`, {
        headers
      })
      return { locales, pagePaths, stores, zone }
    }
  })
}
