import { useQuery } from '@tanstack/react-query'
import { UseQuery } from '../api.types'
import { useApi } from '../hooks/useApi'
import { useApplicationInfo } from '../hooks/useApplicationInfo'
import { useBuildQueryKeys } from '../hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'
import { queryFn } from '../utils/queryFn'

interface WebViewModule {
  comment: null
  created_at: string
  created_by_guid: string
  created_by_user_email: string
  deleted_at: string
  deleted_by_user_email: string
  guid: string
  immutable_guid: string
  id: number
  is_shared: boolean
  launch_time: string
  module_name: string
  page_sort: number
  schema_name: string
  updated_at: string
  updated_by_guid: string
  updated_by_user_email: string
  zone: string
  module_data: {
    [key: string]: {
      url: string
    }
  }
}

export interface UseWebViewDataQuery {
  data: {
    campaign_ids: string[]
    created_at: string
    created_by_guid: string
    created_by_user_email: string
    deleted_at: string
    deleted_by_user_email: string
    engagement_ids: string[]
    guid: string
    immutable_guid: string
    id: number
    is_cascade: boolean
    launch_time: string
    page_data: null
    page_name: string
    page_path: string
    page_template: string
    store_ids: string[]
    updated_at: string
    updated_by_guid: string
    updated_by_user_email: string
    vendors_ids: string[]
    zone: string
    modules: WebViewModule[]
    status: string
  }
}

export const useWebViewDataQuery = ({
  queryKeys,
  options,
  guid
}: UseQuery<UseWebViewDataQuery, Error> & { guid: string | undefined }) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()
  const { api } = useApi()

  const itemsListQueryKeys = useBuildQueryKeys([...queryKeys, guid])

  const query = useQuery<UseWebViewDataQuery, Error>(
    itemsListQueryKeys,
    queryFn(api, `${cmsCoreBackend}/contentedit/page/zone_${zone.toLowerCase()}/${guid}`, {
      headers
    }),
    options
  )

  return query
}
