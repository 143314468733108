import Typography from '@mui/material/Typography'
import styled, { css } from 'styled-components'
import { StyledTextProps } from './Text.types'

export const TextComponent = styled(Typography)<StyledTextProps>`
  font-family: ${({ theme }) => theme.typography?.fontFamily};
  color: ${({ $textColor, theme }) => theme.palette?.text?.[$textColor || 'primary']};
  font-weight: ${({ theme, component }) =>
    component === 'strong' ? theme.typography.fontWeightSemiBold : 'inherit'};
  font-size: ${({ $fontSize }) => $fontSize};

  ${(props) =>
    props.$capitalize &&
    css`
      text-transform: capitalize;
    `}
`
