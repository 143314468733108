import React from 'react'
import { StyledLabel } from './Label.styles'
import { LabelProps } from './Label.types'

export const Label = ({
  optional = false,
  children,
  optionalText = '(Optional)',
  ...props
}: LabelProps) => {
  return (
    <StyledLabel {...props}>
      {children}
      {optional && <span>{optionalText}</span>}
    </StyledLabel>
  )
}
