import { useQuery } from '@tanstack/react-query'
import { UseQuery } from '../api.types'
import { useApi } from '../hooks/useApi'
import { useApplicationInfo } from '../hooks/useApplicationInfo'
import { useBuildQueryKeys } from '../hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'
import { queryFn } from '../utils/queryFn'

export type UseLocalesListQuery = {
  data: string[]
}

export const useLocalesListQuery = ({
  queryKeys,
  options
}: UseQuery<UseLocalesListQuery, Error>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()
  const { api } = useApi()

  const itemsListQueryKeys = useBuildQueryKeys(queryKeys)

  const query = useQuery<UseLocalesListQuery, Error>(
    itemsListQueryKeys,
    queryFn(api, `${cmsCoreBackend}/contentedit/zone_${zone.toLowerCase()}/locales`, {
      headers
    }),
    options
  )

  return query
}
