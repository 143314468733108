import { Button, Paragraph } from '@hexa-ui/components'
import { DocumentTitle } from 'admin-portal-shared-services'
import React from 'react'
import { FallbackProps } from 'react-error-boundary'
import { FallbackErrorContainer } from './ErrorBoundary.styles'

export function ErrorPageFallback({ resetErrorBoundary }: FallbackProps) {
  return (
    <>
      <DocumentTitle>Something went wrong</DocumentTitle>
      <FallbackErrorContainer role="alert" data-testid="fallback-error-page">
        <Paragraph alignment="center">Sorry, something went wrong.</Paragraph>
        <Button type="button" onClick={resetErrorBoundary} data-testid="fallback-error-button">
          Reload application
        </Button>
      </FallbackErrorContainer>
    </>
  )
}
