import { useLogService } from 'admin-portal-shared-services'
import React from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { globalVars } from '../utils/constants'
import { ErrorBoundaryStatus } from './ErrorBoundary.types'
import { ErrorPageFallback } from './ErrorPageFallback'

export const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const logService = useLogService()
  const { localStorageErrorBoundaryKey } = globalVars

  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorPageFallback}
      onError={(error) => logService.error(error)}
      onReset={() => {
        const errorBoundaryCounter = localStorage.getItem(localStorageErrorBoundaryKey)

        if (errorBoundaryCounter === ErrorBoundaryStatus.SecondCatch) {
          localStorage.setItem(localStorageErrorBoundaryKey, ErrorBoundaryStatus.FirstCatch)
          window.location.href = `${window.location.origin}/cms`
          return
        }
        localStorage.setItem(localStorageErrorBoundaryKey, ErrorBoundaryStatus.SecondCatch)
        window.location.reload()
      }}
    >
      {children}
    </ReactErrorBoundary>
  )
}
