import { Box, Text } from '@BarrelComponents'
import pxToRem from '@Utils/pxToRem/pxToRem'
import { TextLink } from '@hexa-ui/components'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { LinkDropDownProps } from './LinkDropDown.types'

const LinkDropDown = ({ url, label, disabled, padding, icon: Icon }: LinkDropDownProps) => {
  return (
    <Box
      sx={{
        padding: padding ?? `${pxToRem(20)} ${pxToRem(16)}`
      }}
    >
      {disabled ? (
        <Text $textColor="disabled">{label}</Text>
      ) : (
        <TextLink
          colorOption="black"
          hasUnderline={false}
          css={{ display: 'flex', alignItems: 'center', columnGap: `${pxToRem(8)}` }}
          asChild
        >
          <Link to={url}>
            {Icon && <Icon />}
            {label}
          </Link>
        </TextLink>
      )}
    </Box>
  )
}

export const MemoizedLinkDropDown = memo(LinkDropDown)
