import pxToRem from '@Utils/pxToRem/pxToRem'
import React from 'react'
import { StyledActionsContainer } from './ActionsContainer.styles'
import { ActionsContainerProps } from './ActionsContainer.types'

export const ActionsContainer = ({
  as,
  children,
  $padding = pxToRem(32),
  ...props
}: ActionsContainerProps) => {
  return (
    <StyledActionsContainer {...props} as={as} $padding={$padding}>
      {children}
    </StyledActionsContainer>
  )
}
