import { DataTableProps, Filters } from '@Src/modules/customer/ContentList/ContentList.types'
import { dayjs } from '@Utils/dayjs/dayjs'
import { useCallback } from 'react'
import { OnFilterDeleteParam, UseFilterParams } from './useFilter.types'

const useFilter = ({
  getFormValues,
  setFormValue,
  setContentTypeFilters,
  setStatusFilters,
  setAdvancedFilters
}: UseFilterParams) => {
  const onFilterDelete = useCallback(
    ({ key, value }: OnFilterDeleteParam) => {
      return () => {
        const currentValue = getFormValues(key)
        const checkBoxFilters = ['contentTypeFilter', 'statusFilter']
        const datesFilters = ['startDate', 'endDate']
        const isArray = Array.isArray(currentValue)

        if (checkBoxFilters.includes(key) && isArray) {
          const remappedValue = currentValue as Filters['contentTypeFilter' | 'statusFilter']
          const newValue = remappedValue?.filter((item) => {
            return item !== value
          })

          if (key === 'contentTypeFilter') setContentTypeFilters(newValue as (string | null)[])
          if (key === 'statusFilter') setStatusFilters(newValue as (string | null)[])
          setFormValue(key, newValue)
          setAdvancedFilters((state) => ({ ...state, [key]: newValue }))
        }

        if (datesFilters.includes(key) && !isArray) {
          setFormValue(key, null)
          setAdvancedFilters((state) => ({ ...state, [key]: null }))
        }

        if (key === 'editedByFilter' && isArray) {
          const remappedValue = currentValue as Filters['editedByFilter']
          const newValue = remappedValue?.filter((label) => label !== (value as string))
          setFormValue(key, newValue)
          setAdvancedFilters((state) => ({ ...state, [key]: newValue }))
        }
      }
    },
    [getFormValues, setAdvancedFilters, setContentTypeFilters, setFormValue, setStatusFilters]
  )

  const filterByAdvancedFields = useCallback(
    ({
      contentTitleFilter,
      contentTypeFilter,
      statusFilter,
      editedByFilter,
      startDate,
      endDate
    }: Filters) => {
      return ({ contentTitle, status, type, username, launch }: DataTableProps) => {
        const shouldSkipFilters =
          !contentTitleFilter?.length &&
          !contentTypeFilter?.length &&
          !statusFilter?.length &&
          !editedByFilter?.length &&
          !startDate &&
          !endDate

        if (shouldSkipFilters) return Boolean

        const formattedLabels = contentTitleFilter?.map((label) => label.toLowerCase())
        const byTitle = formattedLabels
          ?.map((label) => contentTitle.toLowerCase().includes(label))
          .some(Boolean)

        const filteredTypes = contentTypeFilter?.filter(Boolean)
        const formattedType = String(type).toLowerCase().split(' ').join('-')
        const byType = filteredTypes?.some((type) =>
          type === 'web-view-' ? formattedType.includes(type) : formattedType === type
        )

        const formattedStatus = status.toLowerCase()
        const byStatus = statusFilter?.includes(formattedStatus)

        const formattedUsers = editedByFilter?.map((label) => label.toLowerCase())

        const byUsers = formattedUsers
          ?.map((label) => username?.toLowerCase().includes(label))
          .some(Boolean)

        const launchDate = dayjs(launch).startOf('day')
        const byDateRange = launchDate.isBetween(startDate, endDate, 'day', '[]')
        const rangeDateIsValid = startDate?.isValid() && endDate?.isValid()

        const fromStartDate = launchDate.isSameOrAfter(startDate)
        const beforeEndDate = launchDate.isSameOrBefore(endDate)

        return (
          (contentTitleFilter?.length ? byTitle : true) &&
          (contentTypeFilter?.length ? byType : true) &&
          (statusFilter?.length ? byStatus : true) &&
          (editedByFilter?.length ? byUsers : true) &&
          (rangeDateIsValid ? byDateRange : true) &&
          (!rangeDateIsValid && startDate?.isValid() ? fromStartDate : true) &&
          (!rangeDateIsValid && endDate?.isValid() ? beforeEndDate : true)
        )
      }
    },
    []
  )

  return {
    filterByAdvancedFields,
    onFilterDelete
  }
}

export default useFilter
