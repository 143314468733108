import { StatusIndicator, Text } from '@BarrelComponents'
import DropDownIconButton from '@Src/components/molecules/DropDownIconButton/DropDownIconButton'
import { useTranslationsContext } from '@Src/shared/contexts/I18nContext/I18nContext'
import ExternalLinkIcon from '@Src/shared/svg/ExternalLinkIcon'
import { MoreHorizontal, Trash2 } from '@hexa-ui/icons'
import React, { memo } from 'react'
import { CellProps, Column } from 'react-table'
import { DataTableProps, GenerateTableColumnsProps } from '../ContentList.types'
import { ContentTitle } from '../components/ContentTitle/ContentTitle'
import { LastEdit } from '../components/LastEdit/LastEdit'
import { Launch } from '../components/Launch/Launch'

export const GenerateTableColumns = (props: GenerateTableColumnsProps) => {
  const {
    getIcon,
    getTranslatedTypes,
    openedDropdown,
    onRowClick,
    handleDeleteLaunch,
    handleOpenDropdown,
    targetLaunch
  } = props
  const memoizedTrashIcon = memo(() => <Trash2 size="large" />)
  const memoizedExternalLinkIcon = memo(() => <ExternalLinkIcon />)
  const memoizedMoreHorizontalIcon: any = memo(() => <MoreHorizontal size="medium" />)

  const {
    translations: { contentListPage: contentListPageTranslations, shared: sharedTranslations }
  } = useTranslationsContext()

  const {
    contentTitleTableHeadText,
    actionsTableHeadText,
    launchTableHeadText,
    statusTabText,
    lastEditTableHeadText,
    tableItemActionShowDetailsText
  } = contentListPageTranslations

  const { timezoneText, deleteText } = sharedTranslations

  const dropdownOptions = [
    {
      label: tableItemActionShowDetailsText,
      onClick: () => onRowClick(targetLaunch),
      icon: memoizedExternalLinkIcon
    },
    {
      label: deleteText,
      onClick: (event: MouseEvent) => handleDeleteLaunch(event),
      icon: memoizedTrashIcon
    }
  ]

  return [
    {
      accessor: 'icon',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        return getIcon(row?.original?.translatedType as keyof typeof getTranslatedTypes)
      }
    },
    {
      Header: contentTitleTableHeadText,
      accessor: 'contentTitle',
      style: {
        width: '21.5%'
      },
      Cell: ({ value, row }: any) => {
        const { guid, translatedType } = row.original
        return <ContentTitle key={`${guid}-content-title`} title={value} type={translatedType} />
      }
    },
    {
      Header: launchTableHeadText,
      accessor: 'launch',
      sortType: 'alphanumeric',
      style: {
        width: '18%'
      },
      Cell: ({ row }: any) => {
        const { guid, formattedLaunch } = row.original
        return <Launch key={`${guid}-launch`} formattedLaunch={formattedLaunch} />
      }
    },
    {
      Header: timezoneText,
      accessor: 'timezone',
      style: {
        width: '8%'
      },
      Cell: ({ row }: any) => {
        const { guid, formattedLaunchTimezone } = row.original
        return (
          <Text key={`${guid}-timezone`} sx={{ textAlign: 'center' }}>
            {formattedLaunchTimezone}
          </Text>
        )
      }
    },
    {
      Header: statusTabText,
      accessor: 'status',
      Cell: ({ row, value }: any) => {
        const { translatedStatus } = row.original
        return (
          <StatusIndicator
            type={String(value).toLowerCase() as 'live' | 'scheduled' | 'past'}
            text={translatedStatus}
          />
        )
      }
    },
    {
      Header: lastEditTableHeadText,
      accessor: 'lastEdit',
      sortType: 'alphanumeric',
      Cell: ({ row }: any) => {
        const { guid, formattedUpdatedAt, username } = row.original
        return (
          <LastEdit
            key={`${guid}-last-edit`}
            formattedUpdatedAt={formattedUpdatedAt}
            editor={username}
          />
        )
      }
    },
    {
      Header: actionsTableHeadText,
      style: {
        width: '10%'
      },
      accessor: 'deleteButton',
      disableSortBy: true,
      Cell: ({ row }: React.PropsWithChildren<CellProps<DataTableProps, string>>) => {
        const { status, guid } = row.original
        const isLiveOrNotAuthorized = status.toLowerCase() === 'live'
        const isOpen = openedDropdown === guid
        return (
          <DropDownIconButton
            key={`${guid}-action-button`}
            id={guid}
            width={80}
            isDropDown
            icon={memoizedMoreHorizontalIcon}
            dropdownOptions={dropdownOptions}
            isOpen={isOpen}
            onOpen={handleOpenDropdown}
            disabled={isLiveOrNotAuthorized}
          />
        )
      }
    }
  ].filter(Boolean) as Column<DataTableProps>[]
}
