import { Service, fetchEndpointURL } from '@content-shared-components/image-uploader'
import { useMemo } from 'react'
import { TUseGetApiHost, TUseGetApiHostProps } from './useGetApiHost.types'

export const useGetApiHost = ({ zone }: TUseGetApiHostProps): TUseGetApiHost => {
  const apiDevHost = process.env.API_DEV_HOST

  const servicesURLs = {
    cmsCoreBackend: fetchEndpointURL(zone, Service.CMS_CORE),
    imageUpload: fetchEndpointURL(zone, Service.IMAGE_SERVER)
  }

  const finalURLs = useMemo(
    () => ({
      cmsCoreBackend: apiDevHost ?? servicesURLs.cmsCoreBackend,
      imageUpload: servicesURLs.imageUpload
    }),
    [apiDevHost, servicesURLs.cmsCoreBackend, servicesURLs.imageUpload]
  )

  return finalURLs as TUseGetApiHost
}
