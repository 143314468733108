import { useEffect, useState } from 'react'
import { UseSlicePagination } from './useSlicePagination.types'

export const defaultOptions = {
  limit: 5,
  startFrom: 0
}

const useSlicePagination: UseSlicePagination = (list, options = defaultOptions) => {
  /* istanbul ignore next */
  const { limit = defaultOptions.limit, startFrom = defaultOptions.startFrom } = options
  const [pages, setPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(startFrom)
  const [itemsPerPage, setItemsPerPage] = useState(limit)

  useEffect(() => {
    if (!list) return
    setPages(Math.ceil(list.length / itemsPerPage))
  }, [itemsPerPage, list])

  useEffect(() => {
    setItemsPerPage(limit)
  }, [limit])

  useEffect(() => {
    if (currentPage + 1 > pages) setCurrentPage(0)
  }, [currentPage, pages])

  const handleNextPage = () => {
    if (currentPage + 1 === pages) return
    setCurrentPage(currentPage + 1)
  }

  const handlePrevPage = () => {
    if (currentPage === 0) return
    setCurrentPage(currentPage - 1)
  }

  const goToPage = (page: number) => {
    setCurrentPage(page)
  }

  return {
    listLength: list?.length || 0,
    pages,
    currentPage,
    itemsPerPage,
    sliceStartIndex: currentPage * limit,
    sliceEndIndex: currentPage * limit + limit,
    handleNextPage,
    handlePrevPage,
    goToPage
  }
}

export default useSlicePagination
