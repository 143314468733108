import { BasePropsStyles } from '@Styles/sharedStyles'
import { Box } from '@mui/material'
import styled, { keyframes } from 'styled-components'
import { ImageProps } from './Image.types'

const skeleton = keyframes`
  from {
     background-position: 0% 0% 
  }

  to {
    background-position: 100% 100%
  }
`

export const StyledImageBox = styled(Box)`
  position: absolute;
  inset: 0;
  z-index: 1;
  background-image: linear-gradient(
    to bottom right,
    ${({ theme }) => theme.palette?.grey?.[150]} 0%,
    ${({ theme }) => theme.palette?.grey?.[160]} 50%,
    ${({ theme }) => theme.palette?.grey?.[150]} 100%
  );
  background-size: 200% 200%;
  animation: ${skeleton} 1s infinite linear alternate;
`

export const StyledImage = styled.img<ImageProps>`
  ${BasePropsStyles};
  display: block;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s;
  object-fit: ${({ $objectFit }) => $objectFit};
  object-position: ${({ $objectPosition }) => $objectPosition};
`
