import { Stack } from '@BarrelComponents'
import pxToRem from '@Src/shared/utils/pxToRem/pxToRem'
import { Card } from '@hexa-ui/components'
import { Skeleton } from '@mui/material'
import React from 'react'
import { Grid } from './Grid'

export const SkeletonScreen = () => {
  return (
    <Stack
      $padding={`${pxToRem(24)} ${pxToRem(26)} 0 0`}
      gap={pxToRem(24)}
      sx={{
        width: '100%',
        maxWidth: '1200px',
        marginInline: 'auto'
      }}
    >
      {React.Children.toArray(
        Array.from({ length: 2 }).map(() => {
          return (
            <Card
              border="medium"
              elevated="small"
              css={{
                padding: `${pxToRem(24)} ${pxToRem(24)} calc(${pxToRem(24)} + 5px)`
              }}
            >
              <Stack gap={pxToRem(26)}>
                <Skeleton
                  variant="rectangular"
                  width="200px"
                  height="24px"
                  sx={{ borderRadius: '1rem' }}
                />
                <Grid>
                  <Stack gap={pxToRem(8)}>
                    <Skeleton
                      variant="rectangular"
                      width="40%"
                      height="16px"
                      sx={{ borderRadius: '1rem' }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="40px"
                      sx={{ borderRadius: '1rem' }}
                    />
                  </Stack>
                  <Stack gap={pxToRem(8)}>
                    <Skeleton
                      variant="rectangular"
                      width="40%"
                      height="16px"
                      sx={{ borderRadius: '1rem' }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="40px"
                      sx={{ borderRadius: '1rem' }}
                    />
                  </Stack>
                  <Stack gap={pxToRem(8)}>
                    <Skeleton
                      variant="rectangular"
                      width="40%"
                      height="16px"
                      sx={{ borderRadius: '1rem' }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="40px"
                      sx={{ borderRadius: '1rem' }}
                    />
                  </Stack>
                  <Stack gap={pxToRem(8)}>
                    <Skeleton
                      variant="rectangular"
                      width="40%"
                      height="16px"
                      sx={{ borderRadius: '1rem' }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="40px"
                      sx={{ borderRadius: '1rem' }}
                    />
                  </Stack>
                  <Stack gap={pxToRem(8)} sx={{ gridColumn: '1/-1' }}>
                    <Skeleton
                      variant="rectangular"
                      width="20%"
                      height="16px"
                      sx={{ borderRadius: '1rem' }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="400px"
                      sx={{ borderRadius: '1rem' }}
                    />
                  </Stack>
                  <Stack gap={pxToRem(8)} sx={{ gridColumn: '1/-1' }}>
                    <Skeleton
                      variant="rectangular"
                      width="20%"
                      height="16px"
                      sx={{ borderRadius: '1rem' }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="42px"
                      sx={{ borderRadius: '1rem' }}
                    />
                  </Stack>
                </Grid>
              </Stack>
            </Card>
          )
        })
      )}
    </Stack>
  )
}
