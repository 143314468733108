import { BasePropsStyles } from '@Styles/sharedStyles'
import styled from 'styled-components'

export const StyledVideoThumbnail = styled.div`
  ${BasePropsStyles};
  pointer-events: none;
  display: flex;
  height: 100%;
  width: 100%;
  transition: 0.5s;

  & > * {
    flex: 1;
  }

  & img {
    object-fit: cover;
    object-position: center;
  }
`
