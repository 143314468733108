import { Stack } from '@BarrelComponents'
import { MemoizedButtonDropDown } from '@Src/components/organisms/AppHeader/components/ButtonDropDown/ButtonDropDown'
import { Card, IconButton } from '@hexa-ui/components'
import React, { useEffect, useRef } from 'react'
import { TDropDownIconButtonProps } from './DropDownIconButton.type'

const DropDownIconButton = ({
  id,
  isOpen = false,
  onOpen,
  width,
  icon,
  isDropDown,
  dropdownOptions,
  onActionButtonClick,
  disabled
}: TDropDownIconButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const dropDownRef = useRef<HTMLDivElement>(null)

  const handleClickActionButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!isDropDown && typeof onActionButtonClick === 'function') {
      return onActionButtonClick(event)
    }
    onOpen(id)
    event.stopPropagation()
  }

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const eventTarget = event.target as Node

      if (
        !buttonRef ||
        buttonRef.current?.contains(eventTarget) ||
        dropDownRef?.current?.contains(eventTarget) ||
        !isOpen
      ) {
        return
      }
      onOpen(null)
    }
    document.addEventListener('click', listener)
    return () => {
      document.removeEventListener('click', listener)
    }
  }, [isOpen, onOpen])

  return (
    <Stack position="relative" style={{ width: `${width}px` }} data-testid="iconbutton-container">
      <IconButton
        ref={buttonRef}
        size="medium"
        onClick={handleClickActionButton}
        icon={icon}
        variant="inherit"
        style={
          isOpen ? { pointerEvents: 'all', opacity: 1, transition: 'ease-in-out 150ms all' } : {}
        }
      />
      <Card
        ref={dropDownRef}
        className="app-header-card"
        border="medium"
        elevated="small"
        css={{
          position: 'absolute',
          top: 50,
          right: 0,
          width: width + 90,
          zIndex: 12,
          padding: '4px 0',
          pointerEvents: isOpen ? 'all' : 'none',
          opacity: isOpen ? 1 : 0,
          transition: 'ease-in-out 150ms all'
        }}
      >
        {dropdownOptions?.map(({ label, icon, onClick }: any) => {
          const isDisabled = disabled && label === 'Delete'
          return (
            <MemoizedButtonDropDown
              disabled={isDisabled}
              key={label}
              label={label}
              onClick={onClick}
              icon={icon}
            />
          )
        })}
      </Card>
    </Stack>
  )
}

export default DropDownIconButton
