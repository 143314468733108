import React from 'react'
import {
  ColoredStyledStatusIndicatorText,
  StyledStatusIndicator,
  StyledStatusIndicatorCircle
} from './StatusIndicator.styles'
import { StatusIndicatorProps } from './StatusIndicator.types'

export const StatusIndicator = ({ type, text, ...props }: StatusIndicatorProps) => {
  return (
    <StyledStatusIndicator type={type} {...props}>
      <StyledStatusIndicatorCircle type={type} />
      <ColoredStyledStatusIndicatorText type={type}>{text}</ColoredStyledStatusIndicatorText>
    </StyledStatusIndicator>
  )
}
