import { Text } from '@BarrelComponents'
import pxToRem from '@Utils/pxToRem/pxToRem'
import React from 'react'
import { LaunchProps } from './Launch.types'

export const Launch = ({ formattedLaunch }: LaunchProps) => {
  return (
    <Text $textColor="primary" $fontSize={pxToRem(14)}>
      {formattedLaunch}
    </Text>
  )
}
