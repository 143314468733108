import { useGetEnv } from '@Hooks/useGetEnv/useGetEnv'
import { Environment } from '@Src/types/environment'
import { useQuery } from '@tanstack/react-query'
import { UseQuery } from '../api.types'
import { useApi } from '../hooks/useApi'
import { useBuildQueryKeys } from '../hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'
import { queryFn } from '../utils/queryFn'

export interface UseDeepLinkPathsListQuery {
  data: {
    content: {
      id: string
      name: string
      params: string[]
    }[]
  }
}

const getServiceUrl = (env: Environment) => {
  if (env !== Environment.PRODUCTION) {
    return `https://services-${env}.bees-platform.dev/api/deeplink-service/v1/paths`
  }

  return 'https://services.bees-platform.com/api/deeplink-service/v1/paths'
}

export const useDeepLinkPathsListQuery = ({
  queryKeys,
  options,
  vendorId,
  country
}: UseQuery<UseDeepLinkPathsListQuery, Error> & { vendorId: string; country: string }) => {
  const { headers } = usePrepareHeaders()
  const env = useGetEnv()
  const { api } = useApi()

  const deeplinkPathsListQueryKeys = useBuildQueryKeys(queryKeys)

  const query = useQuery<UseDeepLinkPathsListQuery, Error>(
    deeplinkPathsListQueryKeys,
    queryFn(api, getServiceUrl(env), {
      headers: { ...headers, vendorId, country }
    }),
    options
  )

  return query
}
