export enum FeatureToggles {
  MN_TIMEZONE_ENABLED = 'mn_timezone_enabled',
  CUSTOMER_TIMEZONE_ENABLED = 'customer_timezone_enabled',
  MN_AUDIENCE_SELECTOR_ENABLED = 'mn_audience_selector_enabled',
  CUSTOMER_AUDIENCE_SEGMENTATION_ENABLED = 'customer_audience_segmentation_enabled',
  MULTI_MODULES_ENABLED = 'multi_modules_enabled',
  MN_LONG_TIP_ENABLED = 'mn_long_tip_enabled',
  MN_PODCAST_ENABLED = 'mn_podcast_enabled',
  MN_VIDEO_ENABLED = 'mn_video_enabled',
  MN_DAILY_FEED_ENABLED = 'mn_daily_feed_enabled',
  MN_CHALLENGE_ENABLED = 'mn_challenge_enabled',
  MN_SINGLE_EVENT_ENABLED = 'mn_single_event_enabled',
  MN_EVENT_ENABLED = 'mn_event_enabled',
  MN_METADATA_TAB_ENABLED = 'mn_metadata_tab_enabled',
  MN_MULTI_LANGUAGE_METADATA_ENABLED = 'mn_multi_language_metadata_enabled',
  MN_MULTIPLE_LIVE_LAUNCHES_ENABLED = 'mn_multiple_live_launches_enabled',
  MN_RICH_TEXT_EDITOR_ENABLED = 'mn_rich_text_editor_enabled',
  CUSTOMER_VIDEO_BANNERS_ENABLED = 'customer_video_banners_enabled',
  DEEP_LINK_GENERATOR_ENABLED = 'deep_link_generator_enabled',
  MONITORING_DASHBOARD_ENABLED = 'monitoring_dashboard_enabled',
  CUSTOMER_TAGGING_ENABLED = 'customer_tagging_enabled',
  CONTENT_TYPE_ENDPOINT_ENABLED = 'content_type_endpoint_enabled'
}
