import Quill from 'quill'

export const fontSizes = [
  '8px',
  '9px',
  '10px',
  '11px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '22px',
  '24px',
  '26px',
  '28px',
  '30px',
  '32px',
  '34px',
  '36px',
  '38px',
  '40px',
  '42px',
  '44px',
  '46px',
  '48px',
  '54px',
  '60px',
  '66px',
  '72px',
  '80px',
  '88px',
  '96px',
  '104px',
  '120px',
  '144px'
]

export const registerFontSizes = () => {
  const quillFontSizes = Quill.import('attributors/style/size')
  quillFontSizes.whitelist = fontSizes
  Quill.register(quillFontSizes, true)
}
