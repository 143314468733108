import { dayjs, formatTimeZoneOnly } from '@Utils/dayjs/dayjs'

export const formatContentLaunchTableData = (props: any) => {
  const { data, contentTypesAndStatusTranslations } = props
  return data?.map(
    ({
      guid,
      name,
      path,
      launch_timezone,
      launch_time,
      status,
      mod_time,
      username,
      selected_audience
    }: any) => {
      const formattedLaunchTimezone = launch_timezone ? formatTimeZoneOnly(launch_timezone) : '-'

      const parsedLaunch = launch_timezone
        ? dayjs(launch_time).tz(launch_timezone)
        : dayjs(launch_time)

      const parsedLastEdit = dayjs(mod_time)

      const type = path.split('-').join(' ')

      type Translations = keyof typeof contentTypesAndStatusTranslations

      const translatedType = path.includes('web-view-')
        ? contentTypesAndStatusTranslations['web-view-']
        : contentTypesAndStatusTranslations[path as Translations]

      const remainingAudiences =
        selected_audience?.length > 1 ? `, +${selected_audience.length - 1}` : ''
      const audience =
        selected_audience?.length > 1
          ? `${selected_audience[0].audienceName}${remainingAudiences}`
          : contentTypesAndStatusTranslations.noAudience

      return {
        guid,
        formattedLaunch: parsedLaunch.format('MMM D, YYYY [at] h:mm a'),
        formattedUpdatedAt: parsedLastEdit.format('MMM D, YYYY [at] h:mm a'),
        type,
        translatedType,
        contentTitle: name,
        launch: parsedLaunch.valueOf(),
        lastEdit: parsedLastEdit.valueOf(),
        formattedLaunchTimezone,
        timezone: launch_timezone,
        status,
        translatedStatus: contentTypesAndStatusTranslations[status.toLowerCase() as Translations],
        username,
        audience
      }
    }
  )
}
