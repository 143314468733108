import { BasePropsStyles } from '@Styles/sharedStyles'
import Tab from '@mui/material/Tab'
import styled from 'styled-components'
import { TabProps } from './Tab.types'

export const StyledTab = styled(Tab)<TabProps>`
  &:first-child {
    border-top-left-radius: 16px;
  }
  ${BasePropsStyles};
`
