import getLocalStorage from '@Utils/getLocalStorage/getLocalStorage'
import { handleSetLocalStorageOnRowPageChangeProps } from '../ContentList.types'

export const handleSetLocalStorageOnRowPageChange = (
  props: handleSetLocalStorageOnRowPageChangeProps
) => {
  const { userPrefKey, rowsPerPage } = props
  const userPreferences = getLocalStorage(userPrefKey, JSON.stringify({}))

  localStorage.setItem(
    userPrefKey,
    JSON.stringify({
      ...JSON.parse(userPreferences),
      contentListRowsPerPage: rowsPerPage
    })
  )
}
