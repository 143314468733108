import { Options, Slugify } from './slugify.types'

const defaultOptions: Options = {
  invert: false
}

const slugify: Slugify = (string, options = defaultOptions) => {
  /* istanbul ignore next */
  const { invert = defaultOptions.invert } = options

  if (!invert) return string.split(' ').join('-').toLowerCase()
  return string.split('-').join(' ').toLowerCase()
}

export default slugify
