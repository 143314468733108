import { BasePropsStyles } from '@Styles/sharedStyles'
import { colors, palette } from '@Styles/theme/colors'
import pxToRem from '@Utils/pxToRem/pxToRem'
import Alert from '@mui/material/Alert'
import styled from 'styled-components'
import { InfoPanelProps } from './InfoPanel.types'

export const StyledInfoPanel = styled(Alert)<Omit<InfoPanelProps, 'text'>>`
  && {
    width: 100%;
    font-size: ${pxToRem(14)};
    padding: 0 ${pxToRem(16)};
    color: #000;

    &.MuiAlert-standardInfo {
      background-color: ${palette.purple?.[200]};
      .MuiAlert-icon {
        color: ${palette.blue?.[500]};
      }
    }

    &.MuiAlert-standardError {
      background-color: ${colors.red?.[200]};
      .MuiAlert-icon {
        color: ${colors.red?.[300]};
      }
    }

    ${BasePropsStyles};
  }
`
