interface RenameFileParams {
  originalFile: File
  newName?: string
  removeSpecialCharacters?: boolean
}

export function renameFile({
  originalFile,
  newName,
  removeSpecialCharacters = true
}: RenameFileParams) {
  let fileName = ''

  const originalFileNameWithoutExtension = originalFile.name.substring(
    0,
    originalFile.name.lastIndexOf('.')
  )

  if (!newName && removeSpecialCharacters) {
    fileName = originalFileNameWithoutExtension.replace(/([^\w ]|_)/g, '')
  }

  if (newName && !removeSpecialCharacters) {
    fileName = newName
  }

  if (newName && removeSpecialCharacters) {
    fileName = newName.replace(/([^\w ]|_)/g, '')
  }

  return new File([originalFile], fileName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified
  })
}
