import { BasePropsStyles } from '@Styles/sharedStyles'
import Chip from '@mui/material/Chip'
import styled, { css } from 'styled-components'
import { BasicChipProps } from './BasicChip.types'

export const StyledBasicChip = styled(Chip)<BasicChipProps>`
  ${BasePropsStyles};
  border: ${({ $secondaryColor }) => `2px solid ${$secondaryColor || 'transparent'}`};
  color: ${({ $textColor }) => $textColor};
  font-weight: bold;

  &&& {
    line-height: 1;
    background-color: ${({ $primaryColor }) => $primaryColor};
    color: ${({ $secondaryColor }) => $secondaryColor};

    ${(props) =>
      props.$capitalize &&
      css`
        text-transform: capitalize;
      `}
  }
`
