import Switch from '@mui/material/Switch'
import styled, { css } from 'styled-components'
import { ToggleProps } from './Toggle.types'

const sizeStyles = {
  small: css``,
  medium: css`
    width: 56px;
    height: 40px;
    & .MuiSwitch-thumb {
      width: 22px;
      height: 22px;
    }
    & .Mui-checked {
      transform: translateX(16px);
    }
  `
}

export const StyledToggle = styled(Switch)<ToggleProps>`
  ${({ size }) => size && sizeStyles[size]}
`
