import { useEnvProps } from '@Contexts/EnvContext/EnvContext'
import { Zone } from '@Src/types/zone'
import optimizely from '@optimizely/optimizely-sdk'
import { useAuthenticationService, useFeatureToggleServiceV2 } from 'admin-portal-shared-services'
import { useEffect, useMemo, useState } from 'react'
import { FeatureToggles } from './feature-toggles'

type Nullable<T> = T | null
interface UseFeatureToggleVariables {
  customer_video_banners_enabled: Nullable<{
    allowed_types: {
      dev: string[]
      sit: string[]
      uat: string[]
      prod: string[]
    }
  }>
}

export type TUseFeatureToggle<TFeature = any> = {
  isEnabled: boolean
  isLoading: boolean
  variables: TFeature extends keyof UseFeatureToggleVariables
    ? UseFeatureToggleVariables[TFeature]
    : null
}

export interface UseFeatureToggleAttributes {
  zone?: Zone
  store?: string
}

optimizely.setLogger(null)

export const useFeatureToggle = <TFeature extends FeatureToggles>(
  featureName: TFeature,
  attributes: UseFeatureToggleAttributes = {}
): TUseFeatureToggle<TFeature> => {
  const [response, setResponse] = useState<TUseFeatureToggle>({
    isEnabled: false,
    isLoading: true,
    variables: null
  })

  const { optimizelyKey } = useEnvProps()

  const feature = useFeatureToggleServiceV2('cms-optimizely-toggle')
  const authentication = useAuthenticationService()
  const userId = authentication.getUserId()
  const { zone, store } = attributes

  const params = useMemo<Parameters<optimizely.Client['getAllFeatureVariables']>>(
    () => [
      featureName,
      userId,
      {
        ...(!!zone && { zone: `zone_${zone}` }),
        ...(!!store && { store_id: store })
      }
    ],
    [featureName, store, userId, zone]
  )

  useEffect(() => {
    const getFeatureToggle = async () => {
      const optimizelyClient = optimizely.createInstance({
        sdkKey: optimizelyKey
      })

      await feature.onReady()
      await optimizelyClient.onReady()

      const flag = await feature.isFeatureEnabledAudiences(...params)
      const variables = optimizelyClient.getAllFeatureVariables(...params)

      setResponse({
        isEnabled: flag,
        isLoading: false,
        variables
      })
    }
    getFeatureToggle()
  }, [feature, optimizelyKey, params])

  return response
}
