import { BasePropsStyles } from '@Styles/sharedStyles'
import DialogContent from '@mui/material/DialogContent'
import styled from 'styled-components'
import { ModalContentProps } from './ModalContent.types'

export const StyledDialogContent = styled(DialogContent)<ModalContentProps>`
  && {
    ${BasePropsStyles};
    overflow: visible;
  }
`
