import { BasePropsStyles } from '@Styles/sharedStyles'
import pxToRem from '@Utils/pxToRem/pxToRem'
import Stack from '@mui/material/Stack'
import styled from 'styled-components'
import { CountryFlagProps } from './CountryFlag.types'

export const StyledCountryFlagWrapper = styled(Stack)<Omit<CountryFlagProps, 'zone'>>`
  background-color: ${({ theme }) => theme.palette.grey?.[100]};
  padding: ${`${pxToRem(10)} ${pxToRem(24)}`};
  border-radius: 20px;
  ${BasePropsStyles};
`
