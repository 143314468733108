import { useGetChallengeQuery } from '@Api/endpoints/minegocio/challenge/useGetChallengeQuery'
import { useUploadChallengeMutation } from '@Api/endpoints/minegocio/challenge/useUploadChallengeMutation'
import { useGetDailyFeedQuery } from '@Api/endpoints/minegocio/daily-feed/useGetDailyFeedQuery'
import { useUploadDailyFeedMutation } from '@Api/endpoints/minegocio/daily-feed/useUploadDailyFeedMutation'
import { useAddEventMutation } from '@Api/endpoints/minegocio/event/useAddEventMutation'
import { useEventDataQuery } from '@Api/endpoints/minegocio/event/useEventDataQuery'
import { useGetLongTipQuery } from '@Api/endpoints/minegocio/long-tip/useGetLongTipQuery'
import { useUploadLongTipMutation } from '@Api/endpoints/minegocio/long-tip/useUploadLongTipMutation'
import { useGetAuthorsQuery } from '@Api/endpoints/minegocio/metadata/useGetAuthorsQuery'
import { useGetCategoriesQuery } from '@Api/endpoints/minegocio/metadata/useGetCategoriesQuery'
import { useGetSegmentsQuery } from '@Api/endpoints/minegocio/metadata/useGetSegmentsQuery'
import { useGetTagsQuery } from '@Api/endpoints/minegocio/metadata/useGetTagsQuery'
import { useMetadataListQuery } from '@Api/endpoints/minegocio/metadata/useMetadataListQuery'
import { useGetPodcastQuery } from '@Api/endpoints/minegocio/podcast/useGetPodcastQuery'
import { useUploadPodcastMutation } from '@Api/endpoints/minegocio/podcast/useUploadPodcastMutation'
import { useAddVideoMutation } from '@Api/endpoints/minegocio/video/useAddVideoMutation'
import { useVideoDataQuery } from '@Api/endpoints/minegocio/video/useVideoDataQuery'
import { useAddBannerMutation } from '@Api/endpoints/useAddBannerMutation'
import { useAddWebViewMutation } from '@Api/endpoints/useAddWebViewMutation'
import { useAlterZoneMutation } from '@Api/endpoints/useAlterZoneMutation'
import { useBannerDataQuery } from '@Api/endpoints/useBannerDataQuery'
import { useDDCsListQuery } from '@Api/endpoints/useDDCsListQuery'
import { useDeepLinkPathsListQuery } from '@Api/endpoints/useDeepLinkPathsListQuery'
import { useDeleteItemMutation } from '@Api/endpoints/useDeleteItemMutation'
import { useItemsListQuery } from '@Api/endpoints/useItemsListQuery'
import { useLocalesListQuery } from '@Api/endpoints/useLocalesListQuery'
import { usePagePathsListQuery } from '@Api/endpoints/usePagePathsListQuery'
import { useUploadImageMutation } from '@Api/endpoints/useUploadImageMutation'
import { useUploadVideoMutation } from '@Api/endpoints/useUploadVideoMutation'
import { useUsersListQuery } from '@Api/endpoints/useUsersListQuery'
import { useVendorsListQuery } from '@Api/endpoints/useVendorsListQuery'
import { useWebViewDataQuery } from '@Api/endpoints/useWebViewDataQuery'
import { useZoneMutation } from '@Api/endpoints/useZoneMutation'
import { useZonesQuery } from '@Api/endpoints/useZonesQuery'
import { useCreateContent } from './endpoints/contents/useCreateContent'
import { useUpdateContent } from './endpoints/contents/useUpdateContent'
import { useDeepLinkStoresListQuery } from './endpoints/useDeepLinkStoresListQuery'
import { useGenerateDeepLinkMutation } from './endpoints/useGenerateDeepLinkMutation'
import { useGetContentTypes } from './endpoints/useGetContentTypes'

export const api = {
  contents: {
    useCreateContent,
    useUpdateContent
  },
  banners: {
    useAddBannerMutation,
    useBannerDataQuery,
    useBannersListQuery: useItemsListQuery,
    useDeleteBannersMutation: useDeleteItemMutation
  },
  miNegocio: {
    useMiNegocioDataQuery: useItemsListQuery,
    longTip: {
      useGetLongTipQuery,
      useUploadLongTipMutation
    },
    metadata: {
      useGetAuthorsQuery,
      useGetCategoriesQuery,
      useGetTagsQuery,
      useGetSegmentsQuery,
      useMetadataList: useMetadataListQuery
    },
    video: {
      useAddVideoMutation,
      useVideoDataQuery
    },
    podcast: {
      useGetPodcastQuery,
      useUploadPodcastMutation
    },
    dailyFeed: {
      useGetDailyFeedQuery,
      useUploadDailyFeedMutation
    },
    challenge: {
      useGetChallengeQuery,
      useUploadChallengeMutation
    },
    event: {
      useAddEventMutation,
      useEventDataQuery
    }
  },
  deepLink: {
    useDeepLinkPathsListQuery,
    useGenerateDeepLinkMutation,
    useDeepLinkStoresListQuery
  },
  ddcs: {
    useDDCsListQuery
  },
  vendors: {
    useVendorsListQuery
  },
  files: {
    useUploadImageMutation,
    useUploadVideoMutation
  },
  locales: {
    useLocalesListQuery
  },
  pagePaths: {
    usePagePathsListQuery,
    useGetContentTypes
  },
  users: {
    useUsersListQuery
  },
  webView: {
    useAddWebViewMutation,
    useDeleteWebViewMutation: useDeleteItemMutation,
    useWebViewDataQuery,
    useWebViewListQuery: useItemsListQuery
  },
  zones: {
    useAlterZoneMutation,
    useZoneMutation,
    useZonesQuery
  }
}
