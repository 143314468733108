import Quill from 'quill'

const Blockquote = Quill.import('formats/blockquote')

class CustomBlockquote extends Blockquote {
  static create() {
    const blockquoteTag = document.createElement('blockquote')
    blockquoteTag.style.borderLeft = '4px solid #ccc'
    blockquoteTag.style.margin = '5px 0 5px'
    blockquoteTag.style.paddingLeft = '16px'
    blockquoteTag.style.fontSize = '14px'

    return blockquoteTag
  }
}

export const registerCustomBlockquote = () => {
  Quill.register(CustomBlockquote, true)
}
