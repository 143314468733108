import { toast } from '@Src/App'
import globalVars from '@Utils/constants'
import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react'

const fileUploadReducerActions = {
  add: 'add',
  update: 'update',
  remove: 'remove',
  finished: 'finished',
  clear: 'clear',
  addError: 'addError'
} as const

export interface FileUploadContext {
  uploadsInProgress: {
    id: string
    fileName: string
    progress: number
    error: boolean
  }[]
  dispatchFileUploadAction: React.Dispatch<FileUploaderAction>
  fileUploadReducerActions: typeof fileUploadReducerActions
}

type FileUploaderAction = {
  type: keyof typeof fileUploadReducerActions
  payload?: FileUploadContext['uploadsInProgress'][number]
}

const { localStorageFilesUploadsKey, localStorageFilesUploadsBeforeUnloadKey } = globalVars

const sortFileUploads = (fileUploads: FileUploadContext['uploadsInProgress']) => {
  return fileUploads.sort((a, b) => new Intl.Collator().compare(a.fileName, b.fileName))
}

const getStoredUploads = (): FileUploadContext['uploadsInProgress'] => {
  const storedUploads = localStorage.getItem(localStorageFilesUploadsKey)
  return storedUploads ? JSON.parse(storedUploads) : []
}

const getUniqueUploads = (
  storedUploads: FileUploadContext['uploadsInProgress'],
  localState: FileUploadContext['uploadsInProgress']
) => {
  const uniqueUploads = [...storedUploads, ...localState].reduce((acc, fileUpload) => {
    const foundFileUpload = acc.find((item) => item.id === fileUpload.id)
    if (!foundFileUpload) return [...acc, fileUpload]
    return acc
  }, [] as FileUploadContext['uploadsInProgress'])

  return uniqueUploads
}

const fileUploadeReducer = (
  state: FileUploadContext['uploadsInProgress'],
  action: FileUploaderAction
): FileUploadContext['uploadsInProgress'] => {
  const { type, payload } = action
  const { add, update, remove, finished, clear, addError } = fileUploadReducerActions

  switch (type) {
    case add: {
      if (!payload) return state
      const storedUploads = getStoredUploads()
      const uniqueUploads = getUniqueUploads(storedUploads, state)
      localStorage.setItem(localStorageFilesUploadsKey, JSON.stringify([...storedUploads, payload]))
      return sortFileUploads([...uniqueUploads, payload])
    }
    case update: {
      if (!payload) return state
      const storedUploads = getStoredUploads()
      const fileUploadsDiff = state.filter(({ id }) => id !== payload.id)
      const storedUploadsDiff = storedUploads.filter(({ id }) => id !== payload.id)
      const uniqueUploads = getUniqueUploads(storedUploadsDiff, fileUploadsDiff)
      localStorage.setItem(
        localStorageFilesUploadsKey,
        JSON.stringify([...storedUploadsDiff, payload])
      )
      return sortFileUploads([...uniqueUploads, payload])
    }
    case remove: {
      if (!payload) return state
      const storedUploads = getStoredUploads()
      const storedUploadsDiff = storedUploads.filter(({ id }) => id !== payload.id)
      const fileUploadsDiff = state.filter(({ id }) => id !== payload.id)
      if (payload.progress === 100 || payload.error) {
        const uniqueUploads = getUniqueUploads(storedUploadsDiff, fileUploadsDiff)
        localStorage.setItem(localStorageFilesUploadsKey, JSON.stringify(storedUploadsDiff))
        return sortFileUploads(uniqueUploads)
      }
      return state
    }
    case finished: {
      if (!payload) return state
      toast.success('File uploaded successfully.')
      return state
    }
    case clear: {
      const storedUploads = getStoredUploads()
      const storedUploadsDiff = storedUploads.filter(({ progress }) => progress !== 100)
      const fileUploadsDiff = state.filter(({ progress }) => progress !== 100)
      const uniqueUploads = getUniqueUploads(storedUploadsDiff, fileUploadsDiff)
      localStorage.setItem(localStorageFilesUploadsKey, JSON.stringify(storedUploadsDiff))
      return sortFileUploads(uniqueUploads)
    }
    case addError: {
      if (!payload) return state
      const storedUploads = getStoredUploads()
      const storedUploadsDiff = storedUploads.filter(({ id }) => id !== payload.id)
      const fileUploadsDiff = state.filter(({ id }) => id !== payload.id)
      const uniqueUploads = getUniqueUploads(storedUploadsDiff, fileUploadsDiff)
      localStorage.setItem(
        localStorageFilesUploadsKey,
        JSON.stringify([...storedUploadsDiff, { ...payload, error: true }])
      )
      return sortFileUploads([...uniqueUploads, { ...payload, error: true }])
    }
    default: {
      return state
    }
  }
}

const getUploadsWithError = (): FileUploadContext['uploadsInProgress'] => {
  const uploadsWithError = localStorage.getItem(localStorageFilesUploadsBeforeUnloadKey)
  return uploadsWithError ? JSON.parse(uploadsWithError) : []
}

const getInitialValue = () => {
  const storedUploads = getStoredUploads()
  const uploadsWithError = getUploadsWithError()
  const initialValue = storedUploads.reduce((acc, fileUpload) => {
    const foundUpload = uploadsWithError.find((uploadWithError) => {
      return uploadWithError.id === fileUpload.id
    })

    if (!foundUpload) return [...acc, fileUpload]
    return [...acc, foundUpload]
  }, [] as FileUploadContext['uploadsInProgress'])

  return sortFileUploads(initialValue)
}

const FileUploadContext = createContext<FileUploadContext>({} as FileUploadContext)

export const FileUploadContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [uploadsInProgress, dispatchFileUploadAction] = useReducer(
    fileUploadeReducer,
    [],
    getInitialValue
  )
  const value = useMemo<FileUploadContext>(() => {
    return { uploadsInProgress, dispatchFileUploadAction, fileUploadReducerActions }
  }, [uploadsInProgress])

  useEffect(() => {
    const setErrorOnFilesUploads = () => {
      const storedUploads = getStoredUploads()
      const uploadsWithError = storedUploads.reduce((acc, fileUpload) => {
        if (fileUpload.progress === 100) return acc

        return [
          ...acc,
          {
            ...fileUpload,
            error: true
          }
        ]
      }, [] as FileUploadContext['uploadsInProgress'])
      localStorage.setItem(
        localStorageFilesUploadsBeforeUnloadKey,
        JSON.stringify(uploadsWithError)
      )
    }

    window.addEventListener('beforeunload', setErrorOnFilesUploads)

    return () => {
      window.removeEventListener('beforeunload', setErrorOnFilesUploads)
    }
  }, [])

  return <FileUploadContext.Provider value={value}>{children}</FileUploadContext.Provider>
}

export const useFileUploadContext = () => useContext(FileUploadContext)
