import {
  AddContentBodyV2,
  CmsBaseUrl,
  EndpointFunction
} from '@Utils/getEndpoints/getEndpointsDEPRECATED.types'

type AddContentV2 = { body: AddContentBodyV2; zone: string; pagePath: string } & CmsBaseUrl

const addContentV2: EndpointFunction = (builder) => ({
  mock: '',
  real: builder.mutation<unknown, AddContentV2>({
    query: ({ cmsBaseUrl, zone, body, pagePath }) => ({
      url: `${cmsBaseUrl}/contentedit/zone_${zone.toLowerCase()}/page/${pagePath}`,
      method: 'PUT',
      body
    }),
    invalidatesTags: ['Content', 'Modules']
  })
})

export default addContentV2
