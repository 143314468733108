import { BasePropsStyles } from '@Styles/sharedStyles'
import pxToRem from '@Utils/pxToRem/pxToRem'
import InputLabel from '@mui/material/InputLabel'
import styled, { css } from 'styled-components'
import { LabelProps } from './Label.types'

export const StyledLabel = styled(InputLabel)<LabelProps>`
  ${BasePropsStyles};
  color: ${({ textcolor, theme }) => textcolor || theme.palette.text?.primary};
  line-height: ${({ $lineHeight }) => $lineHeight};
  font-size: ${pxToRem(14)};
  font-weight: 500;

  ${({ shrink }) =>
    shrink &&
    css`
      transform: none;
      top: -24px;
    `}

  span {
    color: ${({ theme: { palette } }) => palette.grey && palette.grey[600]};
    font-size: ${pxToRem(12)};
    margin-left: 3px;
  }
`
