import { Box } from '@BarrelComponents'
import pxToRem from '@Utils/pxToRem/pxToRem'
import { TextButton } from '@hexa-ui/components'
import React, { memo } from 'react'
import { ButtonDropDownProps } from './ButtonDropDown.types'

const ButtonDropDown = ({ onClick, label, disabled, padding, icon: Icon }: ButtonDropDownProps) => {
  const handleBoxClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }
  return (
    <Box
      onClick={disabled ? handleBoxClick : onClick}
      sx={{
        padding: padding ?? `${pxToRem(20)} ${pxToRem(16)}`,
        '&:hover': {
          backgroundColor: '#F2F2F2',
          fontWeight: 500
        }
      }}
    >
      <TextButton
        onClick={onClick}
        disabled={disabled}
        color="black"
        css={{
          display: 'flex',
          alignItems: 'center',
          columnGap: `${pxToRem(8)}`,
          textDecoration: 'none'
        }}
      >
        {Icon && (
          <span style={{ display: 'flex', color: disabled ? '#707372' : '#000' }}>
            <Icon />
          </span>
        )}
        {label}
      </TextButton>
    </Box>
  )
}

export const MemoizedButtonDropDown = memo(ButtonDropDown)
