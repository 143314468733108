import { UseQuery } from '@Api/api.types'
import { useApplicationInfo } from '@Api/hooks/useApplicationInfo'
import { useBuildQueryKeys } from '@Api/hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '@Api/hooks/usePrepareHeaders'
import { queryFn } from '@Api/utils/queryFn'
import { useApi } from '@Src/shared/api/hooks/useApi'
import { useQuery } from '@tanstack/react-query'

export interface IMiNegocioTagsResponse {
  data: IMiNegocioTagsData[]
}

export interface IMiNegocioTagsData {
  id: number
  name: string
  isEnabled: boolean
  createdAt: string
  updatedAt: string | null
  updatedBy: string | null
}

export const useGetTagsQuery = ({
  queryKeys,
  options
}: UseQuery<IMiNegocioTagsResponse, Error>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()
  const { api } = useApi()

  const itemsListQueryKeys = useBuildQueryKeys([...queryKeys])

  const response = useQuery<IMiNegocioTagsResponse, Error>(
    itemsListQueryKeys,
    queryFn(api, `${cmsCoreBackend}/contentedit/zone_${zone.toLowerCase()}/metadata/user-tags`, {
      headers
    }),
    options
  )

  return response
}
