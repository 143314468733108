import { Zones } from '@content-shared-components/image-uploader'
import { useAppSelector } from '@Redux/hooks/reduxHooks'
import { useGetApiHost } from '@Src/shared/hooks/useGetApiHost/useGetApiHost'

export const useApplicationInfo = () => {
  const { zone, storeId, cluster } = useAppSelector(({ application }) => {
    const { rawZone, storeId, cluster } = application
    return { zone: rawZone, storeId, cluster }
  })

  const { cmsCoreBackend, imageUpload } = useGetApiHost({
    zone: zone as Zones
  })

  return { zone, storeId, cluster, cmsCoreBackend, imageUpload }
}
