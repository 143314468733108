import { Box, TextField } from '@BarrelComponents'
import { Calendar } from '@hexa-ui/icons'
import { TextFieldProps } from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import React from 'react'
import { DatePickerStyles, StyledDatePicker } from './DatePicker.styles'
import { DatePickerProps } from './DatePicker.types'

export const CalendarIcon = () => <Calendar style={{ fill: 'black' }} />

export const DatePicker = ({
  placeholder,
  error,
  id,
  helperText,
  views = ['day'],
  mask = '__/__/____',
  $hexaUI,
  ...props
}: Omit<DatePickerProps, 'renderInput'>) => {
  return (
    <Box>
      <Box className={$hexaUI ? 'date-picker' : ''}>
        <DatePickerStyles />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StyledDatePicker
            {...props}
            views={[...views]}
            mask={mask}
            allowSameDateSelection
            components={{
              OpenPickerIcon: CalendarIcon
            }}
            renderInput={(params: TextFieldProps) => {
              return (
                <TextField
                  {...params}
                  id={id}
                  variant="outlined"
                  error={error}
                  helperText={helperText}
                  $hexaUI={$hexaUI}
                  inputProps={{
                    ...params.inputProps,
                    placeholder
                  }}
                />
              )
            }}
            PopperProps={{
              disablePortal: true
            }}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  )
}
