import Quill from 'quill'
import { customIcons } from './toolbarIcons'

interface RegisterCustomIcons {
  [key: string]: string | Record<string, string>
}

const quillIcons = Quill.import('ui/icons')

export const registerCustomIcons = (
  parentRef: Record<string, string> = quillIcons,
  icons: RegisterCustomIcons = customIcons
) => {
  Object.entries(icons).forEach(([key, value]) => {
    if (typeof value === 'string') {
      parentRef[key] = value
      return
    }

    registerCustomIcons(parentRef[key] as any, value)
  })
}
