import { useAppSelector } from '@Redux/hooks/reduxHooks'
import {
  TUseFeatureToggle,
  useFeatureToggle,
  UseFeatureToggleAttributes
} from '@Src/shared/hooks/useFeatureToggle/useFeatureToggle'
import { Zone } from '@Src/types/zone'
import React, { createContext, useContext, useMemo } from 'react'

import { FeatureToggles } from '@Hooks/useFeatureToggle/feature-toggles'

type FeatureToggle<TFeature = any> = [
  TUseFeatureToggle['isEnabled'],
  TUseFeatureToggle['isLoading'],
  TUseFeatureToggle<TFeature>['variables']
]

type FeatureTogglesContextValue = {
  [toggle in FeatureToggles]: FeatureToggle<toggle>
}

const useFeat = <TFeature extends FeatureToggles>(
  featureName: TFeature,
  attributes?: UseFeatureToggleAttributes
): FeatureToggle<TFeature> => {
  const { isEnabled, isLoading, variables } = useFeatureToggle(featureName, attributes)

  return [isEnabled, isLoading, variables]
}

const useFeatureTogglesWithZone = (zone: Zone): Partial<FeatureTogglesContextValue> => ({
  mn_timezone_enabled: useFeat(FeatureToggles.MN_TIMEZONE_ENABLED, { zone }),
  customer_timezone_enabled: useFeat(FeatureToggles.CUSTOMER_TIMEZONE_ENABLED, { zone }),
  mn_audience_selector_enabled: useFeat(FeatureToggles.MN_AUDIENCE_SELECTOR_ENABLED, { zone }),
  customer_audience_segmentation_enabled: useFeat(
    FeatureToggles.CUSTOMER_AUDIENCE_SEGMENTATION_ENABLED,
    {
      zone
    }
  ),
  multi_modules_enabled: useFeat(FeatureToggles.MULTI_MODULES_ENABLED, { zone }),
  customer_tagging_enabled: useFeat(FeatureToggles.CUSTOMER_TAGGING_ENABLED, {
    zone
  })
})

const useFeatureTogglesWithoutZone = (): Partial<FeatureTogglesContextValue> => ({
  mn_long_tip_enabled: useFeat(FeatureToggles.MN_LONG_TIP_ENABLED),
  mn_podcast_enabled: useFeat(FeatureToggles.MN_PODCAST_ENABLED),
  mn_video_enabled: useFeat(FeatureToggles.MN_VIDEO_ENABLED),
  mn_daily_feed_enabled: useFeat(FeatureToggles.MN_DAILY_FEED_ENABLED),
  mn_challenge_enabled: useFeat(FeatureToggles.MN_CHALLENGE_ENABLED),
  mn_single_event_enabled: useFeat(FeatureToggles.MN_SINGLE_EVENT_ENABLED),
  mn_event_enabled: useFeat(FeatureToggles.MN_EVENT_ENABLED),
  mn_metadata_tab_enabled: useFeat(FeatureToggles.MN_METADATA_TAB_ENABLED),
  mn_multi_language_metadata_enabled: useFeat(FeatureToggles.MN_MULTI_LANGUAGE_METADATA_ENABLED),
  mn_multiple_live_launches_enabled: useFeat(FeatureToggles.MN_MULTIPLE_LIVE_LAUNCHES_ENABLED),
  mn_rich_text_editor_enabled: useFeat(FeatureToggles.MN_RICH_TEXT_EDITOR_ENABLED),
  customer_video_banners_enabled: useFeat(FeatureToggles.CUSTOMER_VIDEO_BANNERS_ENABLED),
  deep_link_generator_enabled: useFeat(FeatureToggles.DEEP_LINK_GENERATOR_ENABLED),
  monitoring_dashboard_enabled: useFeat(FeatureToggles.MONITORING_DASHBOARD_ENABLED),
  content_type_endpoint_enabled: useFeat(FeatureToggles.CONTENT_TYPE_ENDPOINT_ENABLED)
})

const FeatureTogglesContext = createContext<FeatureTogglesContextValue>(
  {} as FeatureTogglesContextValue
)

export const useFeatureTogglesContext = () => useContext(FeatureTogglesContext)

export const FeatureTogglesContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { rawZone } = useAppSelector(({ application }) => application)
  const zone = rawZone as Zone

  const featureTogglesWithZone = useFeatureTogglesWithZone(zone)
  const featureTogglesWithoutZone = useFeatureTogglesWithoutZone()

  const value = useMemo(
    () =>
      ({ ...featureTogglesWithZone, ...featureTogglesWithoutZone } as FeatureTogglesContextValue),
    [featureTogglesWithZone, featureTogglesWithoutZone]
  )

  return <FeatureTogglesContext.Provider value={value}>{children}</FeatureTogglesContext.Provider>
}
