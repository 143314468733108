import zoneConfiguration from '@Configuration/zoneConfiguration'

import { zoneCluster, type Cluster } from '@Src/configuration/zoneCluster'
import { Environment } from '@Src/types/environment'
import type { Zone } from 'src/types/zone'
import { isInDTC } from '../isInDTC/isInDTC'

export const zonesWithMiNegocio = (): Zone[] => {
  return (Object.keys(zoneConfiguration) as Zone[]).reduce((result: Zone[], zoneKey: Zone) => {
    const zoneConfig = zoneConfiguration[zoneKey]
    if (zoneConfig?.features?.hasMiNegocio) return [...result, zoneKey]
    return result
  }, [] as Zone[])
}

export const zonesWithCustomer = (): Zone[] => {
  return (Object.keys(zoneConfiguration) as Zone[]).reduce((result: Zone[], zoneKey: Zone) => {
    const zoneConfig = zoneConfiguration[zoneKey]
    if (zoneConfig?.features?.hasCustomer) return [...result, zoneKey]
    return result
  }, [] as Zone[])
}

export const zonesWithPartnerStorefront = (): Zone[] => {
  return (Object.keys(zoneConfiguration) as Zone[]).reduce((result: Zone[], zoneKey: Zone) => {
    const zoneConfig = zoneConfiguration[zoneKey]
    if (zoneConfig?.features?.hasPartnerStorefront) return [...result, zoneKey]
    return result
  }, [] as Zone[])
}

export const zonesWithAdvancedFields = (): Zone[] => {
  return (Object.keys(zoneConfiguration) as Zone[]).reduce((result: Zone[], zoneKey: Zone) => {
    const zoneConfig = zoneConfiguration[zoneKey]
    if (zoneConfig?.features?.hasAdvancedFields) return [...result, zoneKey]
    return result
  }, [] as Zone[])
}

export const getZoneLabelByCode = (code: string): { code: string; label: string } => {
  const zone = code.toLocaleLowerCase() as Zone

  if ((zone as string) === 'test')
    return {
      code: 'test',
      label: 'Test Zone'
    }

  const countryLabel = zoneConfiguration[zone]?.label || code

  return { code, label: countryLabel }
}

export const getZoneCluster = (zone: Zone, env: Environment): Cluster => {
  const isDTC = isInDTC(window.location.origin)
  if (isDTC) return 'dtc'

  const lowerCaseZone = zone.toLowerCase() as Zone
  if (!zoneCluster[lowerCaseZone]) throw new Error(`Invalid zone: ${zone}`)

  const cluster = zoneCluster[lowerCaseZone][env]
  if (!cluster) throw new Error(`No cluster found for zone: ${zone} and environment: ${env}`)

  return cluster
}

export const getZoneFeatures = (zone: Zone, zonesConfig = zoneConfiguration) => {
  return zonesConfig[zone]?.features || {}
}
