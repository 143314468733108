import { Zones } from '@content-shared-components/image-uploader'
import { changeStore, changeZone } from '@Redux/slices/application/applicationSlice'
import { apiUtils, tagTypes } from '@Src/shared/redux/slices/api/apiSlice'
import { isInDTC } from '@Src/shared/utils/isInDTC/isInDTC'
import { getStores, hasStore } from '@Src/shared/utils/storeUtils/storeUtils'
import globalVars from '@Utils/constants'
import { getDefaultCluster } from '@Utils/getDefaultCluster/getDefaultCluster'
import getLocalStorage from '@Utils/getLocalStorage/getLocalStorage'
import { handleOnZoneChangeProps } from '../ContentList.types'

const CUSTOMER_PAGE_PATH = '/cms/banners-launch-list/customer'

export const handleOnZoneChange = async (props: handleOnZoneChangeProps) => {
  const {
    value,
    env,
    setSelectedZone,
    setSelectedStore,
    setZonesSelect,
    setCluster,
    refetchContentList,
    refetchContentTypes,
    dispatch
  } = props

  const [rawZone, zone, cluster] = value.toLowerCase().split(':') as [Zones, Zones, string]

  setSelectedZone(value)
  setZonesSelect(value)
  setCluster(cluster || getDefaultCluster())

  localStorage.setItem(globalVars.localStorageZoneKey, rawZone)
  localStorage.setItem(globalVars.localStorageRawZoneKey, zone)
  localStorage.setItem(globalVars.localStorageClusterKey, cluster || getDefaultCluster())

  await dispatch(changeZone(value.toLowerCase()))

  const localStorageStoreKey = `${globalVars.localStorageStoreKey}:${rawZone}${
    cluster ? `:${cluster}` : ''
  }`

  const lastSelectedStore = getLocalStorage(localStorageStoreKey, '')

  const refreshData = async () => {
    await dispatch(apiUtils.invalidateTags(tagTypes as any))
    refetchContentList()
    refetchContentTypes()
  }

  const handleStoreChange = async (storeId: string) => {
    await dispatch(changeStore(storeId))
    setSelectedStore(storeId)
    localStorage.setItem(localStorageStoreKey, storeId)
    return refreshData()
  }

  const { pathname } = window.location
  const isCustomerPage = pathname === CUSTOMER_PAGE_PATH
  const isDTC = isInDTC(window.location.origin)

  const isStoreValid = hasStore({
    zone,
    storeId: lastSelectedStore,
    env,
    cluster: isDTC ? 'dtc' : undefined,
    storeType: isCustomerPage ? 'customer' : 'partner'
  })

  if (isStoreValid) return handleStoreChange(lastSelectedStore)

  const stores = getStores({ zone, env, cluster: isDTC ? 'dtc' : undefined })
  const store = stores.find((store) => {
    return isCustomerPage ? store.isAbiStore : !store.isAbiStore
  })

  if (!store) throw new Error('No store found')

  return handleStoreChange(store.id)
}
