import React, { lazy } from 'react'

const LazyCheckboxPanel = lazy(
  () => import('@Src/modules/customer/ContentList/components/Filters/CheckboxPanel/CheckboxPanel')
)
const LazyEditedByPanel = lazy(
  () => import('@Src/modules/customer/ContentList/components/Filters/EditedByPanel/EditedByPanel')
)
const LazyLaunchDatePanel = lazy(
  () =>
    import('@Src/modules/customer/ContentList/components/Filters/LaunchDatePanel/LaunchDatePanel')
)

export const FiltersTabPanels = (props: any) => {
  const {
    control,
    formattedContentTypes,
    contentTypeFilters,
    setContentTypeFilters,
    statusFilters,
    setStatusFilters,
    setSelectedTab,
    contentTypesAndStatusTranslations
  } = props

  return [
    <LazyCheckboxPanel
      control={control}
      name="contentTypeFilter"
      contentTypesAndStatusTranslations={contentTypesAndStatusTranslations}
      data={formattedContentTypes}
      values={contentTypeFilters}
      setValues={setContentTypeFilters}
    />,
    <LazyCheckboxPanel
      control={control}
      name="statusFilter"
      contentTypesAndStatusTranslations={contentTypesAndStatusTranslations}
      data={['live', 'past', 'scheduled']}
      values={statusFilters}
      setValues={setStatusFilters}
    />,
    <LazyEditedByPanel control={control} />,
    <LazyLaunchDatePanel control={control} setSelectedTab={setSelectedTab} />
  ]
}
