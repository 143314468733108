import Quill from 'quill'

const List = Quill.import('formats/list')

class CustomList extends List {
  static create(tag: 'ordered' | 'bullet') {
    const listTag = document.createElement(tag === 'ordered' ? 'ol' : 'ul')
    listTag.style.paddingLeft = '24px'

    return listTag
  }
}

export const registerCustomList = () => {
  Quill.register(CustomList, true)
}
