import { colors } from '@Styles/theme/colors'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import styled from 'styled-components'
import { TooltipProps } from './Tooltip.types'

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    padding: 12px 16px;
    background-color: ${colors.grey[600]};
    color: ${colors.white};
    font-size: 14px;
    line-height: 1;
    text-align: center;
  }
`
