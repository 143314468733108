import { isInDTC } from '@Utils/isInDTC/isInDTC'
import { updateSelectedZoneProps } from '../ContentList.types'

export const updateSelectedZone = (props: updateSelectedZoneProps) => {
  const { setSelectedZone, setZonesSelect, zonesWithLabel, locale, cluster } = props

  if (!zonesWithLabel) return

  const foundZone = zonesWithLabel.find(({ value }: any) => {
    return value.toLowerCase().includes(locale)
  })

  let foundZoneWithCluster = `${foundZone?.value || ''}`
  if (!(foundZone && isInDTC(foundZone.value)))
    foundZoneWithCluster += cluster !== 'default' ? `:${cluster}` : ''

  setSelectedZone(foundZoneWithCluster || 'Country')
  setZonesSelect(foundZoneWithCluster || 'Country')
}
