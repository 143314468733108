import Quill from 'quill'

export const registerInlineStyles = () => {
  const attributors = ['align', 'background', 'color', 'direction', 'font', 'size']

  attributors.forEach((attributor) => {
    const inlineStyle = Quill.import(`attributors/style/${attributor}`)
    Quill.register(inlineStyle, true)
  })
}
