import { TextField } from '@BarrelComponents'
import { Clock } from '@hexa-ui/icons'
import { TextFieldProps } from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import React from 'react'
import { StyledTimePicker, TimePickerContainer, TimePickerStyles } from './TimePicker.styles'
import { TimePickerProps } from './TimePicker.types'

// eslint-disable-next-line
// @ts-ignore
export const ClockIcon = () => <Clock css={{ fill: 'black' }} />

export const TimePicker = ({
  placeholder = 'Set the launch time',
  error,
  helperText,
  $hexaUI,
  ...props
}: Omit<TimePickerProps, 'renderInput'>) => {
  return (
    <TimePickerContainer className={$hexaUI ? 'time-picker' : ''}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePickerStyles />
        <StyledTimePicker
          {...props}
          showToolbar
          ampm
          toolbarTitle=""
          renderInput={(params: TextFieldProps) => {
            return (
              <TextField
                {...params}
                error={error}
                helperText={helperText}
                $hexaUI={$hexaUI}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  placeholder
                }}
              />
            )
          }}
          components={{
            OpenPickerIcon: ClockIcon
          }}
          PopperProps={{
            disablePortal: true
          }}
        />
      </LocalizationProvider>
    </TimePickerContainer>
  )
}
