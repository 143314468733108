import { Box } from '@BarrelComponents'
import React, { useState } from 'react'
import { StyledImage, StyledImageBox } from './Image.styles'
import { ImageProps } from './Image.types'

export const Image = ({
  loading = 'lazy',
  src,
  $width,
  $height,
  $padding,
  $paddingLeft,
  $paddingRight,
  $paddingBottom,
  $paddingTop,
  $margin,
  $marginBottom,
  $marginLeft,
  $marginRight,
  $marginTop,
  ...props
}: ImageProps) => {
  const [isLoading, setIsLoading] = useState(true)

  const onLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const imageElement = e.target as HTMLImageElement
    setIsLoading(false)
    imageElement.style.opacity = '1'
  }

  return (
    <Box
      $padding={$padding}
      $paddingBottom={$paddingBottom}
      $paddingLeft={$paddingLeft}
      $paddingRight={$paddingRight}
      $paddingTop={$paddingTop}
      $margin={$margin}
      $marginBottom={$marginBottom}
      $marginLeft={$marginLeft}
      $marginRight={$marginRight}
      $marginTop={$marginTop}
      $height={$height}
      $width={$width}
      sx={{ position: 'relative' }}
    >
      {isLoading && <StyledImageBox />}
      <StyledImage {...props} src={src} loading={loading} onLoad={onLoad} />
    </Box>
  )
}
