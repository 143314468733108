import { Zones } from '@content-shared-components/image-uploader'
import b2bConfig from '@Src/configuration/b2bStoreConfiguration'
import dtcConfig from '@Src/configuration/dtcStoreConfiguration'
import { EnvZonesStores } from '@Src/shared/contexts/ZonesContext/ZonesContext.types'
import { isInDTC } from '@Src/shared/utils/isInDTC/isInDTC'
import { Store } from '@Src/shared/utils/storeUtils/class/Store'
import { StoreFeatures } from '@Src/shared/utils/storeUtils/class/StoreFeatures'
import { Environment } from '@Src/types/environment'
import { MI_NEGOCIO_STORE_ID } from '../constants'
import type {
  TGetStoreFeaturesProps,
  TGetStoreProps,
  TGetStoresProps,
  THasStoreProps,
  TIsAbiStoreProps
} from './storeUtils.types'

export function getStoreConfig(): EnvZonesStores {
  return isInDTC(window.location.origin) ? dtcConfig : b2bConfig
}

export function getStoresByZone(zone: Zones, env: Environment, stores: EnvZonesStores): Store[] {
  const rawStores = stores?.[env]?.[zone]?.stores || []
  return rawStores.map((store) => new Store(store))
}

export function isAbiStore(params: TIsAbiStoreProps, stores = b2bConfig): boolean | undefined {
  const { zone, storeId, env } = params
  const store = Store.findById(getStoresByZone(zone, env, stores), storeId)
  if (!store) {
    throw new Error(`Store not found for storeId: ${storeId} and zone: ${zone} on env: ${env}`)
  }
  return store.isAbiStore
}

export function hasStore(params: THasStoreProps): boolean {
  const { zone, storeId, env, storeType } = params
  const stores = getStoreConfig()
  const store = getStoresByZone(zone, env, stores).find((store) => {
    const shouldShowStore = storeType === 'customer' ? store.isAbiStore : !store.isAbiStore
    const isValidStore = ![MI_NEGOCIO_STORE_ID].includes(store.id)
    return store.id === storeId && shouldShowStore && isValidStore
  })
  return Boolean(store)
}

export function getStore(params: TGetStoreProps): Store | null {
  const { zone, env, storeId } = params
  const stores = getStoreConfig()
  return Store.findById(getStoresByZone(zone, env, stores), storeId)
}

export function getStoreFeatures(params: TGetStoreFeaturesProps): StoreFeatures | undefined {
  const { zone, env, storeId } = params
  const stores = getStoreConfig()
  const store = Store.findById(getStoresByZone(zone, env, stores), storeId)

  if (!store) {
    throw new Error(`Store not found for storeId: ${storeId} and zone: ${zone} on env: ${env}`)
  }
  return store.features
}

export function getStores(params: TGetStoresProps): Store[] {
  const { zone, env, storeType } = params
  const storeConfig = getStoreConfig()
  const rawStores = getStoresByZone(zone, env, storeConfig)

  return rawStores.filter((store) => {
    const shouldShowStore = storeType === 'customer' ? store.isAbiStore : !store.isAbiStore

    const isValidStore = ![MI_NEGOCIO_STORE_ID].includes(store.id)
    return (!storeType || shouldShowStore) && isValidStore
  })
}
