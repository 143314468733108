import { useTranslationsContext } from '@Src/shared/contexts/I18nContext/I18nContext'
import { Browser, Linked, Smartphone, Web } from '@hexa-ui/icons'
import React from 'react'
import { translatedType } from '../ContentList.types'

export const TranslatedType = (): translatedType => {
  const {
    translations: { shared: sharedTranslations }
  } = useTranslationsContext()
  const {
    brandCarouselTypeText,
    guestHeroCarouselTypeText,
    heroCarouselTypeText,
    mobileBannersTypeText,
    streamlinedBannersTypeText,
    webviewLinkTypeText
  } = sharedTranslations
  return {
    GuestHeroCarousel: guestHeroCarouselTypeText,
    WebViewLink: webviewLinkTypeText,
    MobileBanners: mobileBannersTypeText,
    StreamlinedBanners: streamlinedBannersTypeText,
    BrandCarousel: brandCarouselTypeText,
    HeroCarousel: heroCarouselTypeText
  }
}

export const iconMapping = (
  translated: string
): Record<keyof typeof TranslatedType, JSX.Element> => {
  const getTranslatedType = TranslatedType()
  const getIcon = {
    [getTranslatedType.GuestHeroCarousel]: <Browser />,
    [getTranslatedType.WebViewLink]: <Linked />,
    [getTranslatedType.MobileBanners]: <Smartphone />,
    [getTranslatedType.StreamlinedBanners]: <Smartphone />,
    [getTranslatedType.BrandCarousel]: <Web />,
    [getTranslatedType.HeroCarousel]: <Web />
  }
  return getIcon[translated]
}
