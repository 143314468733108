import { BasePropsStyles } from '@Styles/sharedStyles'
import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import { ModalHeaderProps } from './ModalHeader.types'

export const StyledModalHeader = styled(DialogTitle)<ModalHeaderProps>`
  ${BasePropsStyles};
  line-height: ${({ $lineHeight }) => $lineHeight};
  letter-spacing: ${({ $letterSpacing }) => $letterSpacing};
  text-align: ${({ align }) => align};
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
`
