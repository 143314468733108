import dayjs, { Dayjs as DayjsTypes } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

// Plugins
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)

const dayjsInstance = dayjs as unknown as typeof DayjsTypes

function formatTimeZoneOffset(timezone: string) {
  const offsetHours = dayjs().tz(timezone).utcOffset() / 60
  const formattedOffset = offsetHours >= 0 ? `+${offsetHours}` : `${offsetHours}`

  return `(GMT${formattedOffset}) - ${timezone}`
}

function formatTimeZoneOnly(timezone: string) {
  const offsetHours = dayjs().tz(timezone).utcOffset() / 60
  const formattedOffset = offsetHours >= 0 ? `+${offsetHours}` : `${offsetHours}`
  return `GMT${formattedOffset}`
}

export { DayjsTypes, dayjs, dayjsInstance, formatTimeZoneOffset, formatTimeZoneOnly }
