import { apiSlice } from '@Redux/slices/api/apiSlice'
import applicationReducer from '@Redux/slices/application/applicationSlice'
import contentReducer from '@Redux/slices/content/contentSlice'
import userReducer from '@Redux/slices/user/userSlice'
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'

const store = configureStore({
  reducer: {
    application: applicationReducer,
    user: userReducer,
    content: contentReducer,
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(apiSlice.middleware)
  }
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
