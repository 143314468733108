import { IconButton } from '@BarrelComponents'
import CloseRounded from '@mui/icons-material/CloseRounded'
import React, { forwardRef } from 'react'
import { StyledInteractiveChip } from './InteractiveChip.styles'
import { InteractiveChipProps } from './InteractiveChip.types'

export const InteractiveChip = forwardRef<HTMLDivElement | null, InteractiveChipProps>(
  ({ size = 'medium', color = 'secondary', deleteIcon, ...props }, ref) => {
    return (
      <StyledInteractiveChip
        {...props}
        ref={ref}
        color={color}
        size={size}
        deleteIcon={
          color === 'secondary' || color === 'error' ? (
            <IconButton>
              <CloseRounded />
            </IconButton>
          ) : (
            deleteIcon
          )
        }
      />
    )
  }
)
