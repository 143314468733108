import { useQuery } from '@tanstack/react-query'
import { UseQuery } from '../api.types'
import { useApi } from '../hooks/useApi'
import { useApplicationInfo } from '../hooks/useApplicationInfo'
import { useBuildQueryKeys } from '../hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'
import { queryFn } from '../utils/queryFn'

interface User {
  username: string
  zone: Record<string, string[]>
  createdAt: Date
  createdByGuid: string
}

export interface UseUsersListQuery {
  data: {
    data: User[]
  }
}

export const useUsersListQuery = ({ queryKeys, options }: UseQuery<UseUsersListQuery, Error>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()
  const { api } = useApi()

  const itemsListQueryKeys = useBuildQueryKeys(queryKeys)

  const query = useQuery<UseUsersListQuery, Error>(
    itemsListQueryKeys,
    queryFn(
      api,
      `${cmsCoreBackend}/contentedit/zone_${zone.toLowerCase()}/page-user-email-list.json`,
      {
        headers
      }
    ),
    options
  )

  return query
}
