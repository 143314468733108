import { Stack } from '@BarrelComponents'

import { DeleteModalContent } from '@Src/modules/customer/ContentList/components/DeleteModalContent/DeleteModalContent'
import { DeleteModalFooter } from '@Src/modules/customer/ContentList/components/DeleteModalFooter/DeleteModalFooter'
import { useControllerContentList } from '@Src/modules/customer/ContentList/useControllerContentList'
import { useTranslationsContext } from '@Src/shared/contexts/I18nContext/I18nContext'
import globalVars from '@Utils/constants'
import pxToRem from '@Utils/pxToRem/pxToRem'
import slugify from '@Utils/slugify/slugify'
import {
  Breadcrumb,
  Button,
  Card,
  Dialog,
  Heading,
  IconButton,
  Select,
  SkeletonLoader
} from '@hexa-ui/components'
import { Edit2, Plus, Trash2, World } from '@hexa-ui/icons'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { AppHeaderProps, BreadcrumbOptionFn, BreadcrumbOptions } from './AppHeader.types'
import { MemoizedLinkDropDown } from './components/LinkDropDown/LinkDropDown'
import { useContent } from './hooks/useContent'

const PlusIcon = () => <Plus />
const WorldIcon = () => <World data-ref="world-icon" />
const EditIcon = () => <Edit2 />
const TrashIcon = () => <Trash2 />

const isDropDownStyles = {
  '& + .active': {
    pointerEvents: 'all',
    visibility: 'visible'
  }
}

const SkeletonModalLoading = () => (
  <Stack gap={pxToRem(8)}>
    <SkeletonLoader width="40%" height={pxToRem(24)} />
    <SkeletonLoader width="80%" height={pxToRem(24)} />
    <SkeletonLoader height={pxToRem(24)} />
  </Stack>
)

export const AppHeader = ({
  $marginBottom = pxToRem(24),
  headerText,
  onActionButtonClick,
  showActionButton = true,
  actionButtonText,
  actionButtonIcon = PlusIcon,
  breadcrumbOptions, // used for passing down the breadcrumb options manually
  breadcrumbOptionsComplement, // used for complementing each breadcrumb option with a partial url and/or onClick function
  showZoneAndStoreSelects = true,
  selectedZone,
  onZoneChange,
  zones,
  showZonesIcon = true,
  disableZonesSelect = false,
  selectedStore,
  onStoreChange,
  stores,
  disableStoresSelect = false,
  zonesSelectPlaceholder = 'Country',
  storesSelectPlaceholder = 'Store',
  isDropDown = false,
  dropdownOptions,
  adornment,
  onEnableEdit,
  ...props
}: AppHeaderProps) => {
  const { pathname } = useLocation()
  const { guid } = useParams()
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbOptions[]>([])
  const buttonRef = useRef<HTMLButtonElement>(null)
  const dropDownRef = useRef<HTMLDivElement>(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const { content } = useContent({ targetLaunch: guid as string })

  const { formattedTableData, isLoading } = useControllerContentList()

  const {
    translations: { contentEditorPage: launchEditorTranslations }
  } = useTranslationsContext()

  const { editContentText } = launchEditorTranslations

  useEffect(() => {
    if (breadcrumbOptions) return setBreadcrumbs(breadcrumbOptions)

    // all url params must be deleted from the url
    const normalizedRoute = pathname.replace('/', '').replace(`/${guid}`, '')
    const paths = normalizedRoute.split('/')

    const breadcrumbs = paths.map((path, index) => {
      const normalizedPath = slugify(path, { invert: true }).replace(/^(\w)|(\s\w)/g, (match) => {
        return match.toUpperCase()
      })

      let to = ''
      let onClick: BreadcrumbOptionFn | undefined

      for (let n = 0; n <= index; n++) {
        const newURL = `${to}/${paths[n]}`

        const urlComplement = breadcrumbOptionsComplement?.[newURL]?.partialURL || ''
        to = `${newURL}${urlComplement}`

        onClick = breadcrumbOptionsComplement?.[newURL]?.onClick
      }

      const breadcrumbOption = {
        to,
        text: normalizedPath,
        isCurrentPage: paths.length - 1 === index
      }

      if (typeof onClick === 'function') {
        Object.defineProperty(breadcrumbOption, 'onClick', { value: onClick })
      }

      return breadcrumbOption
    })

    setBreadcrumbs(breadcrumbs)
  }, [breadcrumbOptions, guid, pathname, breadcrumbOptionsComplement])

  const handleClickActionButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!isDropDown && typeof onActionButtonClick === 'function') {
      return onActionButtonClick(e)
    }
    dropDownRef.current?.classList.toggle('active')
  }

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const eventTarget = event.target as Node

      if (
        !buttonRef ||
        buttonRef.current?.contains(eventTarget) ||
        dropDownRef?.current?.contains(eventTarget) ||
        !dropDownRef?.current?.classList.contains('active')
      ) {
        return
      }
      dropDownRef.current?.classList.remove('active')
    }
    document.addEventListener('click', listener)
    return () => {
      document.removeEventListener('click', listener)
    }
  }, [])

  return (
    <Stack
      {...props}
      direction="column"
      $marginBottom={$marginBottom}
      spacing={pxToRem(16)}
      data-ref="app-header"
    >
      <Dialog.Root
        data-ref="contentList.modal"
        title={<Heading size="H3">Delete banner?</Heading>}
        onClose={() => setIsDeleteModalOpen(false)}
        actions={
          <DeleteModalFooter
            variant="banner"
            setOpen={setIsDeleteModalOpen}
            targetLaunch={guid as string}
          />
        }
        open={isDeleteModalOpen}
      >
        {isLoading ? (
          <SkeletonModalLoading />
        ) : (
          <DeleteModalContent
            variant="minified"
            guid={guid as string}
            content={formattedTableData}
          />
        )}
      </Dialog.Root>
      <Stack justifyContent="space-between" direction="row" data-ref="app-header-first-line">
        <Breadcrumb.Root>
          <Breadcrumb.HomeItem asChild>
            <Link to="/">Home</Link>
          </Breadcrumb.HomeItem>
          {breadcrumbs?.map(({ text, to, onClick, isCurrentPage }) => (
            <Breadcrumb.Item key={to} asChild isCurrentPage={isCurrentPage}>
              <Link to={to} onClick={onClick}>
                {text}
              </Link>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb.Root>

        {showZoneAndStoreSelects && (
          <Stack
            direction="row"
            spacing={pxToRem(16)}
            sx={{
              '& > *': {
                marginTop: '0 !important'
              }
            }}
          >
            {adornment && adornment}
            {!!zones?.length && (
              <Select.Root
                size="small"
                shape="pill"
                placeholder={zonesSelectPlaceholder}
                icon={showZonesIcon ? <WorldIcon /> : undefined}
                value={selectedZone}
                onChange={onZoneChange}
                disabled={disableZonesSelect || zones.length === 1}
              >
                {zones.map(({ value, label }) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select.Root>
            )}

            {!!stores?.length && (
              <Select.Root
                size="small"
                shape="pill"
                placeholder={storesSelectPlaceholder}
                value={selectedStore}
                onChange={onStoreChange}
                disabled={disableStoresSelect || stores.length === 1}
              >
                {stores.map(({ value, label }) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select.Root>
            )}
          </Stack>
        )}
      </Stack>
      <Stack justifyContent="space-between" direction="row" data-ref="app-header-second-line">
        <Heading size="H2">{headerText}</Heading>
        {onEnableEdit && (
          <Stack position="relative" direction="row" spacing={2}>
            <IconButton
              data-testid="open-modal-icon-button"
              size="medium"
              onClick={() => setIsDeleteModalOpen(true)}
              icon={TrashIcon}
              variant="tertiary"
              disabled={content?.status === 'Live'}
            />
            <Button
              ref={buttonRef}
              icon={EditIcon}
              leading
              size="medium"
              variant="secondary"
              onClick={onEnableEdit}
            >
              {editContentText}
            </Button>
          </Stack>
        )}
        {showActionButton && (
          <Stack position="relative">
            <Button
              ref={buttonRef}
              icon={actionButtonIcon}
              leading
              size="medium"
              variant="primary"
              onClick={handleClickActionButton}
              css={isDropDown ? isDropDownStyles : undefined}
            >
              {actionButtonText}
            </Button>
            <Card
              ref={dropDownRef}
              className="app-header-card"
              border="medium"
              elevated="small"
              css={{
                position: 'absolute',
                top: 50,
                right: 0,
                width: 179,
                zIndex: 12,
                padding: '4px 0',
                pointerEvents: 'none',
                visibility: 'hidden'
              }}
            >
              {!dropdownOptions && (
                <>
                  <MemoizedLinkDropDown
                    label="Event"
                    url={`new-event?action=${globalVars.createNewAction}`}
                  />
                  <MemoizedLinkDropDown label="Tip" url="/" disabled />
                  <MemoizedLinkDropDown label="Podcast" url="/" disabled />
                  <MemoizedLinkDropDown label="Video" url="/" disabled />
                  <MemoizedLinkDropDown label="Daily Feed" url="/" disabled />
                  <MemoizedLinkDropDown label="Challenge" url="/" disabled />
                </>
              )}
              {dropdownOptions?.map(({ label, url }) => (
                <MemoizedLinkDropDown key={url} label={label} url={url} />
              ))}
            </Card>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
