import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionActions from '@mui/material/AccordionActions'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import styled, { css } from 'styled-components'
import {
  AccordionActionsProps,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummaryProps
} from './Accordion.types'

export const StyledAccordionSummary = styled(MuiAccordionSummary)<
  BaseProps<typeof css, AccordionSummaryProps>
>`
  .MuiAccordionSummary-content {
    margin: 0 0;
    .MuiAccordionSummary-customImg {
      height: 64px;
      min-width: 267px;
      object-fit: cover;
      margin-right: 8px;
      flex: 1;
    }
    .MuiAccordionSummary-customContent {
      width: 100%;
      height: fit-content;
      display: flex;
      gap: 8px;
      align-items: center;
      .MuiAccordion-customTitleWrapper,
      .MuiAccordion-dragIconWrapper {
        display: inherit;
        align-items: center;
      }
      .MuiAccordion-dragIconWrapper {
        width: 24px;
        height: 40px;
        justify-content: center;
        color: ${({ theme }) => theme.palette?.grey?.[900]};
        &.expanded {
          color: ${({ theme }) => theme.palette?.grey?.[200]};
        }
      }
      .MuiAccordion-customTitleWrapper {
        .MuiAccordion-customTitle {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          margin: 20px 0 20px 12px;
        }
      }
    }
  }
  &.MuiAccordionSummary-root {
    .MuiAccordionSummary-expandIconWrapper {
      transform: rotate3d(1, 0, 0, 180deg);
    }
    &.Mui-expanded {
      .MuiAccordionSummary-customImg {
        visibility: hidden;
      }
      .MuiAccordionSummary-expandIconWrapper {
        transform: rotate3d(1, 0, 0, 360deg);
      }
      .MuiAccordion-customTitleWrapper {
        visibility: hidden;
      }
    }
    &.Mui-disabled {
      opacity: 1;
      .MuiAccordionSummary-customImg {
        margin-right: -18px;
      }
      .MuiAccordionSummary-expandIconWrapper {
        display: none;
      }
      .MuiAccordionSummary-customContent {
        .MuiAccordion-dragIconWrapper {
          display: none;
        }
      }
    }
  }
`
export const StyledAccordionDetails = styled(MuiAccordionDetails)<
  BaseProps<typeof css, AccordionDetailsProps>
>`
  &.MuiAccordionDetails-root {
    padding: 24px;
    background-color: ${({ theme }) => theme.palette?.grey?.[100]};
    border-top: 1px solid ${({ theme }) => theme.palette?.grey?.[200]};
  }
`
export const StyledAccordionActions = styled(MuiAccordionActions)<
  BaseProps<typeof css, AccordionActionsProps>
>`
  &.MuiAccordionActions-root {
    display: flex;
    padding: 24px;
    background-color: ${({ theme }) => theme.palette?.grey?.[100]};
    justify-content: flex-start;
    border-top: 1px solid ${({ theme }) => theme.palette?.grey?.[200]};
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    .MuiAccordionActions-configuration {
      flex: 1;
    }
    .MuiAccordionActions-btn {
      flex: 1;
    }
    .txtAlignRight {
      text-align: right;
    }
  }

  &.MuiAccordionActions-spacing {
  }
`

type StyledAccordionTypes = Omit<AccordionProps, 'expandedAccordion' | 'setExpandedAccordion'>

export const StyledAccordion = styled(MuiAccordion)<StyledAccordionTypes>`
  &.MuiAccordion-root {
    border-color: ${({ theme, error }) =>
      error ? theme.palette.error?.main : theme.palette?.grey?.['200']};
    background-color: ${({ theme }) => theme.palette?.common?.white};
    width: 100%;
    overflow: hidden !important;
    margin: 0;
    &.isDragging {
      border: none;
      box-shadow: 0 0 8px 2px ${({ theme }) => theme.palette?.grey?.['100']};
    }
  }
  &.MuiAccordion-rounded {
    border-radius: 16px;

    &::before {
      opacity: 0 !important;
    }
  }
`
