import { Text } from '@BarrelComponents'
import { useTranslationsContext } from '@Contexts/I18nContext/I18nContext'
import { DataTableProps } from '@Src/modules/customer/ContentList/ContentList.types'
import React, { useEffect, useMemo, useState } from 'react'
import { DeleteModalContentProps } from './DeleteModalContent.types'

export const DeleteModalContent = ({
  guid,
  content,
  variant = 'full'
}: DeleteModalContentProps) => {
  const [targetContent, setTargetContent] = useState<DataTableProps | undefined>(undefined)

  const {
    translations: { contentListPage, shared }
  } = useTranslationsContext()
  const {
    deleteLaunchModalWarningText,
    deleteLaunchModalWarningTextMinified,
    contentTitleTableHeadText,
    contentTitleTableHeadTextMinified
  } = contentListPage
  const {
    brandCarouselTypeText,
    guestHeroCarouselTypeText,
    heroCarouselTypeText,
    mobileBannersTypeText,
    streamlinedBannersTypeText,
    typeText,
    launchDateText
  } = shared

  const typesTranslations = useMemo(
    () => ({
      'brand carousel': brandCarouselTypeText,
      'guest hero carousel': guestHeroCarouselTypeText,
      'hero carousel': heroCarouselTypeText,
      'mobile banners': mobileBannersTypeText,
      'streamlined banners': streamlinedBannersTypeText
    }),
    [
      brandCarouselTypeText,
      guestHeroCarouselTypeText,
      heroCarouselTypeText,
      mobileBannersTypeText,
      streamlinedBannersTypeText
    ]
  )

  useEffect(() => {
    if (guid && content) {
      const targetContent = content.find((item) => item.guid === guid)
      setTargetContent(targetContent)
    }
  }, [content, guid])

  if (variant === 'minified') {
    return (
      <>
        {targetContent && (
          <>
            <Text>
              {contentTitleTableHeadTextMinified}: {targetContent?.contentTitle}
            </Text>
            <Text>
              {launchDateText}: {targetContent?.formattedLaunch}
            </Text>
          </>
        )}
        <br />
        <Text>{deleteLaunchModalWarningTextMinified}</Text>
      </>
    )
  }

  return (
    <>
      {targetContent && (
        <>
          <Text $capitalize>
            {contentTitleTableHeadText}: {targetContent?.contentTitle}
          </Text>
          <Text>
            {launchDateText}: {targetContent?.formattedLaunch}
          </Text>
          <Text $capitalize>
            {typeText}:{' '}
            {targetContent.type.includes('web view ')
              ? 'Mobile web view link'
              : typesTranslations?.[targetContent.type as keyof typeof typesTranslations]}
          </Text>
        </>
      )}
      <br />
      <Text>{deleteLaunchModalWarningText}</Text>
    </>
  )
}
