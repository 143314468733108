import { UseMutation } from '@Api/api.types'
import { useApplicationInfo } from '@Api/hooks/useApplicationInfo'
import { useBuildQueryKeys } from '@Api/hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '@Api/hooks/usePrepareHeaders'
import { OnChangeValue } from '@Src/components/organisms/RichTextEditor/RichTextEditor.types'
import { useApi } from '@Src/shared/api/hooks/useApi'
import type { AudienceObject } from '@dlb-shared-business-components/audience'
import { useMutation } from '@tanstack/react-query'

interface LongTipContent {
  quote: string
  description: string | OnChangeValue
  long_tip_image_path: string
}

export type ModuleData = LongTipContent

export interface LongTipChildModule {
  guid: string
  updated_by_user_email: string
  module_name: string
  schema_name: string
  zone: string
  module_data: Record<string, LongTipContent>
}

export interface LongTipPayloadModules {
  updated_by_user_email: string
  guid: string
  module_name: string
  schema_name: string
  launch_time: string
  zone: string
  page_sort: number | null
  is_default_display: boolean
  user_categories: string[]
  minegocio_groups: string[]
  user_tags: string[]
  authors: string[]
  selected_audience: AudienceObject[] | null
  module_data: Record<string, LongTipContent>
}

export interface UseAddLongTipMutationBody {
  is_active: boolean
  guid: string
  page_name: string
  page_template: string
  user_email: string
  launch_timezone: string | null
  launch_time: string | Date
  store_id: string
  modules: LongTipPayloadModules[]
}

export interface UseAddLongTipMutationFn {
  data: UseAddLongTipMutationBody
  pagePath: string
}

export interface UseAddLongTipMutationResponse {
  data: {
    message: string
    data: {
      guid: string
      name: string
    }
  }
}

export const useUploadLongTipMutation = ({
  mutationKeys,
  options
}: UseMutation<UseAddLongTipMutationResponse, Error, UseAddLongTipMutationFn>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()
  const { api } = useApi()

  const uploadLongTipKeys = useBuildQueryKeys(mutationKeys)

  return useMutation<UseAddLongTipMutationResponse, Error, UseAddLongTipMutationFn>(
    uploadLongTipKeys,
    {
      mutationFn: ({ pagePath, data }: UseAddLongTipMutationFn) => {
        return api({
          url: `${cmsCoreBackend}/contentedit/zone_${zone.toLowerCase()}/page/${pagePath}`,
          method: 'PUT',
          data,
          headers
        })
      },
      ...options
    }
  )
}
