import { Stack } from '@BarrelComponents'
import { StackProps } from '@Components/containers/Stack/Stack.types'
import { BasePropsStyles } from '@Styles/sharedStyles'
import pxToRem from '@Utils/pxToRem/pxToRem'
import React from 'react'
import styled, { css } from 'styled-components'

const StackWithHexaUIProp = (props: StackProps & { $hexaUI?: boolean }) => {
  return <Stack {...props} />
}

export const StyledDropzone = styled(StackWithHexaUIProp)`
  border-radius: ${pxToRem(4)};
  border: 1px solid ${({ theme }) => theme.palette.grey?.[200]};
  background-color: #e5e5e5;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;

  ${(props) => {
    return (
      props.$hexaUI &&
      css`
        border: 1px dashed #047af1;
        background-color: white;
        border-radius: ${pxToRem(16)};
      `
    )
  }}

  & > svg {
    height: ${pxToRem(72)};
    width: ${pxToRem(72)};
    fill: ${({ theme }) => theme.palette.grey?.[600]};
    margin-bottom: ${pxToRem(10)};
  }

  & > svg:last-child {
    fill: ${({ theme }) => theme.palette.grey?.[600]};
    margin-bottom: none;
  }

  p {
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(20)};
    color: ${({ theme }) => theme.palette.grey?.[600]};

    ${(props) => {
      return (
        props.$hexaUI &&
        css`
          color: black;
        `
      )
    }}
  }

  &.without-preview {
    border-color: ${({ theme }) => theme.palette.success?.light};
    background-color: ${({ theme }) => theme.palette.success?.[100]};

    & > svg {
      fill: ${({ theme }) => theme.palette.success?.light};
    }

    p {
      color: ${({ theme }) => theme.palette.success?.light};
    }
  }

  &.drop-rejected {
    border-color: ${({ theme }) => theme.palette.error?.light};
    background-color: ${({ theme }) => theme.palette.error?.[100]};

    & > svg {
      fill: ${({ theme }) => theme.palette.error?.light};
    }

    p {
      color: ${({ theme }) => theme.palette.error?.light};
    }
  }

  .edit-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
    background-color: ${({ theme }) => theme.palette.primary?.main};
    border-radius: 50%;
    color: ${({ theme }) => theme.palette.link?.light};
    pointer-events: none;

    svg {
      width: ${pxToRem(12)};
      height: ${pxToRem(12)};
    }

    ${(props) => {
      return (
        props.$hexaUI &&
        css`
          border: 1px solid black;
          background-color: white;
          color: black;
        `
      )
    }}
  }

  ${BasePropsStyles};
`
