import pxToRem from '@Utils/pxToRem/pxToRem'
import TableHead from '@mui/material/TableHead'
import styled from 'styled-components'
import { TableHeadProps } from './TableHead.types'

export const StyledTableHead = styled(TableHead)<TableHeadProps>`
  &&& {
    * {
      font-size: ${({ $fontSize }) => $fontSize};
    }

    th:first-child {
      padding-left: ${pxToRem(32)};
    }
  }
`

export const StyledToolTip = styled.div`
  margin-left: ${pxToRem(8)};
  width: ${pxToRem(24)};
  height: ${pxToRem(24)};

  & svg:first-child {
    fill: ${(props) => props.theme.palette.grey![200]};
  }
`
