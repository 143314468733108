import pxToRem from '@Utils/pxToRem/pxToRem'
import TableBody from '@mui/material/TableBody'
import styled from 'styled-components'
import { TableBodyProps } from './TableBody.types'

export const StyledTableBody = styled(TableBody)<Omit<TableBodyProps, 'data' | 'rowKey' | 'hover'>>`
  &&& {
    * {
      font-size: ${({ $fontSize }) => $fontSize};
    }

    td:first-child {
      padding-left: ${pxToRem(32)};
    }

    & tr button {
      opacity: 0;
      pointer-events: none;
      transition: 0.1s;
    }

    & tr:hover button {
      opacity: 1;
      pointer-events: all;
    }
  }
`
