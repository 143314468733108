import { colors, colorsByName } from './registerColors'
import { fontSizes } from './registerFontSizes'

const sizesLabels = fontSizes.reduce((acc, size) => {
  return {
    ...acc,
    [size]: size.replace('px', '')
  }
}, {})

const alignLabels = {
  '': 'Left alignment',
  right: 'Right alignment',
  center: 'Center alignment',
  justify: 'Justified'
}

const colorsLabels = Object.values(colorsByName).reduce((acc, colorName, index) => {
  return {
    ...acc,
    [colors[index]]: colorName
  }
}, {})

const labels = { ...sizesLabels, ...alignLabels, ...colorsLabels }

export const createOptions = (options: string[]) => {
  return options
    .map((option) => {
      const label = labels?.[option as keyof typeof labels] ?? option
      return `<option value="${option}">${label}</option>`
    })
    .join('\n')
}
