import Box from '@mui/material/Box'
import styled from 'styled-components'
import { DndRowElementProps } from './DndRow.types'

export const StyledBoxRow = styled(Box)<DndRowElementProps>`
  display: flex;
  align-items: center;
  border-radius: 16px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette?.grey?.[50]};
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  &.isActive {
    background-color: ${({ theme }) => theme.palette?.blue?.[50]};
    border-style: dashed;
    border-color: ${({ theme }) => theme.palette?.blue?.[600]};
  }
  & > .boxNumber {
    min-width: 32px;
    min-height: 66px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin: 0 0 0 24px;
    text-align: center;
    align-self: flex-start;
    display: flex;
    align-items: center;
  }
`
