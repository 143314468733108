import pxToRem from '@Utils/pxToRem/pxToRem'
import Chip from '@mui/material/Chip'
import styled, { css } from 'styled-components'
import { InteractiveChipProps } from './InteractiveChip.types'

const sizeStyles = {
  small: css``,
  medium: css`
    font-size: ${pxToRem(14)};
    font-weight: 700;
    padding-left: 16px;
    padding-right: 10px;
    padding-bottom: 2px;
  `
}

export const StyledInteractiveChip = styled(Chip)<InteractiveChipProps>`
  .MuiChip-deleteIcon {
    border-radius: 50%;
    padding: 2px;
    margin-right: 11px;
  }

  & button {
    width: ${pxToRem(22)};
    height: ${pxToRem(22)};

    svg {
      height: 0.7em;
    }
  }

  & .MuiChip-label {
    line-height: 1;
    ${({ size }) => size && sizeStyles[size]};
  }

  ${({ color }) => {
    if (color === 'error') {
      return css`
        background-color: #ffdede;
        color: black;
        .MuiChip-deleteIcon {
          background-color: transparent;
          color: black;

          &:hover,
          &:active {
            background-color: transparent;
            color: black;
          }
        }
      `
    }

    if (color === 'secondary') {
      return css`
        .MuiChip-deleteIcon {
          background-color: transparent;
          color: ${({ theme }) => theme.palette?.blue?.[500]};
        }
      `
    }

    return null
  }}
`
