import { Box } from '@BarrelComponents'
import React from 'react'
import { StyledTabPanel } from './TabPanel.styles'
import { TabPanelProps } from './TabPanel.types'

export const TabPanel = ({ children, value, index, boxSx, ...props }: TabPanelProps) => {
  return (
    <StyledTabPanel
      {...props}
      id={`tabpanel-${index}`}
      className={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      role="tabpanel"
      hidden={value !== index}
      index={index}
    >
      <Box sx={{ p: 2, display: value === index ? 'block' : 'none', ...boxSx }}>{children}</Box>
    </StyledTabPanel>
  )
}
