import b2bConfig from '@Src/configuration/b2bStoreConfiguration'
import { GetStoreDefaultVendorId } from './getStoreDefaultVendorId.types'

export const getStoreDefaultVendorId: GetStoreDefaultVendorId = ({ env, zone, storeId }) => {
  const envConfig = b2bConfig[env]

  if (!envConfig) {
    return null
  }

  const zoneConfig = envConfig[zone]
  if (!zoneConfig) {
    return null
  }

  const store = zoneConfig.stores.find((store) => store.id === storeId)

  if (!store?.defaultVendorId) {
    return null
  }

  return store.defaultVendorId
}
