import addContentV2 from './endpoints-v2/addContentV2/addContentV2'
import getContentListV2 from './endpoints-v2/getContentListV2/getContentListV2'
import getContentModulesV2 from './endpoints-v2/getContentModulesV2/getContentModulesV2'
import addGame from './endpoints/addGame/addGame'
import deleteContent from './endpoints/deleteContent/deleteContent'
import getContentTypes from './endpoints/getContentTypes/getContentTypes'
import getDDCList from './endpoints/getDDCList/getDDCList'
import getGame from './endpoints/getGame/getGame'
import getLocaleslist from './endpoints/getLocalesList/getLocalesList'
import getUsers from './endpoints/getUsers/getUsers'
import getVendors from './endpoints/getVendors/getVendors'
import imageUpload from './endpoints/imageUpload/imageUpload'
import { Builder, FinalMethods, Methods } from './getEndpointsDEPRECATED.types'

const getEndpoints = (builder: Builder) => {
  const enviroment =
    process.env.API_DEV_HOST && process.env.USE_MOCK_ENDPOINT === 'true' ? 'mock' : 'real'

  const methods: Methods = {
    deleteContent: deleteContent(builder),
    getContentTypes: getContentTypes(builder),
    getUsers: getUsers(builder),
    getLocales: getLocaleslist(builder),
    imageUpload: imageUpload(builder),
    getVendors: getVendors(builder),
    getDDCList: getDDCList(builder),
    getGame: getGame(builder),
    addGame: addGame(builder),

    // v2 endpoints
    addContentV2: addContentV2(builder),
    getContentModulesV2: getContentModulesV2(builder),
    getContentListV2: getContentListV2(builder)
  }

  const finalMethods = Object.entries(methods).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value[enviroment]
    }
  }, {}) as FinalMethods

  return finalMethods
}

export default getEndpoints
