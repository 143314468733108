import {
  CmsBaseUrl,
  EndpointFunction,
  GetDDCList
} from '@Utils/getEndpoints/getEndpointsDEPRECATED.types'

type GetDDCListParams = { zone: string } & CmsBaseUrl

const getDDCList: EndpointFunction = (builder) => ({
  mock: '',
  real: builder.query<GetDDCList, GetDDCListParams>({
    query: ({ cmsBaseUrl, zone }) => ({
      url: `${cmsBaseUrl}/contentedit/ddc/zone_${zone.toLowerCase()}`,
      method: 'GET'
    }),
    providesTags: ['DDCs']
  })
})

export default getDDCList
