import { UseMutation } from '@Api/api.types'
import { useApplicationInfo } from '@Api/hooks/useApplicationInfo'
import { useBuildQueryKeys } from '@Api/hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '@Api/hooks/usePrepareHeaders'
import type { AudienceObject } from '@dlb-shared-business-components/audience'
import { useApi } from '@Src/shared/api/hooks/useApi'
import { useMutation } from '@tanstack/react-query'

interface EventContent {
  title: string
  description: string
  start_date: string
  end_date: string
  custom_tags: string | string[]
  image_path: string
}

export interface EventPayloadModules {
  updated_by_user_email: string
  guid: string
  module_name: string
  schema_name: string
  launch_time: string
  zone: string
  page_sort: number | null
  ddc_ids: string[]
  module_data: Record<string, EventContent>
  selected_audience: AudienceObject[] | null
}

export interface UseAddEventMutationBody {
  guid: string
  page_name: string
  page_template: string
  user_email: string
  launch_time: string | Date
  launch_timezone: string | null
  store_id: string
  is_active: boolean
  modules: EventPayloadModules[]
}

export interface UseAddEventMutationFn {
  data: UseAddEventMutationBody
  pagePath: string
}

export interface UseAddEventMutationResponse {
  data: {
    message: string
    data: {
      guid: string
      name: string
    }
  }
}

export const useAddEventMutation = ({
  mutationKeys,
  options
}: UseMutation<UseAddEventMutationResponse, Error, UseAddEventMutationFn>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()
  const { api } = useApi()

  const eventKeys = useBuildQueryKeys(mutationKeys)

  return useMutation<UseAddEventMutationResponse, Error, UseAddEventMutationFn>(eventKeys, {
    mutationFn: ({ pagePath, data }: UseAddEventMutationFn) => {
      return api({
        url: `${cmsCoreBackend}/contentedit/zone_${zone.toLowerCase()}/page/${pagePath}`,
        method: 'PUT',
        data,
        headers
      })
    },
    ...options
  })
}
