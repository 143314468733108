import { Environment } from '@Src/types/environment'
import { useEnvService } from 'admin-portal-shared-services'
import { EnvFromWrapper } from './useGetEnv.types'

const envMapper = {
  QA: Environment.SIT,
  SIT: Environment.SIT,
  UAT: Environment.UAT,
  PROD: Environment.PRODUCTION
}

export const useGetEnv = (): Environment => {
  const envFromWrapper = useEnvService().getEnv() as EnvFromWrapper

  return envMapper[envFromWrapper] ?? Environment.SIT
}
