import { apiUtils } from '@Redux/slices/api/apiSlice'
import { changeStore } from '@Redux/slices/application/applicationSlice'
import globalVars from '@Utils/constants'
import { handleOnStoreChangeProps } from '../ContentList.types'

export const handleOnStoreChange = (props: handleOnStoreChangeProps) => {
  const { value, setSelectedStore, dispatch, locale, cluster, refetchContentList } = props
  setSelectedStore(value)
  dispatch(changeStore(value))

  const { localStorageStoreKey } = globalVars
  localStorage.setItem(
    `${localStorageStoreKey}:${locale}${cluster !== 'default' ? `:${cluster}` : ''}`,
    value
  )

  apiUtils.invalidateTags(['Content'])
  refetchContentList()
}
