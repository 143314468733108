import { MarginPropsStyles, PaddingPropsStyles } from '@Styles/sharedStyles'
import styled from 'styled-components'
import { ActionsContainerProps } from './ActionsContainer.types'

export const StyledActionsContainer = styled.div<ActionsContainerProps>`
  && {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;

    ${PaddingPropsStyles}
    ${MarginPropsStyles}

    button + button {
      margin-left: 16px;
    }
  }
`
