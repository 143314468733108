import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ContentEditorURLQueries } from '@Src/modules/customer/ContentEditorV2/ContentEditorV2.types'
import globalVars from '@Utils/constants'
import getLocalStorage from '@Utils/getLocalStorage/getLocalStorage'
import { ApplicationState } from './applicationSlice.types'

const {
  localStorageZoneKey,
  defaultZone,
  localStorageRawZoneKey,
  localStorageStoreKey,
  localStorageClusterKey
} = globalVars
const rawZone = getLocalStorage(localStorageRawZoneKey, '')?.toLowerCase()
const cluster = getLocalStorage(localStorageClusterKey, 'default')?.toLowerCase()

const initialState: ApplicationState = {
  zone: getLocalStorage(localStorageZoneKey, defaultZone)?.toLowerCase(),
  rawZone,
  storeId: getLocalStorage(
    `${localStorageStoreKey}:${rawZone}${cluster !== 'default' ? `:${cluster}` : ''}`,
    'Store'
  ),
  cluster,
  contentEditorURLQueries: {} as ContentEditorURLQueries
}

const { actions, reducer: applicationSliceReducer } = createSlice({
  name: 'application',
  initialState,
  reducers: {
    changeZone: (state, action: PayloadAction<string>) => {
      const [rawZone, zone, cluster] = action.payload.toLowerCase().split(':')
      state.zone = rawZone
      state.rawZone = zone
      state.cluster = cluster || 'default'
    },
    changeStore: (state, action: PayloadAction<string>) => {
      state.storeId = action.payload
    },
    changeContentEditorURLQueries: (state, action: PayloadAction<ContentEditorURLQueries>) => {
      state.contentEditorURLQueries = action.payload
    }
  }
})

export const { changeZone, changeStore, changeContentEditorURLQueries } = actions

export default applicationSliceReducer
