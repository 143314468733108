export const customIcons = {
  underline: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9996 16.2201C14.6896 16.2201 16.8896 14.0101 16.8996 11.3201V5.32007C16.8996 4.82007 16.4996 4.42007 15.9996 4.42007C15.4996 4.42007 15.0996 4.82007 15.0996 5.32007V11.3201C15.0996 13.0301 13.6996 14.4201 11.9996 14.4201C10.2896 14.4201 8.89961 13.0201 8.89961 11.3201V5.32007C8.89961 4.82007 8.49961 4.42007 7.99961 4.42007C7.49961 4.42007 7.09961 4.82007 7.09961 5.32007V11.3201C7.09961 14.0101 9.29961 16.2101 11.9996 16.2201Z"
        fill="#010101"
      />
      <path
        d="M17.9996 17.7802H5.99961C5.49961 17.7802 5.09961 18.1802 5.09961 18.6802C5.09961 19.1802 5.49961 19.5802 5.99961 19.5802H17.9996C18.4996 19.5802 18.8996 19.1802 18.8996 18.6802C18.8996 18.1802 18.4996 17.7802 17.9996 17.7802Z"
        fill="#010101"
      />
    </svg>
  `,
  list: {
    ordered: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.2904 16.7404C20.7904 16.7404 21.1904 17.1404 21.1904 17.6404C21.1904 18.1004 20.8404 18.4804 20.3904 18.5304H20.2904H8.59043C8.09043 18.5304 7.69043 18.1304 7.69043 17.6304C7.69043 17.1704 8.04043 16.7904 8.49043 16.7404H8.59043H20.2904Z"
            fill="#151515"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.1904 6.84057C21.1904 6.34057 20.7904 5.94057 20.2904 5.94057H8.49043C8.04043 6.00057 7.69043 6.38057 7.69043 6.84057C7.69043 7.34057 8.09043 7.74057 8.59043 7.74057H20.4004C20.8504 7.68057 21.2004 7.30057 21.2004 6.84057H21.1904Z"
            fill="#151515"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.1904 12.2405C21.1904 11.7405 20.7904 11.3405 20.2904 11.3405H8.49043C8.04043 11.4005 7.69043 11.7805 7.69043 12.2405C7.69043 12.7405 8.09043 13.1405 8.59043 13.1405H20.4004C20.8504 13.0805 21.2004 12.7005 21.2004 12.2405H21.1904Z"
            fill="#151515"
          />
          <path
            d="M3.33691 8.49785H5.49076"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.41425 9.03628C4.27144 9.03628 4.13448 8.97955 4.0335 8.87856C3.93252 8.77758 3.87579 8.64062 3.87579 8.49782V5.89705L3.57856 6.04567C3.45089 6.10964 3.30303 6.12028 3.16752 6.07524C3.032 6.03021 2.91993 5.93318 2.85595 5.80551C2.72241 5.53951 2.83118 5.45659 3.09718 5.32413L4.1741 4.78567C4.25615 4.74478 4.34728 4.72552 4.43886 4.72971C4.53044 4.7339 4.61943 4.7614 4.69741 4.80961C4.77539 4.85782 4.83976 4.92514 4.88444 5.00519C4.92912 5.08525 4.95262 5.17538 4.95271 5.26705V8.49782C4.95271 8.64062 4.89598 8.77758 4.795 8.87856C4.69402 8.97955 4.55706 9.03628 4.41425 9.03628Z"
            fill="black"
          />
          <path
            d="M5.49076 13.8826H3.33691C3.33691 13.6306 5.32922 12.7239 5.32922 11.4768C5.28375 11.2538 5.1659 11.0521 4.994 10.903C4.82209 10.7539 4.60574 10.6657 4.37857 10.6522C4.15141 10.6387 3.92614 10.7006 3.73777 10.8283C3.5494 10.956 3.40849 11.1423 3.33691 11.3583"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.33691 18.5628C3.40839 18.7789 3.54921 18.9652 3.7375 19.093C3.92579 19.2208 4.151 19.2828 4.37815 19.2694C4.60531 19.256 4.82168 19.168 4.99367 19.019C5.16565 18.87 5.28361 18.6684 5.32922 18.4455C5.34114 18.2561 5.28124 18.0693 5.16147 17.9222C5.0417 17.775 4.87087 17.6785 4.68306 17.6518C4.87198 17.628 5.04449 17.5324 5.1648 17.3848C5.28512 17.2372 5.34399 17.049 5.32922 16.8591C5.28361 16.6362 5.16565 16.4346 4.99367 16.2856C4.82168 16.1366 4.60531 16.0486 4.37815 16.0352C4.151 16.0218 3.92579 16.0838 3.7375 16.2116C3.54921 16.3394 3.40839 16.5257 3.33691 16.7418"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
      </svg>
    `,
    bullet: `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.809 6.52512C4.809 6.02807 4.40606 5.62512 3.909 5.62512L3.79504 5.63118C3.34744 5.68317 3 6.06357 3 6.52512C3 7.02218 3.40294 7.42512 3.909 7.42512L4.01396 7.41907C4.46156 7.36708 4.809 6.98667 4.809 6.52512ZM21 6.52512C21 6.02807 20.5971 5.62512 20.1 5.62512H8.4L8.29504 5.63118C7.84744 5.68317 7.5 6.06357 7.5 6.52512C7.5 7.02218 7.90294 7.42512 8.4 7.42512H20.1L20.205 7.41907C20.6526 7.36708 21 6.98667 21 6.52512ZM21 11.9251C21 11.4281 20.5971 11.0251 20.1 11.0251H8.4L8.29504 11.0312C7.84744 11.0832 7.5 11.4636 7.5 11.9251C7.5 12.4222 7.90294 12.8251 8.4 12.8251H20.1L20.205 12.8191C20.6526 12.7671 21 12.3867 21 11.9251ZM20.1 16.4251C20.5971 16.4251 21 16.8281 21 17.3251C21 17.7867 20.6526 18.1671 20.205 18.2191L20.1 18.2251H8.4C7.90294 18.2251 7.5 17.8222 7.5 17.3251C7.5 16.8636 7.84744 16.4832 8.29504 16.4312L8.4 16.4251H20.1ZM3.909 11.0251C4.40606 11.0251 4.809 11.4281 4.809 11.9251C4.809 12.3867 4.46156 12.7671 4.01396 12.8191L3.909 12.8251C3.40294 12.8251 3 12.4222 3 11.9251C3 11.4636 3.34744 11.0832 3.79504 11.0312L3.909 11.0251ZM4.809 17.3251C4.809 16.8281 4.40606 16.4251 3.909 16.4251L3.79504 16.4312C3.34744 16.4832 3 16.8636 3 17.3251C3 17.8222 3.40294 18.2251 3.909 18.2251L4.01396 18.2191C4.46156 18.1671 4.809 17.7867 4.809 17.3251Z"
          fill="#141414"
        />
      </svg>
    `
  },
  link: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5558 4.44559C17.6522 2.54194 14.5741 2.5152 12.6377 4.38547L11.2145 5.8003L11.1458 5.87759C10.894 6.19953 10.9154 6.66615 11.2111 6.96352C11.5313 7.28567 12.0521 7.28718 12.3743 6.96691L13.789 5.5604L13.9244 5.43676C15.2121 4.3246 17.1673 4.38347 18.3926 5.6088C19.6617 6.8779 19.6795 8.92994 18.4327 10.2209L15.9751 12.6783L15.8368 12.8089C15.1754 13.3981 14.3018 13.6977 13.4132 13.634C12.461 13.5658 11.5854 13.0875 11.0136 12.323C10.7415 11.9593 10.226 11.885 9.86229 12.1571C9.49853 12.4292 9.42422 12.9446 9.69632 13.3084C10.554 14.455 11.8674 15.1726 13.2956 15.2749C14.7239 15.3772 16.1261 14.8541 17.1385 13.8414L19.6059 11.3739L19.7504 11.2179C21.4835 9.2661 21.4094 6.29913 19.5558 4.44559ZM10.7044 8.71152L10.4791 8.70055C9.12958 8.66565 7.82057 9.18561 6.86153 10.145L4.39407 12.6125L4.24959 12.7684C2.51649 14.7203 2.59062 17.6872 4.44417 19.5408C6.34781 21.4444 9.42587 21.4712 11.3623 19.6009L12.779 18.1844L12.8474 18.1069C13.0983 17.7842 13.0755 17.3177 12.779 17.0212C12.4578 16.7 11.937 16.7 11.6158 17.0212L10.2093 18.4277L10.0741 18.5511C8.78794 19.6618 6.83272 19.6029 5.60739 18.3776C4.33829 17.1085 4.32046 15.0564 5.56731 13.7655L8.02485 11.3081L8.16324 11.1775C8.82456 10.5882 9.69816 10.2887 10.5868 10.3524C11.539 10.4205 12.4146 10.8989 12.9864 11.6633C13.2585 12.0271 13.774 12.1014 14.1377 11.8293C14.5015 11.5572 14.5758 11.0418 14.3037 10.678C13.446 9.53138 12.1326 8.81381 10.7044 8.71152Z"
        fill="#141414"
      />
    </svg>
  `,
  italic: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1034_1214)">
        <path
          d="M16.9996 4.85012H9.49961C8.99961 4.85012 8.59961 5.25012 8.59961 5.75012C8.59961 6.25012 8.99961 6.65012 9.49961 6.65012H12.1496L10.0096 17.3501H6.99961C6.49961 17.3501 6.09961 17.7501 6.09961 18.2501C6.09961 18.7501 6.49961 19.1501 6.99961 19.1501H10.7496H14.4996C14.9996 19.1501 15.3996 18.7501 15.3996 18.2501C15.3996 17.7501 14.9996 17.3501 14.4996 17.3501H11.8496L13.9896 6.65012H16.9996C17.4996 6.65012 17.8996 6.25012 17.8996 5.75012C17.8996 5.25012 17.4996 4.85012 16.9996 4.85012Z"
          fill="#231F20"
        />
      </g>
      <defs>
        <clipPath id="clip0_1034_1214">
          <rect width="11.8" height="14.3" fill="white" transform="translate(6.09961 4.85012)" />
        </clipPath>
      </defs>
    </svg>
  `,
  bold: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4096 11.5451C15.9396 10.9051 16.2796 10.0851 16.2796 9.18512C16.2796 7.13512 14.6096 5.47512 12.5696 5.47512H6.59961V18.5251H13.6896C15.7396 18.5251 17.3996 16.8551 17.3996 14.8151C17.3996 13.3951 16.5896 12.1651 15.4096 11.5451ZM8.39961 7.27512H12.5596C13.6096 7.27512 14.4696 8.13512 14.4696 9.18512C14.4696 10.2351 13.6096 11.0951 12.5596 11.0951H8.39961V7.27512ZM13.6896 16.7251H8.39961V12.8951H13.6896C14.7396 12.8951 15.5996 13.7551 15.5996 14.8051C15.5996 15.8551 14.7396 16.7151 13.6896 16.7151V16.7251Z"
        fill="#010101"
      />
    </svg>
  `,
  blockquote: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1217_26888)">
        <path d="M16.2373 10.0051H7.9873" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19.7923 6.00012H7.9873" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19.7923 13.9951H7.9873" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.20752 6.00012V18.0001" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.2373 18.0001H7.9873" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1217_26888">
          <rect width="17.835" height="14.25" fill="white" transform="translate(3.08252 4.87512)"/>
        </clipPath>
      </defs>
    </svg>
  `,
  strike: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0081 5.20013C13.1681 5.20013 14.1681 5.44013 15.0081 5.91013C15.6181 6.25013 16.1481 6.71013 16.6181 7.29013C16.9081 7.66013 16.8481 8.20013 16.4781 8.49013C16.1081 8.78013 15.5781 8.70013 15.2981 8.33013C14.9281 7.84013 14.5281 7.48013 14.0981 7.23013C13.4781 6.88013 12.7281 6.70013 11.8481 6.70013C11.1381 6.70013 10.5581 6.80013 10.0981 7.00013C9.6381 7.20013 9.2981 7.46013 9.0881 7.79013C8.8781 8.12013 8.7681 8.49013 8.7681 8.90013C8.7681 9.37013 8.9181 9.78013 9.2081 10.1301C9.4981 10.4801 10.0881 10.7601 10.9681 10.9601L13.8281 11.6001C15.0981 11.8801 15.9781 12.3001 16.4781 12.8701C16.9781 13.4401 17.2281 14.1401 17.2281 14.9801C17.2281 15.7301 17.0181 16.3901 16.5981 16.9701C16.1781 17.5501 15.5881 18.0001 14.8281 18.3201C14.0681 18.6401 13.1781 18.8001 12.1481 18.8001C11.1881 18.8001 10.3481 18.6701 9.6181 18.4101C8.8881 18.1501 8.2681 17.8001 7.7481 17.3601C7.4581 17.1201 7.1981 16.8601 6.9681 16.5801C6.6681 16.2201 6.7181 15.6801 7.0781 15.3801L7.1281 15.3401C7.4981 15.0401 8.0381 15.1201 8.3281 15.5001C8.4881 15.7101 8.6581 15.9001 8.8381 16.0801C9.2381 16.4701 9.7181 16.7701 10.2881 16.9801C10.8581 17.1901 11.5081 17.3001 12.2381 17.3001C12.9181 17.3001 13.5081 17.2201 14.0081 17.0501C14.5081 16.8801 14.8881 16.6401 15.1581 16.3101C15.4281 15.9801 15.5581 15.5901 15.5581 15.1201C15.5581 14.6901 15.4181 14.3101 15.1381 13.9701C14.8581 13.6301 14.3281 13.3701 13.5581 13.2001L10.4581 12.5001C9.6481 12.3101 8.9881 12.0601 8.4881 11.7301C7.9881 11.4001 7.6281 11.0201 7.4081 10.5701C7.1881 10.1201 7.0781 9.64013 7.0781 9.12013C7.0781 8.41013 7.2781 7.76013 7.6681 7.17013C8.0581 6.58013 8.6281 6.10013 9.3681 5.74013C10.1081 5.38013 10.9881 5.20013 11.9981 5.20013L12.0081 5.20013Z" fill="black"/>
      <path d="M18.0001 11.2001H6.0001C5.5001 11.2001 5.1001 11.6001 5.1001 12.1001C5.1001 12.6001 5.5001 13.0001 6.0001 13.0001H18.0001C18.5001 13.0001 18.9001 12.6001 18.9001 12.1001C18.9001 11.6001 18.5001 11.2001 18.0001 11.2001Z" fill="#010101"/>
    </svg>
  `,
  color: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1587_2162)">
        <path d="M4.37982 11.9551C4.27982 11.9551 4.16982 11.9351 4.06982 11.8951C3.63982 11.7251 3.41982 11.2351 3.58982 10.7951L7.07982 1.96513C7.45982 1.17513 8.50982 1.23513 8.83982 1.90513L12.3498 10.8051C12.5198 11.2351 12.3098 11.7351 11.8698 11.9051C11.4398 12.0851 10.9398 11.8651 10.7698 11.4251L7.95982 4.30513L5.14982 11.4251C5.01982 11.7551 4.69982 11.9651 4.35982 11.9651L4.37982 11.9551Z" fill="#141414"/>
        <path d="M10.6497 9.61513H5.29971C4.82971 9.61513 4.44971 9.23513 4.44971 8.76513C4.44971 8.29513 4.82971 7.91513 5.29971 7.91513H10.6497C11.1197 7.91513 11.4997 8.29513 11.4997 8.76513C11.4997 9.23513 11.1197 9.61513 10.6497 9.61513Z" fill="#141414"/>
        <path d="M13.2499 14.6151H2.7499C2.2799 14.6151 1.8999 14.2351 1.8999 13.7651C1.8999 13.2951 2.2799 12.9151 2.7499 12.9151H13.2499C13.7199 12.9151 14.0999 13.2951 14.0999 13.7651C14.0999 14.2351 13.7199 14.6151 13.2499 14.6151Z" fill="#141414"/>
      </g>
      <defs>
        <clipPath id="clip0_1587_2162">
          <rect width="12.2" height="13.23" fill="white" transform="translate(1.8999 1.38513)"/>
        </clipPath>
      </defs>
    </svg>
  `,
  background: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.00012207" width="16" height="16" rx="4" fill="#F2F2F2"/>
      <path d="M4.40961 13.2774C4.30961 13.2774 4.19961 13.2574 4.09961 13.2174C3.66961 13.0474 3.44961 12.5574 3.61961 12.1174L7.10961 3.2874C7.48961 2.4974 8.53961 2.5574 8.86961 3.2274L12.3796 12.1274C12.5496 12.5574 12.3396 13.0574 11.8996 13.2274C11.4696 13.4074 10.9696 13.1874 10.7996 12.7474L7.98961 5.6274L5.17961 12.7474C5.04961 13.0774 4.72961 13.2874 4.38961 13.2874L4.40961 13.2774Z" fill="#141414"/>
      <path d="M10.6795 10.9374H5.32949C4.85949 10.9374 4.47949 10.5574 4.47949 10.0874C4.47949 9.6174 4.85949 9.2374 5.32949 9.2374H10.6795C11.1495 9.2374 11.5295 9.6174 11.5295 10.0874C11.5295 10.5574 11.1495 10.9374 10.6795 10.9374Z" fill="#141414"/>
    </svg>
  `,
  clean: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1217_26896)">
        <path d="M15.0201 13.5001L13.6601 12.1901L14.9701 10.8301C15.3501 10.4301 15.3401 9.80015 14.9401 9.42015C14.5401 9.04015 13.9101 9.05015 13.5301 9.45015L12.2201 10.8101L10.8601 9.50015C10.4601 9.12015 9.83006 9.13015 9.45006 9.53015C9.07006 9.93015 9.08006 10.5601 9.48006 10.9401L10.8401 12.2501L9.53006 13.6101C9.15006 14.0101 9.16006 14.6401 9.56006 15.0201C9.75006 15.2101 10.0001 15.3001 10.2501 15.3001C10.5001 15.3001 10.7701 15.2001 10.9701 14.9901L12.2801 13.6301L13.6401 14.9401C13.8301 15.1301 14.0801 15.2201 14.3301 15.2201C14.5901 15.2201 14.8501 15.1201 15.0501 14.9101C15.4301 14.5101 15.4201 13.8801 15.0201 13.5001Z" fill="#141414"/>
        <path d="M3.62012 2.70013H7.14012L3.69012 11.3301C3.48012 11.8401 3.73012 12.4201 4.25012 12.6301C4.37012 12.6801 4.50012 12.7001 4.62012 12.7001C5.02012 12.7001 5.39012 12.4601 5.55012 12.0701L9.30012 2.70013H12.6101C13.1601 2.70013 13.6101 2.25013 13.6101 1.70013C13.6101 1.15013 13.1601 0.700134 12.6101 0.700134H3.62012C3.07012 0.700134 2.62012 1.15013 2.62012 1.70013C2.62012 2.25013 3.07012 2.70013 3.62012 2.70013Z" fill="#141414"/>
        <path d="M7.58992 13.2001H1.16992C0.889922 13.2001 0.669922 13.4201 0.669922 13.7001C0.669922 13.9801 0.889922 14.2001 1.16992 14.2001H7.58992C7.86992 14.2001 8.08992 13.9801 8.08992 13.7001C8.08992 13.4201 7.86992 13.2001 7.58992 13.2001Z" fill="#141414"/>
      </g>
      <defs>
        <clipPath id="clip0_1217_26896">
          <rect width="14.66" height="14.6" fill="white" transform="translate(0.669922 0.700134)"/>
        </clipPath>
      </defs>
    </svg>

  `
}
