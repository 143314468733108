import { UseQuery, Zone } from '@Api/api.types'
import { useQuery } from '@tanstack/react-query'
import { useApi } from '../hooks/useApi'
import { useApplicationInfo } from '../hooks/useApplicationInfo'
import { useBuildQueryKeys } from '../hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'

export interface GetZoneBody {
  zonesArray: Pick<Zone, 'id' | 'key'>[]
  pagePathArray: string[]
}

export const useZonesQuery = ({ queryKeys, options }: UseQuery<GetZoneBody, Error>) => {
  const { headers } = usePrepareHeaders()
  const { cmsCoreBackend, zone: zoneKey } = useApplicationInfo()
  const { api } = useApi()

  const itemsListQueryKeys = useBuildQueryKeys(queryKeys)

  return useQuery<GetZoneBody, Error>(
    itemsListQueryKeys,
    async () => {
      const { data: zone } = await api.get(
        `${cmsCoreBackend}/contentedit/client/zones/zone_${zoneKey}`,
        {
          headers
        }
      )

      return zone
    },
    options
  )
}
