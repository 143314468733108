import {
  EndpointFunction,
  ImageUploadResponse
} from '@Utils/getEndpoints/getEndpointsDEPRECATED.types'

type ImageUpload = { file: File; imageUploadBaseUrl: string }

const imageUpload: EndpointFunction = (builder) => ({
  mock: '',
  real: builder.mutation<ImageUploadResponse, ImageUpload>({
    query: ({ imageUploadBaseUrl, file }) => {
      const formData = new FormData()
      formData.append('File', file)

      return {
        url: imageUploadBaseUrl,
        method: 'POST',
        body: formData
      }
    }
  })
})

export default imageUpload
