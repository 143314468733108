import { colors } from '@Styles/theme/colors'
import pxToRem from '@Utils/pxToRem/pxToRem'
import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    padding: 8px 16px;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(20)};
  }

  .Toastify__toast-icon {
    width: 16px;
  }
  .Toastify__close-button {
    align-self: auto;
  }

  .Toastify__toast-body {
    padding: 6px 16px 6px 0px;
  }

  .Toastify__toast-theme--colored.Toastify__toast--success {
    background: ${colors.green[300]};
  }

  .Toastify__toast-theme--colored.Toastify__toast--error {
    background: ${colors.red[300]};
  }
`
