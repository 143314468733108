import pxToRem from '@Utils/pxToRem/pxToRem'
import Paper from '@mui/material/Paper'
import styled from 'styled-components'
import { ActionCardElement, DivElement } from './ActionCard.types'

export const TitleWrapper = styled.div<DivElement>``
export const ActionButtonWrapper = styled.div<DivElement>`
  margin-left: ${pxToRem(36)};
`
export const ActionCardHeader = styled.div<DivElement>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  min-height: 38px;
`
export const ActionCardBody = styled.div<DivElement>`
  position: relative;
`
export const StyledActionCard = styled(Paper)<ActionCardElement>`
  padding: 32px;
  width: 100%;
`
