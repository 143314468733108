import Quill from 'quill'

const Link = Quill.import('formats/link')

class CustomLink extends Link {
  static create(href: string) {
    const anchorTag = document.createElement('a')
    anchorTag.setAttribute('href', href)

    if (href.startsWith('#')) return anchorTag
    anchorTag.setAttribute('target', '_blank')

    return anchorTag
  }
}

export const registerCustomLink = () => {
  Quill.register(CustomLink, true)
}
