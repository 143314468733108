import { TextChangeHandler } from 'quill'
import { useEffect } from 'react'
import { UseOnTextChange } from '../RichTextEditor.types'
import { hexToRGB } from '../utils/registerColors'

const parseDelta = (delta: Parameters<TextChangeHandler>[0]) => {
  const actions: Record<string, (...params: any) => any> = {
    color: (value: string) => (value.startsWith('#') ? hexToRGB(value) : value),
    background: (value: string) => (value.startsWith('#') ? hexToRGB(value) : value)
  }

  const parsedDeltaOps = delta.ops.map(({ attributes, ...rest }) => {
    if (!attributes) return rest

    const attributesEntries = Object.entries(attributes).map(([key, value]) => {
      return [key, actions?.[key]?.(value) ?? value]
    })

    return { attributes: Object.fromEntries(attributesEntries), ...rest }
  })

  return { ops: parsedDeltaOps }
}

export const useOnTextChange = ({ quillInstance, onChange }: UseOnTextChange) => {
  useEffect(() => {
    if (!quillInstance) return

    const onTextChange: TextChangeHandler = () => {
      const contents = quillInstance.getContents()
      onChange({ value: parseDelta(contents), html: quillInstance.root.innerHTML })
    }

    quillInstance.on('text-change', onTextChange)

    return () => {
      quillInstance.off('text-change', onTextChange)
    }
  }, [onChange, quillInstance])
}
