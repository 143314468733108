import { css } from 'styled-components'

export const SizingPropsStyles = css<SizingProps>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
`

export const MarginPropsStyles = css<MarginProps>`
  margin: ${({ $margin }) => $margin};
  margin-top: ${({ $marginTop }) => $marginTop};
  margin-left: ${({ $marginLeft }) => $marginLeft};
  margin-right: ${({ $marginRight }) => $marginRight};
  margin-bottom: ${({ $marginBottom }) => $marginBottom};
`

export const PaddingPropsStyles = css<PaddingProps>`
  padding: ${({ $padding }) => $padding};
  padding-top: ${({ $paddingTop }) => $paddingTop};
  padding-left: ${({ $paddingLeft }) => $paddingLeft};
  padding-right: ${({ $paddingRight }) => $paddingRight};
  padding-bottom: ${({ $paddingBottom }) => $paddingBottom};
`

export const BasePropsStyles = css<BaseProps<typeof css>>`
  ${SizingPropsStyles};
  ${MarginPropsStyles};
  ${PaddingPropsStyles};

  ${({ $additionalCss }) => $additionalCss}
  font-size: ${({ $fontSize }) => $fontSize};
`
