import { Tab } from '@Components/molecules/Tabs/Tabs.types'
import { TranslationsObjectContext } from '@Src/shared/contexts/I18nContext/I18nContext.types'

export const getTableFilterTabs = (
  contentListPageTranslations: TranslationsObjectContext['contentListPage'],
  sharedTranslations: TranslationsObjectContext['shared']
): Tab[] => {
  const { statusTabText, editedByTabText } = contentListPageTranslations
  const { typeText, launchDateText } = sharedTranslations
  return [
    {
      value: 'content-type',
      label: typeText
    },
    {
      value: 'status',
      label: statusTabText
    },
    {
      value: 'edited-by',
      label: editedByTabText
    },
    {
      value: 'launch-date',
      label: launchDateText
    }
  ]
}
