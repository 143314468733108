import { CustomPaletteOptions } from './types'

export const colors = {
  white: '#FFFFFF',
  black: {
    700: '#414141',
    900: '#000000'
  },
  blue: {
    600: '#047AF1',
    550: '#0c69c7',
    500: '#4D6EDB',
    50: '#E9F1FF'
  },
  grey: {
    600: '#707372',
    400: '#ACA9A9',
    300: '#DFDEDE',
    200: '#B9BCC1',
    170: '#DBDADA',
    160: '#e6e6e6',
    150: '#FAFAFA',
    100: '#F3F3F3',
    60: '#F5F5F5',
    50: '#FAFAFA'
  },
  green: {
    300: '#2BAB3C',
    600: '#19A454',
    700: '#006400',
    100: '#ddfcdd'
  },
  red: {
    500: '#C9201D',
    300: '#E02B27',
    200: '#FFDEDE',
    100: '#feebea'
  },
  purple: {
    100: '#F1EDFC',
    200: '#F0ECFC'
  },
  yellow: {
    900: '#FF9D15'
  }
}

export const palette: CustomPaletteOptions = {
  primary: {
    main: colors.black[900],
    light: colors.grey[300]
  },
  secondary: {
    main: colors.blue[50]
  },
  error: {
    main: colors.red[500],
    100: colors.red[100]
  },
  success: {
    main: colors.green[300],
    dark: colors.green[600],
    100: colors.green[100]
  },
  // warning: {},
  // info: {},
  blue: {
    ...colors.blue
  },
  black: {
    ...colors.black
  },
  yellow: {
    ...colors.yellow
  },
  grey: {
    ...colors.grey
  },
  purple: {
    ...colors.purple
  },
  text: {
    primary: colors.black[900],
    secondary: colors.grey[600],
    disabled: colors.grey[600]
  },
  link: {
    main: colors.black[900],
    dark: colors.blue[500],
    light: colors.white
  }
}
