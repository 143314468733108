import { LinkBehavior } from '@Utils/LinkBehavior/LinkBehavior'
import { LinkProps } from '@mui/material/Link'
import { createTheme } from '@mui/material/styles'
import { breakpoints } from './breakpoints'
import { palette } from './colors'
import { CustomThemeOptions } from './types'
import { typography } from './typography'

export const customTheme: CustomThemeOptions = createTheme({
  breakpoints,
  palette,
  typography,
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      } as LinkProps
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    }
  }
})
