import { BasePropsStyles } from '@Styles/sharedStyles'
import pxToRem from '@Utils/pxToRem/pxToRem'
import Box from '@mui/material/Box'
import styled, { css } from 'styled-components'
import { InputBoxProps } from './InputBox.types'

const styles = css`
  position: absolute;
  font-size: ${pxToRem(14)};
`

export const StyledBox = styled(Box)<InputBoxProps>`
  ${BasePropsStyles};
  position: relative;

  ${(props) => {
    return (
      props.type === 'input' &&
      css`
        & > div > div + div:not(:last-child) {
          ${styles};
          bottom: -21px;
        }
      `
    )
  }}

  ${(props) => {
    return (
      props.type === 'select' &&
      css`
        & > div {
          ${styles};
          bottom: -22px;
        }

        & > button {
          margin-top: 1px;
        }
      `
    )
  }}
`
