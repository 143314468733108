/* eslint-disable */
// @ts-nocheck
import { DocumentCookie, DocumentCookieService } from './documentCookieService'

class DocumentCookieServiceImp implements DocumentCookieService {
  public setCookie({ name, value, attributes }: DocumentCookie) {
    let newAttributes = ''
    for (let attributeName in attributes) {
      if (!attributes[attributeName]) continue

      newAttributes += ';' + attributeName

      if (attributes[attributeName] === true) continue

      if (attributeName === 'expires' && attributes['expires']) {
        newAttributes += '=' + attributes.expires.toUTCString()
        continue
      }

      newAttributes += '=' + attributes[attributeName]
    }
    document.cookie = `${name}=${value}` + newAttributes
  }
}

export default DocumentCookieServiceImp
