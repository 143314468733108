import pxToRem from '@Utils/pxToRem/pxToRem'
import { CustomTypographyOptions } from './types'

export const typography: CustomTypographyOptions = {
  fontFamily: 'Work Sans, sans-serif',
  fontWeightBold: 700,
  fontWeightSemiBold: 600,
  fontWeightMedium: 500,
  fontWeightRegular: 400,
  fontWeightLight: 300,
  lineHeightXs: 1,
  lineHeightSm: 1,
  lineHeightMd: pxToRem(20),
  lineHeightXl: 1,
  lineHeightXxl: 1
}
