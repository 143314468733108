import { useTranslationsContext } from '@Contexts/I18nContext/I18nContext'
import { useGetApiHost } from '@Hooks/useGetApiHost/useGetApiHost'
import {
  apiUtils,
  useDeleteContentMutation,
  useGetContentListV2Query
} from '@Redux/slices/api/apiSlice'
import { toast } from '@Src/App'
import { useAppDispatch, useAppSelector } from '@Src/shared/redux/hooks/reduxHooks'
import { Zones } from '@content-shared-components/image-uploader'
import { UseContentProps } from '../AppHeader.types'

export const useContent = ({ targetLaunch, onFinish }: UseContentProps) => {
  const { zone, userEmail, rawZone, storeId } = useAppSelector(({ application, user }) => ({
    zone: application.zone,
    rawZone: application.rawZone,
    storeId: application.storeId,
    userEmail: String(user.email)
  }))
  const dispatch = useAppDispatch()

  const { cmsCoreBackend: cmsBaseUrl } = useGetApiHost({
    zone: rawZone as Zones
  })

  const { data: contentList, isLoading } = useGetContentListV2Query({
    cmsBaseUrl,
    zone: rawZone,
    storeId: String(storeId)
  })
  const content = contentList?.find(({ guid }: any) => guid === targetLaunch)
  const [deleteContent] = useDeleteContentMutation()

  const {
    translations: { contentListPage, shared }
  } = useTranslationsContext()
  const {
    deleteLaunchMessageNotificationText,
    deleteLaunchNotFoundErrorNotificationText,
    deleteLiveLaunchErrorNotificationText
  } = contentListPage
  const { defaultErrorNotificationText } = shared

  const onDelete = async () => {
    try {
      dispatch(apiUtils.invalidateTags(['Content']))

      if (!content) throw new Error(deleteLaunchNotFoundErrorNotificationText)
      const { status } = content

      if (status.toLowerCase() === 'live') throw new Error(deleteLiveLaunchErrorNotificationText)

      const response = await deleteContent({ cmsBaseUrl, guid: targetLaunch, zone, userEmail })

      if (!response?.data) {
        throw new Error(defaultErrorNotificationText)
      }

      toast.success(deleteLaunchMessageNotificationText)
    } catch (error) {
      const { message } = error as Error

      toast.error(String(message))
    } finally {
      onFinish?.()
    }
  }

  return { content, onDelete, isLoading }
}
