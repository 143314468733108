import pxToRem from '@Utils/pxToRem/pxToRem'
import styled, { css } from 'styled-components'
import { StyledRichHTMLWrapperProps } from './RichTextEditor.types'
import chevronDownIcon from './assets/chevron-down.svg'
import linkIcon from './assets/linked.svg'

export const StyledWrapper = styled.div<{ maxWidth?: string }>`
  position: relative;
  width: 100%;
  max-width: ${(props) => props?.maxWidth ?? pxToRem(620)};
`

export const StyledRichHTMLLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${pxToRem(4)};
  margin-bottom: ${pxToRem(8)};
`

export const StyledRichHTMLWrapper = styled.div<StyledRichHTMLWrapperProps>`
  &&& {
    max-width: ${(props) => props?.maxWidth ?? pxToRem(620)};
    width: 100%;

    .ql-toolbar {
      border-radius: ${pxToRem(8)} ${pxToRem(8)} 0 0;
      display: flex;
      flex-wrap: wrap;
      row-gap: ${pxToRem(10)};

      .ql-formats {
        display: flex;
        align-items: center;
        height: ${pxToRem(32)};

        .ql-icon-picker .ql-picker-item {
          height: auto;
          width: auto;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        & :is(.ql-font, .ql-size, .ql-align, .ql-color-picker) .ql-picker-options {
          padding: 0;

          .ql-picker-item {
            font-size: ${pxToRem(16)};
            padding: ${`${pxToRem(20)} ${pxToRem(16)}`};
            transition: background-color 0.5ms ease-in;

            &:hover,
            &.ql-selected {
              background-color: #f2f2f2 !important;
              color: #000000;
            }
          }
        }

        & :is(button svg) {
          width: ${pxToRem(24)};
          height: ${pxToRem(24)};
          border-radius: ${pxToRem(4)};
          padding: ${pxToRem(2.5)};
          transition: background-color 0.5ms ease-in;
        }

        & button:hover svg {
          background-color: #ffff00;
        }

        & button.ql-active svg {
          background-color: #dbdada;
        }

        & :is(button:hover, button.ql-active) .ql-stroke {
          stroke: #000000;
        }

        .ql-picker {
          display: flex;
          height: ${pxToRem(32)};
          align-items: center;

          &:not(.ql-icon-picker, .ql-color-picker) .ql-picker-label svg {
            display: none;
          }

          &.ql-icon-picker,
          &.ql-color-picker {
            width: ${pxToRem(64)};

            svg {
              width: ${pxToRem(16)};
              height: ${pxToRem(16)};
            }
          }

          &.ql-color-picker {
            & .ql-picker-item:first-child {
              display: none !important;
            }

            &.ql-expanded .ql-picker-options {
              display: flex;
            }

            .ql-picker-options {
              flex-direction: column;
              top: 100%;
              overflow-x: hidden;
              padding: 0;

              .ql-picker-item {
                width: 100%;
                height: ${pxToRem(62)};
                margin: 0;
                background: none !important;
                display: flex;
                align-items: center;
                gap: ${pxToRem(8)};
                color: #000000;
                border: 1px solid transparent !important;

                & > * {
                  flex-shrink: 0;
                }

                &:is(:hover, .ql-selected) {
                  background-color: #f2f2f2 !important;
                }

                & .ql-color-preview {
                  display: inline-block;
                  width: ${pxToRem(16)};
                  height: ${pxToRem(16)};
                  border-radius: 50%;
                }

                &::after {
                  content: attr(data-label);
                  text-transform: capitalize;
                }
              }
            }
          }

          &.ql-align {
            & .ql-picker-item:first-child {
              display: none !important;
            }

            .ql-picker-options {
              .ql-picker-item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: ${pxToRem(8)};

                &::after {
                  content: attr(data-label);
                }
              }
            }
          }

          &::after {
            content: url(${chevronDownIcon});
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            inset: 7px 3px auto auto;
            border-radius: ${pxToRem(8)};
            background: #ffffff;
            pointer-events: none;
            z-index: 10;
          }

          &,
          .ql-picker-options {
            border-radius: ${pxToRem(8)};
            border: 1px solid rgba(0, 0, 0, 0.16);
          }

          .ql-picker-options {
            margin-top: ${pxToRem(5)};
            overflow-y: auto;
            max-height: ${pxToRem(300)};
            width: max-content;

            &::-webkit-scrollbar {
              width: 3px;
            }

            &::-webkit-scrollbar-track {
              background: #eeeeee;
            }

            &::-webkit-scrollbar-thumb {
              background: #dbdada;
            }
          }

          &:not(:last-of-type) {
            margin-right: ${pxToRem(8)};
          }

          .ql-picker-label {
            display: flex;
            align-items: center;
            border-radius: ${pxToRem(8)};
          }
        }
      }
    }

    .ql-container {
      border-radius: 0 0 ${pxToRem(8)} ${pxToRem(8)};
      height: auto;

      & :is(p, span, li, a, .ql-blank),
      & .ql-blank::before {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-size: ${pxToRem(16)};
      }

      & .ql-editor {
        height: ${(props) => props?.height ?? pxToRem(163)};
        resize: ${(props) => (props?.resizable ? 'vertical' : 'none')};

        & ::selection {
          background: #b2dafc;
        }

        a {
          text-decoration: none;
        }
      }

      .ql-tooltip {
        padding: ${`${pxToRem(8)} ${pxToRem(16)}`};
        z-index: 10;

        &::before {
          content: url(${linkIcon});
          display: inline-block;
          width: max-content;
          height: max-content;
          position: absolute;
          top: 9px;
        }

        &,
        input {
          border-radius: ${pxToRem(8)};
        }

        input,
        .ql-preview {
          margin-left: ${pxToRem(35)};
        }

        .ql-preview {
          color: #000000;
          text-decoration: underline;
        }

        .ql-action,
        .ql-remove {
          font-weight: 500;
        }

        .ql-action {
          color: #000000;
        }

        .ql-remove {
          color: #c9201d;
        }
      }
    }

    .ql-container,
    .ql-toolbar {
      width: 100%;

      ${(props) =>
        props.error &&
        css`
          border-color: #c9201d;
        `}
    }

    .ql-counter {
      display: ${(props) => (props?.maxCharacters ? 'block' : 'none')};
      color: #757575;
      font-size: ${pxToRem(12)};
      line-height: ${pxToRem(18)};
      text-align: right;

      &.error {
        color: #c9201d;
      }
    }
  }
`

export const ErrorTextStyles = {
  position: 'absolute',
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  gap: pxToRem(4)
}

export const LabelTextStyles = { fontWeight: 500 }
