import { useGetEnv } from '@Hooks/useGetEnv/useGetEnv'
import { Environment } from '@Src/types/environment'
import { useMutation } from '@tanstack/react-query'
import { UseMutation } from '../api.types'
import { useApi } from '../hooks/useApi'
import { useBuildQueryKeys } from '../hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'

interface Param {
  name: string
  value: string | number
}

export interface UseGenerateDeepLinkMutationResponse {
  data: {
    feature: string
    params: Param[]
    url: string
  }
}

export interface UseGenerateDeepLinkMutationFn {
  vendorId: string
  country: string
  data: {
    feature: string
    params: Param[]
  }
}

const getServiceUrl = (env: Environment) => {
  if (env !== Environment.PRODUCTION) {
    return `https://services-${env}.bees-platform.dev/api/deeplink-service/v1/deeplink`
  }

  return 'https://services.bees-platform.com/api/deeplink-service/v1/deeplink'
}

export const useGenerateDeepLinkMutation = ({
  mutationKeys,
  options
}: UseMutation<UseGenerateDeepLinkMutationResponse, Error, UseGenerateDeepLinkMutationFn>) => {
  const { headers } = usePrepareHeaders()
  const env = useGetEnv()
  const { api } = useApi()

  const generateDeepLinkMutationKeys = useBuildQueryKeys(mutationKeys)

  const mutation = useMutation<
    UseGenerateDeepLinkMutationResponse,
    Error,
    UseGenerateDeepLinkMutationFn
  >(generateDeepLinkMutationKeys, {
    mutationFn: ({ vendorId, country, data }: UseGenerateDeepLinkMutationFn) => {
      return api({
        url: getServiceUrl(env),
        method: 'POST',
        data,
        headers: { ...headers, vendorId, country }
      })
    },
    ...options
  })

  return mutation
}
