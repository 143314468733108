import { colors } from '@Styles/theme/colors'
import { Button } from '@mui/material'
import styled, { css } from 'styled-components'
import { ButtonProps, VariantStylesArgs } from './Button.types'

const disabledOutlinedStyles = css`
  color: ${colors.grey[400]};
  background-color: ${colors.grey[300]};
  border: 1px solid ${colors.grey[400]};
`

const disabledContainedStyles = css`
  color: ${colors.grey[400]};
  background-color: ${colors.grey[300]};
`

const disabledTextStyles = css`
  color: ${colors.grey[400]};
`

const variantStyles = ({ variant, disabled }: VariantStylesArgs) => {
  return {
    outlined: css`
      background-color: ${colors.white};
      border: 1px solid black;
      ${disabled && disabledOutlinedStyles}
    `,
    text: css`
      background-color: transparent;
      ${disabled && disabledTextStyles}
    `,
    contained: css`
      background-color: ${colors.black[900]};
      color: ${colors.white};
      ${disabled && disabledContainedStyles}
    `
  }[variant || 'text']
}

const sizeStyles = {
  small: css`
    font-size: 0.8rem;
    padding: 10px 12px;
  `,
  medium: css`
    font-size: 1rem;
    padding: 12px 16px;
  `,
  large: css`
    font-size: 1.1rem;
    padding: 14px 20px;
  `
}

export const StyledButton = styled(Button)<ButtonProps>`
  && {
    border-radius: 4px;
    line-height: 1;
    font-size: 1rem;
    padding: 12px 16px;

    ${({ size }) => sizeStyles[size || 'medium']}

    color: ${colors.black[900]};

    ${({ variant, disabled }) =>
      variantStyles({
        variant,
        disabled
      })}
  }
`
