import { Box, Image } from '@BarrelComponents'
import React from 'react'

import { StyledVideoThumbnail } from './VideoThumbnail.styles'
import { VideoThumbnailProps } from './VideoThumbnail.types'

export const VideoThumbnail = ({
  $width,
  $height,
  $padding,
  $paddingLeft,
  $paddingRight,
  $paddingBottom,
  $paddingTop,
  $margin,
  $marginBottom,
  $marginLeft,
  $marginRight,
  $marginTop,
  src,
  alt,
  ...props
}: VideoThumbnailProps) => {
  return (
    <Box
      $padding={$padding}
      $paddingBottom={$paddingBottom}
      $paddingLeft={$paddingLeft}
      $paddingRight={$paddingRight}
      $paddingTop={$paddingTop}
      $margin={$margin}
      $marginBottom={$marginBottom}
      $marginLeft={$marginLeft}
      $marginRight={$marginRight}
      $marginTop={$marginTop}
      $height={$height}
      $width={$width}
    >
      <StyledVideoThumbnail {...props}>
        {src && <Image src={src} alt={alt ?? ''} />}
      </StyledVideoThumbnail>
    </Box>
  )
}
