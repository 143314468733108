import { Box, Image, Stack, Text, Title, VideoThumbnail } from '@BarrelComponents'
import { TextButton, Tooltip } from '@hexa-ui/components'
import { ChevronUp, IconProps } from '@hexa-ui/icons'
import DragIndicatorSharpIcon from '@mui/icons-material/DragIndicatorSharp'
import { useTranslationsContext } from '@Src/shared/contexts/I18nContext/I18nContext'
import pxToRem from '@Utils/pxToRem/pxToRem'
import React from 'react'
import { useTheme } from 'styled-components'
import * as S from './Accordion.styles'
import { AccordionProps, DivElementRef } from './Accordion.types'

const ExpandIcon = () => {
  return (
    <Box className="expand-icon-box" sx={{ marginLeft: pxToRem(10), marginRight: pxToRem(10) }}>
      <ChevronUp />
    </Box>
  )
}

export const Accordion = React.forwardRef(
  (
    {
      children,
      title,
      mediaType = 'Image',
      actions,
      imgSource,
      thumbnailSource,
      showDragIcon,
      dragIcon,
      id,
      expandedAccordion,
      advancedConfig,
      setExpandedAccordion,
      hideFilePreview,
      $hexaUI,
      ...props
    }: Omit<AccordionProps, 'expanded'>,
    ref: DivElementRef<HTMLDivElement>
  ) => {
    const theme = useTheme()
    const isExpanded =
      expandedAccordion === id || (typeof expandedAccordion === 'boolean' && expandedAccordion)

    const {
      translations: { contentEditorPage: launchEditorTranslations }
    } = useTranslationsContext()

    const { imageText, videoText } = launchEditorTranslations

    const mediaTypeTranslation = mediaType === 'Video' ? videoText : imageText

    return (
      <S.StyledAccordion
        {...props}
        elevation={0}
        variant="outlined"
        ref={ref}
        expanded={isExpanded}
        onChange={(...params) => {
          if (!expandedAccordion) setExpandedAccordion(id, ...params)
          else if (expandedAccordion === id) setExpandedAccordion('accordion', ...params)
          else setExpandedAccordion(id, ...params)
        }}
      >
        <S.StyledAccordionSummary
          expandIcon={props.disabled ? undefined : <ExpandIcon />}
          className="accordion-summary"
        >
          <Box
            className="MuiAccordionSummary-customContent"
            sx={{ margin: 'auto 0', padding: '10px 0' }}
          >
            {!props.disabled && showDragIcon && (
              <Box
                className={`MuiAccordion-dragIconWrapper ${isExpanded ? 'expanded' : ''}`}
                style={{
                  cursor: isExpanded ? 'default' : 'grab',
                  pointerEvents: isExpanded ? 'none' : 'all'
                }}
                {...dragIcon?.props}
                ref={dragIcon?.ref}
              >
                <DragIndicatorSharpIcon className="MuiAccordion-dragIcon" />
              </Box>
            )}
            <Box className="MuiAccordion-customTitleWrapper">
              <Tooltip text={title as string} placement="bottom">
                <Box>
                  <Title
                    variant="h2"
                    className="MuiAccordion-customTitle"
                    sx={{
                      maxWidth: '25rem',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontWeight: '400 !important',
                      margin: '0 !important'
                    }}
                  >
                    {title as string}
                  </Title>

                  {mediaType && (
                    <Text $textColor="disabled" $fontSize="0.9rem">
                      {mediaTypeTranslation}
                    </Text>
                  )}
                </Box>
              </Tooltip>
            </Box>
          </Box>
          {!hideFilePreview && mediaType === 'Image' && imgSource && !isExpanded && (
            <Box
              sx={{
                borderBottomRightRadius: props.disabled ? '16px' : '0',
                borderTopRightRadius: props.disabled ? '16px' : '0',
                borderLeft: `1px solid ${theme.palette?.grey?.[400]}`,
                borderRight: `1px solid ${theme.palette?.grey?.[400]}`,
                overflow: 'hidden',
                marginRight: props.disabled ? '-16px' : '8px'
              }}
            >
              <Image
                className="MuiAccordionSummary-customImg"
                src={imgSource}
                alt={String(title)}
              />
            </Box>
          )}
          {!hideFilePreview && mediaType === 'Video' && thumbnailSource && !isExpanded && (
            <Box
              sx={{
                borderBottomRightRadius: props.disabled ? '16px' : '0',
                borderTopRightRadius: props.disabled ? '16px' : '0',
                borderLeft: `1px solid ${theme.palette?.grey?.[400]}`,
                borderRight: `1px solid ${theme.palette?.grey?.[400]}`,
                overflow: 'hidden',
                marginRight: props.disabled ? '-16px' : '8px',
                width: props.disabled ? '250px' : undefined
              }}
            >
              <VideoThumbnail
                src={thumbnailSource}
                alt={String(title)}
                $height={pxToRem(64)}
                $width={pxToRem(274)}
              />
            </Box>
          )}
        </S.StyledAccordionSummary>
        {!props.disabled && <S.StyledAccordionDetails>{children}</S.StyledAccordionDetails>}
        {!props.disabled && (advancedConfig || (actions && actions?.length > 0)) && (
          <S.StyledAccordionActions>
            <Stack sx={{ width: '50%' }}>
              {advancedConfig && (
                <Box className="MuiAccordionActions-configuration">{advancedConfig}</Box>
              )}
            </Stack>
            <Stack sx={{ width: '50%' }}>
              {actions && actions?.length > 0 && (
                <Stack
                  className={`MuiAccordionActions-btn ${advancedConfig ? 'txtAlignRight' : ''} ${
                    $hexaUI ? 'hexa-ui-advanced-configurations-block' : ''
                  }`}
                  alignItems="flex-end"
                  direction="row"
                  gap={pxToRem(16)}
                  sx={{ marginLeft: 'auto' }}
                >
                  {React.Children.toArray(
                    actions?.map((action) => {
                      if (action.type === 'component') return action.component

                      const { icon, label, fn, variant = 'text' } = action
                      return (
                        <TextButton
                          icon={(() => icon) as React.FunctionComponent<IconProps>}
                          iconPosition="leading"
                          type="button"
                          css={{
                            ...(variant === 'destructive' && {
                              color: 'white',
                              backgroundColor: theme.palette?.error?.main,
                              padding: `${pxToRem(8)} ${pxToRem(16)}`,
                              borderRadius: pxToRem(32),
                              '&:hover': {
                                svg: {
                                  fill: variant === 'destructive' ? 'white' : 'black'
                                }
                              }
                            })
                          }}
                          onClick={fn}
                        >
                          {label}
                        </TextButton>
                      )
                    })
                  )}
                </Stack>
              )}
            </Stack>
          </S.StyledAccordionActions>
        )}
      </S.StyledAccordion>
    )
  }
)
