import { Stack, Text, Tooltip } from '@BarrelComponents'
import pxToRem from '@Utils/pxToRem/pxToRem'
import {} from '@hexa-ui/components'
import React from 'react'
import { ContentTitleProps } from './ContentTitle.types'

export const ContentTitle = ({ title, type }: ContentTitleProps) => {
  return (
    <>
      {title.length <= 40 ? (
        <Text $capitalize $fontSize={pxToRem(14)} component="strong">
          {title}
        </Text>
      ) : (
        <Stack direction="row">
          <Text $capitalize $fontSize={pxToRem(14)} component="strong">
            {title.slice(0, 40)}
          </Text>
          <Tooltip title={title} arrow>
            <Text>...</Text>
          </Tooltip>
        </Stack>
      )}
      <Text $textColor="primary" $fontSize={pxToRem(14)}>
        {type}
      </Text>
    </>
  )
}
