import { colors } from '@Styles/theme/colors'
import pxToRem from '@Utils/pxToRem/pxToRem'
import TablePagination from '@mui/material/TablePagination'
import styled, { css } from 'styled-components'
import { TablePaginationProps } from './TablePagination.types'

const sizeVariant = (size: 'small' | 'medium' | 'large') => {
  return {
    small: css`
      font-size: ${pxToRem(14)};
    `,
    medium: css`
      font-size: ${pxToRem(16)};
    `,
    large: css`
      font-size: ${pxToRem(18)};
    `
  }[size]
}

export const StyledTablePagination = styled(TablePagination)<Pick<TablePaginationProps, 'size'>>`
  border-bottom: 0;
  ${({ size }) => css`
    ${!!size && sizeVariant(size)}
  `};
  .MuiInputBase-root {
    order: 1;
  }
  .MuiTablePagination-spacer {
    display: none;
  }
  .MuiTablePagination-toolbar {
    width: 100%;
    min-width: auto;
    display: flex;
  }
  .MuiTablePagination-displayedRows {
    ${({ size }) => css`
      ${!!size && sizeVariant(size)}
    `};
  }
  .MuiTablePagination-selectLabel {
    ${({ size }) => css`
      ${!!size && sizeVariant(size)}
    `};
    font-weight: 500;
    order: 2;
  }
  .MuiInputBase-root {
    margin-left: ${pxToRem(24)};
    margin-right: ${pxToRem(16)};
  }
  .MuiTablePagination-select {
    display: flex;
    align-items: center;
    order: 2;

    border: 1px solid ${colors.grey[200]};
    border-radius: ${pxToRem(4)};
    width: ${pxToRem(78)};
    height: ${pxToRem(29)};
    ${({ size }) => css`
      ${!!size && sizeVariant(size)}
    `};
    padding-left: ${pxToRem(16)};
    &:focus {
      background-color: transparent;
    }
  }
  .MuiPagination-root {
    order: 2;
  }
`
