import { Box, FormHelperText, InteractiveChip } from '@BarrelComponents'
import { CONTAINER_PADDING } from '@Components/molecules/Autocomplete/Autocomplete.constants'
import { buildErrorMessage } from '@Components/molecules/Autocomplete/Autocomplete.helpers'
import { StyledAutocomplete } from '@Components/molecules/Autocomplete/Autocomplete.styles'
import {
  AutocompleteOptionData,
  AutocompleteProps
} from '@Components/molecules/Autocomplete/Autocomplete.types'
import { useLimitTags } from '@Components/molecules/Autocomplete/hooks/useLimitTags/useLimitTags'
import { getParentElementWidth } from '@Components/molecules/Autocomplete/utils/getParentElementWidth/getParentElementWidth'
import useDebounce from '@Src/shared/hooks/useDebounce/useDebounce'
import { Tooltip } from '@hexa-ui/components'
import { Search } from '@hexa-ui/icons'
import { AutocompleteRenderGetTagProps } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import React, { useMemo, useRef, useState } from 'react'

const renderTagsDefaultValue = (value: unknown[], getTagProps: AutocompleteRenderGetTagProps) => {
  return (value as AutocompleteOptionData[]).map((option, index) => (
    <div key={String(option)} className="tooltip-tags">
      <Tooltip text={String(option)}>
        <InteractiveChip label={option} {...getTagProps({ index })} />
      </Tooltip>
    </div>
  ))
}

export const Autocomplete = ({
  value,
  onChange,
  forcePopupIcon,
  placeholder,
  options,
  loading,
  controlOpening,
  disabled,
  error,
  errorMessage,
  limitTags,
  maxTags = 12,
  maxCharacters = 256,
  $leftIcon = true,
  multiple = true,
  $chipMaxWidth = '210px',
  renderTags = renderTagsDefaultValue,
  freeSolo = true,
  ...props
}: Omit<AutocompleteProps, 'renderInput'>) => {
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [internalError, setInternalError] = useState('')
  const debouncedError = useDebounce<string>(internalError, 100)
  const autocompleteRef = useRef<HTMLInputElement>(null)

  const filteredOptions = useMemo(
    () => [...new Set(options?.map((option) => (option as AutocompleteOptionData)?.label))],
    [options]
  )

  const CONTAINER_WIDTH_LESS_PADDINGS = getParentElementWidth({
    ref: autocompleteRef,
    containerPadding: CONTAINER_PADDING
  })

  const tags = autocompleteRef?.current?.parentElement?.getElementsByClassName(
    'tooltip-tags'
  ) as HTMLCollectionOf<HTMLElement>

  const handleLimitTags = useLimitTags({
    value,
    containerWidth: CONTAINER_WIDTH_LESS_PADDINGS,
    limitTags,
    tags
  })

  return (
    <StyledAutocomplete
      {...props}
      $chipMaxWidth={$chipMaxWidth}
      onChange={(event: any, values: any, reason: any, details: any) => {
        if (inputValue && inputValue.match(/^ *$/)) return

        if (values.length > maxTags) {
          return setInternalError(
            buildErrorMessage({
              maxTags,
              maxCharacters
            }).maxTags
          )
        }
        if (inputValue.length > maxCharacters) {
          return setInternalError(
            buildErrorMessage({
              maxTags,
              maxCharacters
            }).maxCharacters
          )
        }
        setInternalError('')
        onChange(values, event, reason, details)
      }}
      open={open}
      onOpen={() => (!controlOpening ? setOpen(true) : inputValue && setOpen(true))}
      inputValue={inputValue}
      onClose={() => setOpen(false)}
      onInputChange={(_, value) => {
        setInputValue(value)
        if (value.length > maxCharacters) {
          setInternalError(
            buildErrorMessage({
              maxTags,
              maxCharacters
            }).maxCharacters
          )
          return
        }
        setInternalError('')
        return !value && setOpen(false)
      }}
      disablePortal
      getOptionLabel={(option) => (option as AutocompleteOptionData)?.label ?? option}
      $leftIcon={$leftIcon}
      value={value}
      freeSolo={freeSolo}
      multiple={multiple}
      loading={loading}
      disabled={!!loading || disabled}
      fullWidth
      limitTags={handleLimitTags}
      options={filteredOptions}
      renderTags={renderTags}
      forcePopupIcon={forcePopupIcon}
      renderInput={(params) => (
        <Box position="relative">
          <TextField
            {...params}
            error={!!debouncedError || error}
            ref={autocompleteRef}
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps,
                'data-testid': 'autocomplete-input'
              },
              startAdornment: (
                <>
                  {$leftIcon && (
                    <InputAdornment position="start">
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <Search size="large" />
                      )}
                    </InputAdornment>
                  )}
                  {params.InputProps.startAdornment}
                </>
              )
            }}
            variant="outlined"
            placeholder={!value?.length ? placeholder : ''}
          />
          <FormHelperText error={!!debouncedError || error}>
            {debouncedError || errorMessage}
          </FormHelperText>
        </Box>
      )}
    />
  )
}
