import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { ptBR } from './languages/br/pt'
import { koKR } from './languages/kr/ko'
import { enUS } from './languages/us/en'

const i18 = i18n as any

export const i18nInit = i18
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'us',
    fallbackNS: 'en',
    defaultNS: 'en',
    resources: {
      us: {
        en: enUS
      },
      kr: {
        ko: koKR
      },
      br: {
        pt: ptBR
      }
    }
  })
