import Quill from 'quill'
import { useCallback, useEffect, useState } from 'react'
import { UseInitialValue } from '../RichTextEditor.types'

type Delta = Parameters<Quill['setContents']>[0]

export const useInitialValue = ({ quillInstance, initialValue }: UseInitialValue) => {
  const [hasInitialValue, setHasInitialValue] = useState(false)
  const { value, html } = initialValue || {}

  const setInitialValue = useCallback(() => {
    if (!quillInstance || hasInitialValue) return

    if (typeof value === 'string') quillInstance.setText(value)
    else quillInstance.setContents(value as Delta)

    if (value || html) setHasInitialValue(true)
  }, [quillInstance, hasInitialValue, value, html])

  useEffect(() => {
    setInitialValue()
  }, [setInitialValue])
}
