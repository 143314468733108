import { useLogService } from 'admin-portal-shared-services'
import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import App from './App'

const AppMountBoundary = (_error: Error, _info: any, _props: any) => {
  useLogService().error(_error)
  return <p>An error has occurred, try later</p>
}

const reactLifeCycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary: AppMountBoundary
})

export const { bootstrap, mount, unmount } = reactLifeCycles
