import { ActionsContainer } from '@BarrelComponents'
import { Zones } from '@content-shared-components/image-uploader'
import { useTranslationsContext } from '@Contexts/I18nContext/I18nContext'
import { Button } from '@hexa-ui/components'
import { useContent } from '@Src/components/organisms/AppHeader/hooks/useContent'
import { useGetEnv } from '@Src/shared/hooks/useGetEnv/useGetEnv'
import { useAppSelector } from '@Src/shared/redux/hooks/reduxHooks'
import { isAbiStore } from '@Src/shared/utils/storeUtils/storeUtils'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteModalFooterProps } from './DeleteModalFooter.types'

export const DeleteModalFooter = ({
  setOpen,
  targetLaunch,
  variant = 'content'
}: DeleteModalFooterProps) => {
  const { onDelete, isLoading, content } = useContent({ targetLaunch, onFinish })
  const navigate = useNavigate()
  const { zone, storeId } = useAppSelector(({ application }) => application)
  const env = useGetEnv()

  function onFinish() {
    if (variant === 'banner') {
      const customerPath = '/banners-launch-list/customer'
      const partnerPath = '/banners-launch-list/partner'

      const isPartnerStore = !isAbiStore({ zone: zone as Zones, storeId: storeId.toString(), env })

      navigate(isPartnerStore ? partnerPath : customerPath)
    }
    setOpen(false)
  }

  const {
    translations: { contentListPage, shared: sharedTranslations }
  } = useTranslationsContext()
  const { deleteLaunchModalCancelButtonTextMinified, deleteLaunchModalSubmitButtonTextMinified } =
    contentListPage
  const { cancelText, deleteText } = sharedTranslations

  const onCloseModal = () => setOpen(false)

  return (
    <ActionsContainer $padding={0}>
      <Button
        type="submit"
        variant={variant === 'banner' ? 'destructive' : 'primary'}
        onClick={onDelete}
        disabled={isLoading || !content}
      >
        {variant === 'banner' ? deleteLaunchModalSubmitButtonTextMinified : deleteText}
      </Button>
      <Button type="button" variant="secondary" onClick={onCloseModal}>
        {variant === 'banner' ? deleteLaunchModalCancelButtonTextMinified : cancelText}
      </Button>
    </ActionsContainer>
  )
}
