import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import styled from 'styled-components'
import { BreadcrumbsWrapper, LinkElement } from './Breadcrumbs.types'

export const StyledBreadcrumbs = styled(Breadcrumbs)<BreadcrumbsWrapper>`
  & > .fallback-message {
    color: ${({ theme }) => theme.palette?.text?.disabled};
  }
`
export const StyledLink = styled(Link)<LinkElement>`
  font-size: 16px;
  color: ${({ theme }) => theme.palette?.text?.secondary};
  &:hover {
    color: ${({ theme }) => theme.palette?.link?.dark};
  }
  &.actived {
    color: ${({ theme }) => theme.palette?.grey?.[400]};
    &:hover {
      color: ${({ theme }) => theme.palette?.grey?.[400]};
      cursor: not-allowed;
    }
  }
`
