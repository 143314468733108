import styled from 'styled-components'
import globalVars from '../utils/constants'
import pxToRem from '../utils/pxToRem/pxToRem'

export const FallbackErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - ${globalVars.headerHeight}px);
  flex-direction: column;
  gap: ${pxToRem(10)};
`
