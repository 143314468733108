import pxToRem from '@Utils/pxToRem/pxToRem'
import Autocomplete from '@mui/material/Autocomplete'
import styled, { css } from 'styled-components'
import { AutocompleteProps } from './Autocomplete.types'

export const StyledAutocomplete = styled(Autocomplete)<AutocompleteProps>`
  & .MuiAutocomplete-tag {
    max-width: ${({ $chipMaxWidth }) => $chipMaxWidth};
  }

  & + .MuiAutocomplete-popper {
    .MuiAutocomplete-paper {
      border-radius: 16px;
      box-shadow: 0 0px 12px -4px rgb(0 0 0 / 20%);
    }

    .MuiAutocomplete-listbox {
      padding: 0;
    }

    .MuiAutocomplete-option {
      padding: ${pxToRem(16)};

      &[aria-selected='true'] {
        background: ${({ theme }) => theme.palette?.grey?.[100]};
        font-weight: 600;
      }
    }
  }

  && {
    fieldset {
      border: 0 !important;
    }

    & .MuiInputBase-root {
      position: relative;
      padding: 1px 42px 1px 8px;
      overflow-y: auto;
      max-height: 120px;
      border-radius: 24px;
      background: ${({ theme }) => theme.palette?.grey?.[100]};

      &.Mui-error {
        outline: 1px solid ${({ theme }) => theme.palette?.error?.main};

        &:focus {
          outline-width: 2px;
        }
        &:hover {
          outline-color: ${({ theme }) => theme.palette?.error?.main};
        }
      }
    }

    .MuiAutocomplete-input {
      min-width: 0;
      color: black;
      font-weight: 500;
      font-size: ${pxToRem(18)};
      line-height: 2;

      &:focus {
        min-width: 30px;
      }

      &::placeholder {
        font-family: 'Work Sans';
        font-size: 1rem;
        color: rgba(20, 20, 20, 0.56);
        opacity: 1;
      }
    }

    ${({ $leftIcon }) =>
      $leftIcon &&
      css`
        & .MuiInputBase-root {
          padding: 1px 42px 1px 44px;
        }
        & .MuiAutocomplete-input {
          padding-left: 0;
        }
        & .MuiInputAdornment-positionStart {
          position: absolute;
          left: 14px;
          top: 21px;
        }
      `}
  }
`
