import { FormHelperText } from '@BarrelComponents'
import pxToRem from '@Utils/pxToRem/pxToRem'
import React, { forwardRef } from 'react'
import { StyledTextField } from './TextField.styles'
import { TextFieldProps } from './TextField.types'

export const TextField = forwardRef(
  (
    {
      $padding = `${pxToRem(10)} ${pxToRem(16)}`,
      id,
      fullWidth = true,
      helperText,
      error,
      $hexaUI,
      ...props
    }: TextFieldProps,
    ref
  ) => {
    return (
      <>
        <StyledTextField
          {...props}
          ref={ref as TextFieldProps['ref']}
          aria-describedby={id}
          id={id}
          $padding={$padding}
          error={error}
          fullWidth={fullWidth}
        />

        <FormHelperText
          id={id}
          error={error}
          sx={$hexaUI ? { color: 'rgb(201, 32, 29) !important' } : {}}
        >
          {helperText}
        </FormHelperText>
      </>
    )
  }
)
