import { zonesWithCustomer, zonesWithPartnerStorefront } from '@Utils/zoneUtils/zoneUtils'
import { filterZonesByStoreTypeProps } from '../ContentList.types'

export const filterZonesByStoreType = (props: filterZonesByStoreTypeProps) => {
  const { isCurrentStoreCustomer, zonesWithLabel } = props
  const customerZones = zonesWithCustomer().map((item) => item.toUpperCase())
  const partnerStoresZones = zonesWithPartnerStorefront().map((item) => item.toUpperCase())

  if (isCurrentStoreCustomer) {
    return Object.keys(zonesWithLabel).reduce((result: any, zoneKey: string) => {
      const zoneConfig = zonesWithLabel[zoneKey]
      const zoneConfigValue = zoneConfig.value.split(':')[0]
      if (customerZones.includes(zoneConfigValue)) {
        return [...result, zonesWithLabel[zoneKey]]
      }
      return result
    }, [])
  }

  return Object.keys(zonesWithLabel).reduce((result: any, zoneKey: string) => {
    const zoneConfig = zonesWithLabel[zoneKey]
    const zoneConfigValue = zoneConfig.value.split(':')[0]
    if (partnerStoresZones.includes(zoneConfigValue)) {
      return [...result, zonesWithLabel[zoneKey]]
    }
    return result
  }, [])
}
