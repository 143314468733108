import { BreakpointsOptions } from '@material-ui/core/styles/createBreakpoints'

export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 425,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536
  }
}
