import { useEffect } from 'react'
import useDocumentCookieService from '../../utils/documentCookieService/documentCookieServiceImp'

const ENV = 'ENV'
const PROD = 'prod'

export function useCookieService(): void {
  // eslint-disable-next-line react-hooks/exhaustive-deps, new-cap
  const cookieService = new useDocumentCookieService()

  const splitedDomain = window.location.hostname.split('.')
  const isUs = splitedDomain[0].includes('us')
  const domain = `${splitedDomain[splitedDomain.length - 2]}.${
    splitedDomain[splitedDomain.length - 1]
  }`

  useEffect(() => {
    const env = JSON.parse(localStorage.getItem('portal-config') || '{}')[ENV] || ''

    const refreshExpires = +(localStorage.getItem('refresh_token_expires_in') || 0)

    cookieService.setCookie({
      name: `admin_portal_refresh${isUs ? '_us' : ''}${env === PROD ? '' : `_${env}`}`,
      value: localStorage.getItem('refresh_token') || '',
      attributes: {
        domain,
        secure: true,
        expires: refreshExpires ? new Date(Date.now() + refreshExpires) : undefined
      }
    })
  }, [cookieService, domain, isUs])
}

export default useCookieService
