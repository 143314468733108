export const createColorsPreviews = () => {
  const colors = document.querySelectorAll('.ql-color-picker .ql-picker-item')
  colors.forEach((color) => {
    const colorValue = color.getAttribute('data-value') as string
    const colorPreview = document.createElement('span')
    colorPreview.style.background = colorValue
    colorPreview.classList.add('ql-color-preview')
    color.appendChild(colorPreview)
  })
}
