import { Filters } from './ContentList.types'

export const defaultFiltersValues: Filters = {
  contentTitleFilter: [],
  contentTypeFilter: [],
  statusFilter: ['live'],
  editedByFilter: [],
  startDate: null,
  endDate: null
}
