import { useUserMetadata } from 'admin-portal-shared-services'

export const useFilteredUserActiveStores = () => {
  return useUserMetadata()
    .data?.vendors?.flatMap((vendor) => {
      return vendor.stores
    })
    .map((store) => {
      return store?.id
    })
}
