import { useAuthenticationService } from 'admin-portal-shared-services'

export const getAuthUserCountry = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const authentication = useAuthenticationService()
  const token = authentication.getAuthHeader()
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage()
  return {
    Authorization: token,
    country: userCountry || 'DO'
  }
}
