import { useLimitTagsProps } from '@Components/molecules/Autocomplete/Autocomplete.types'
import { useLayoutEffect, useState } from 'react'

export const useLimitTags = ({
  value,
  containerWidth,
  tags,
  limitTags: lmtTags
}: useLimitTagsProps) => {
  const [limitTags, setLimitTags] = useState(lmtTags)

  useLayoutEffect(() => {
    if (lmtTags) {
      return
    }

    if (tags && containerWidth) {
      const tagsWidth = Array.from(tags).reduce((sum, item, index, arr) => {
        const width = sum + item.offsetWidth
        if (width > containerWidth && limitTags === -1) {
          setLimitTags(index - 1)
          arr.slice(0, index + 1)
        }
        return width
      }, 0)

      if (tagsWidth <= containerWidth) {
        return setLimitTags(-1)
      }
    }

    if (!value.length) {
      return setLimitTags(-1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return limitTags
}
