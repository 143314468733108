import { useAnalyticsService } from 'admin-portal-shared-services'
import { useEffect } from 'react'

export const useInitializeAnalytics = (segmentKey: string): void => {
  const analyticsService = useAnalyticsService()
  useEffect(() => {
    analyticsService.load(segmentKey)
  }, [analyticsService, segmentKey])
}
export default useInitializeAnalytics
