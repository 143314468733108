import { Text } from '@BarrelComponents'
import pxToRem from '@Utils/pxToRem/pxToRem'
import React from 'react'
import { LastEditProps } from './LastEdit.types'

export const LastEdit = ({ editor, formattedUpdatedAt }: LastEditProps) => {
  return (
    <>
      <Text $capitalize $fontSize={pxToRem(14)} component="strong">
        {formattedUpdatedAt}
      </Text>
      <Text $textColor="primary" $fontSize={pxToRem(14)} $capitalize>
        {editor}
      </Text>
    </>
  )
}
