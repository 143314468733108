import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import decodeJwt from '@Utils/decodeJwt/decodeJwt'
import getLocalStorage from '@Utils/getLocalStorage/getLocalStorage'
import { UserState } from './userSlice.types'

const jwt = getLocalStorage('authHeader')
const decodedJwt = decodeJwt(jwt)

const initialState: UserState = {
  email: decodedJwt?.email,
  jwt,
  roles: decodedJwt?.scopes,
  supportedCountries: decodedJwt?.supportedCountries
}

export const { actions, reducer: userSliceReducer } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      state.email = action.payload.email
    }
  }
})

export const { setUser } = actions

export default userSliceReducer
