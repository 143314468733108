import React, { createContext, useContext } from 'react'
import { EnvProviderProps } from './EnvContext.types'

const defaultInitialValue: EnvProps = {
  cms_base_url: '',
  BRIGHTCOVE_PLAYER_ID: 'default',
  BRIGHTCOVE_PLAYER_POLICY: 'default',
  optimizelyKey: ''
}

export const EnvContext = createContext(defaultInitialValue)

const EnvProvider = ({ env, children }: EnvProviderProps): JSX.Element => {
  return <EnvContext.Provider value={env}>{children}</EnvContext.Provider>
}

export const useEnvProps = (): EnvProps => {
  const envContext = useContext(EnvContext)
  return envContext
}

export default EnvProvider
