import { useQuery } from '@tanstack/react-query'
import { UseQuery } from '../api.types'
import { useApi } from '../hooks/useApi'
import { useApplicationInfo } from '../hooks/useApplicationInfo'
import { useBuildQueryKeys } from '../hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'
import { queryFn } from '../utils/queryFn'

export interface UseItemsListQuery {
  data: {
    data: {
      id: StringOrNumber
      guid: StringOrNumber
      immutable_guid: string
      is_live: boolean
      status: string
      name: string
      path: string
      launch_time: string
      launch_timezone: string
      mod_time: string
      is_cascade: boolean
      username: string
      modules: string[]
      page_template: string
      modulesType: string
      deleted_at: string | null
    }[]
  }
}

export const useItemsListQuery = ({ queryKeys, options }: UseQuery<UseItemsListQuery, Error>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()
  const { api } = useApi()

  const itemsListQueryKeys = useBuildQueryKeys(queryKeys)

  const query = useQuery<UseItemsListQuery, Error>(
    itemsListQueryKeys,
    queryFn(api, `${cmsCoreBackend}/contentedit/v2/zone_${zone.toLowerCase()}/allpages.json`, {
      headers
    }),
    options
  )

  return query
}
