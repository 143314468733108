import isEmpty from 'lodash/isEmpty'
import { Filters } from '../ContentList.types'

export const determiningFilters = (advancedFilters: Filters) => {
  return Object.entries(advancedFilters).some(([key, value]) => {
    if (key === 'contentTitleFilter') return false
    if (key.includes('Date')) {
      if (!value) return false
      return true
    }
    return !isEmpty(value)
  })
}
