/* eslint-disable max-lines */
import { TranslationsObject } from '@Contexts/I18nContext/I18nContext.types'

export const enUS: TranslationsObject = {
  zoneSelectionPage: {
    modalTitle: 'Hi {{name}}, welcome!',
    modalSubTitle: 'Please select a country to continue.'
  },
  contentListPage: {
    contentListPageTitle: 'Content list',
    customerPageTitle: 'BEES Customer content list',
    partnerstoreBreadcrumbText: 'BEES Partner storefront content list',
    addContentButtonText: 'Create new content',
    searchInputPlaceholder: 'Search by title',
    filtersButtonText: 'Filter',
    filteredByText: 'Filtered by',
    statusTabText: 'Status',
    editedByTabText: 'Edited By',
    editedByInputPlaceholder: 'Enter the email',
    startDateInputPlaceholder: 'Insert a start date',
    endDateInputPlaceholder: 'Insert an end date',
    filtersApplyButtonText: 'Apply',
    contentTypeActiveFilterText: 'type',
    statusActiveFilterText: 'status',
    editedByActiveFilterText: 'edited by',
    startDateActiveFilterText: 'Start date',
    startDateErrorText: 'Date must be at least 01/01/2019',
    endDateActiveFilterText: 'End date',
    endDateErrorText: 'Invalid date',
    contentTitleTableHeadText: 'Content title and placement',
    contentTitleTableHeadTextMinified: 'Content title',
    contentTitleTableHeadTooltipText: 'Launch title and type.',
    actionsTableHeadText: 'Actions',
    audienceTableHeadText: 'Audience',
    launchTableHeadText: 'Launch date and time',
    launchTableHeadTooltipText: 'Launch date and time.',
    timezoneTableHeadTooltipText: 'Launch timezone.',
    statusTableHeadTooltipText: 'Launch status.',
    lastEditTableHeadText: 'Last edited on',
    lastEditTableHeadTooltipText: 'Date, time, and email author.',
    deleteLaunchButtonTooltipText: 'Delete launch',
    deleteLaunchModalTitle: 'Are you sure you want to delete this launch?',
    deleteLaunchModalWarningText: 'You will lose all data stored in this launch once deleted',
    deleteLaunchModalWarningTextMinified: 'All content information will be lost.',
    deleteLaunchModalCancelButtonTextMinified: 'No, go back',
    deleteLaunchModalSubmitButtonTextMinified: 'Yes, delete',
    deleteLaunchMessageNotificationText: 'Launch deleted!',
    deleteLaunchNotFoundErrorNotificationText: 'Could not find the launch!',
    deleteLiveLaunchErrorNotificationText: 'Live launch cannot be deleted!',
    noResultsWereFoundText: 'No results were found.',
    bannerLaunchTitle: 'Banner launch',
    webViewLaunchTitle: 'Web View Launch',
    tableItemActionShowDetailsText: 'Show details'
  },
  contentEditorPage: {
    contentEditorPageTitle: 'Edit launch',
    newLaunchPageTitle: 'Create launch',
    launchInformationText: 'Launch properties',
    contentTitleLabel: 'Title',
    contentTitlePlaceholder: 'Add a title',
    contentTitleErrorText: 'You need to create a title.',
    contentTypePlaceholder: 'Choose a type',
    contentTypeErrorText: 'You need to select a type.',
    contentTimeZonePlaceholder: 'Select a timezone',
    contentTimeZoneErrorText: 'You need to select a timezone.',
    launchLabelPreviewFallbackText: 'Choose your banner',
    launchPlaceholder: 'Set date',
    launchErrorText: 'Invalid date.',
    timeLabel: 'Launch time',
    timePlaceholder: 'Set time',
    timeErrorText: 'Invalid time.',

    bannersText: 'Banners information',
    bannerImagePlaceholder: 'Drag and drop or upload your image.',
    bannerImageButtonText: 'Upload image',
    bannerImageTipText:
      'These banners must be JPG, PNG or GIF, have {{widthImgText}} x {{heightImgText}} dimensions and a maximum size of {{maxSizeImgText}}',
    webHomepageHeroBannerTipText:
      'These banners must be JPG, PNG or GIF, have {{widthImgText}} x {{heightImgText}} or {{widthImgText2}} x {{heightImgText2}} dimensions and a maximum size of {{maxSizeImgText}}',
    bannerImageErrorText: 'You need to have an image banner to continue.',
    bannerLabel: 'Banner title',
    bannerLabelPlaceholder: 'Add a label',
    bannerLabelErrorText: 'You need to have a banner label to continue.',
    alternativeTextLabel: 'Alternative text',
    alternativeTextPlaceholder: 'Add alt text',
    linkLabel: 'Link',
    linkPlaceholder: 'http / https / bees / tada',
    linkErrorText: 'The link must start with http://, https://, bees:// or tada://',
    advancedConfigurationsText: 'Advanced settings',
    vendorAssociationLabel: 'Vendor association',
    vendorAssociationPlaceholder: 'Enter one or more vendor',
    vendorAssociationErrorText: 'You have a wrong vendor selected',
    displayToUnassociatedVendorLabel: 'Display to unassociated Wholesalers',
    addNewBannerText: 'Add new banner',
    submitButtonText: 'Submit',
    createNewLaunchSubmitButtonText: 'Create new launch',
    updateSubmitButtonText: 'Update launch',
    updateLaunchSuccessNotificationText: 'Launch updated!',
    createNewLaunchSuccessNotificationText: 'Launch created!',
    deleteBannerNotificationText: 'Banner deleted!',
    pastDateTimeErrorNotificationText: "You can't create a launch on a past date or time.",
    updatePassedOrScheduleModuleTitle: 'You are updating an existing launch.',
    updatePassedOrScheduleModuleDescription:
      'After the update , all the information changed will be replaced.',
    mandatoryFieldsErrorNotificationText: 'Please complete the mandatory fields.',
    overwriteLaunchModalTitle:
      'A launch already exists at this date and time. Are you sure you want to overwrite the data of the existing launch?',
    overwriteLaunchModalTitlePartOne: 'A launch already exists at this date and time.',
    overwriteLaunchModalTitlePartTwo:
      'Are you sure you want to overwrite the data of the existing launch?',
    liveLaunchUpdateModalTitle: 'You are updating a live launch.',
    liveLaunchUpdateModalText: 'After the update, the changes will go live.',
    liveLaunchUpdateModalSubmitButtonText: 'Update',
    copyLaunchModalTitle: 'You are updating a {{status}} launch',
    copyLaunchModalText:
      'When you update the date and time of a launch, that automatically creates a new launch.',
    copyLaunchModalSubmitButtonText: 'Create a new launch',
    lastEditText: 'Last Edit',
    requiredField: 'Required field.',
    specificationPageTextBegin: 'Visit our',
    specificationPageTextLink: 'specification page',
    specificationPageTextEnd: 'to check all the details for encoding content.',
    mediaTypeText: 'Media type',
    imageText: 'Image',
    videoText: 'Video',
    videoTipText: 'Videos must be MP4 format and be less than 20MB.',
    videoUploadButtonText: 'Upload video',
    videoAspectErrorMessage: 'Video must have an aspect ratio of 16:9.',
    videoCallToActionCheckboxLabel: 'Add call to action button',
    videoCallToActionLabel: 'Call to action label',
    videoCallToActionPlaceholder: 'Add a label for this button',
    videoAddThumbnailLabel: 'Add thumbnail',
    videoAddThumbnailTooltip:
      'If no thumbnail is added, a still image from half of the video duration will be used instead.',
    videoThumbnailTitle: 'Thumbnail',
    videoThumbnailTipText:
      'Thumbnails must be 16:9 ratio, JPG, PNG format and must be less than 1MB.',
    videoThumbnailUploadButtonText: 'Browse files',
    videoThumbnailEnableMessage: 'You need to upload the video again to enable this field.',
    videoThumbnailAspectErrorMessage: 'Thumbnail must have an aspect ratio of 16:9.',
    webViewLinkTypeText: 'Web View Link',
    siteUrlLabel: 'Site url',
    siteUrlPlaceholder: 'Type the web view url',
    gameLinkLabel: 'Game link',
    gameLinkPlaceholder: 'The CMS url will be shown here',
    createGameBreadcrumbText: 'Create Game',
    editGameBreadcrumbText: 'Edit Game',
    editContentText: 'Edit content',
    copyLinkText: 'Copy link',
    fileIsTooBigErrorMessage: 'File is too big. Max file size allowed is 2.00 MB',
    titleMinimumCharactersErrorMessage: 'Title needs at least 4 characters',
    urlErrorMessage: 'You need to insert a valid url',
    audienceSegmentationTitle: 'Segmentation',
    audienceSegmentationSubtitle: 'Choose who should see this banner.',
    audienceSegmentationSubtitleUS: 'Define who will be impacted.',
    audienceSegmentationToggleLabel: 'Display banner to all POCs',
    audienceSegmentationToggleLabelUS: 'Display to unassociated wholesalers',
    customAudiencesText: 'Custom audiences',
    addCustomAudienceButtonText: 'Add custom audience',
    pocsTargetedText: 'POCs targeted',
    audienceDialogTitle: 'Audiences',
    addAudienceGroupText: 'Add audience group',
    allFieldsRequiredErrorMessage: 'You must enter all required fields to continue.',
    diplicateAudiencesErrorMessage:
      'Duplicate audiences are not allowed. Please remove the duplicates.',
    audienceSavedSuccessfullyMessage: 'Audience saved successfully',
    audienceNoBannerErrorMessage: 'There must be at least 1 banner displayed for all POCs.'
  },
  miNegocioContentList: {
    pageTitle: 'Mi Negocio content list',
    contentTabText: 'Content',
    metadaTabText: 'Metadata',
    newLaunchButtonText: 'New launch',
    longTipText: 'Long Tip',
    multipleEventsText: 'Multiple Events',
    eventText: 'Event',
    podcastText: 'Podcast',
    videoText: 'Video',
    dailyFeedText: 'Daily Feed',
    challengeText: 'Challenge',
    authorText: 'Author',
    categoryText: 'Category',
    tagText: 'Tag',
    metadataLabelTableHeadText: 'Label',
    metadataLabelTableHeadTooltip: 'Metadata label',
    metadataTypeTableHeadText: 'Metadata type',
    metadataCreatedOnTableHeadText: 'Created on',
    metadataCreatedOnTableHeadTooltip: 'Created at, date and time',
    metadaStatusTableHeadTooltip: 'Metadata status',
    metadaCreateDataButtonText: 'Create data',
    titleTableHeadText: 'Title',
    contentTypeTableHeadText: 'Content type',
    audienceTableHeadTooltip: 'Content audience',
    timezoneTableHeadTooltip: 'Content timezone',
    statusTableHeadTooltip: 'Content status'
  },
  homePage: {
    title: 'Hi {{name}}, welcome!',
    subtitle:
      'Here you can manage content for different platforms and also access campaign dashboards.',
    featuresTitle: 'features',
    cards: {
      features: {
        title: 'Features',
        beesConsumerDescription: 'Manage banners and web views on the BEES Customer launch list.',
        beesConsumerLinkText: 'Manage Customers content',
        beesPartnerDescription: 'Upload and manage content for partner storefronts.',
        beesPartnerLinkText: 'Manage Partners content',
        beesMiNegocioDescription: 'Create and manage all content for Mi Negocio.',
        beesMiNegocioLinkText: 'Manage Mi Negocio content',
        beesDeepLinkGeneratorDescription: 'Create deep links to be used in communications.',
        beesDeepLinkGeneratorLinkText: 'Generate deep links',
        beesDeepLinkMonitoringDescription: 'Manage and monitor all data generated by deep links.',
        beesDeepLinkMonitoringLinkText: 'Go to dashboard'
      },
      dashboard: {
        title: 'Dashboards'
      }
    }
  },
  partnerStorefront: {
    title: 'Hi {{name}}!',
    subtitle: 'Please select a store to continue.'
  },
  monitoringDashboard: {
    title: 'Monitoring',
    subtitle:
      'The items in this page may take some time to load. All information and graphics is generated by New Relic.',
    paragraph: 'You can find how to read the charts in this '
  },
  deepLinkGenerator: {
    createDeepLinkText: 'Create deep link',
    countryAndVendorTitleText: 'Country and vendor',
    countryAndVendorSubtitleText:
      'Select for what country and vendor you want to create the deep link.',
    countrySelectPlaceholder: 'Select Country',
    vendorSelectPlaceholder: 'Select Vendor',
    pathSelectorSubtitleText: 'Select where you want to create the deep link.',
    pathSelectorSearchInputPlaceholder: 'Search by path name',
    paramStoreSelectorSubtitle:
      'Select for what store you want to create the deep link, and to show the available parameters.',
    resultPageTitle: 'Deep link successfully created',
    resultPageSubtitle: 'Your deep link is active and ready to be used.',
    needToCreateNewOneText: 'Need to create a new one?',
    leaveDeepLinkGeneratorModalTitle: 'Leave deep link creation?',
    leaveDeepLinkGeneratorModalContentText: 'All information will be lost.',
    leaveDeepLinkGeneratorModalPrimaryButtonText: 'Yes, leave',
    leaveDeepLinkGeneratorModalSecondaryButtonText: 'No, go back',
    leaveWithoutCopyingLinkModalTitle: 'Leave deep link creation without copying the link?',
    leaveWithoutCopyingLinkModalContentText: 'The deep link will be lost.'
  },
  shared: {
    brandCarouselTypeText: 'Web homepage brand banner',
    guestHeroCarouselTypeText: 'Web guest homepage',
    heroCarouselTypeText: 'Web homepage hero banner',
    mobileBannersTypeText: 'Mobile homepage hero banner',
    streamlinedBannersTypeText: 'Mobile streamlined banners',
    webviewLinkTypeText: 'Mobile web view link',
    liveStatusText: 'live',
    pastStatusText: 'past',
    finishedStatusText: 'finished',
    scheduledStatusText: 'scheduled',
    newStatusText: 'new',
    activeStatusText: 'active',
    inactiveStatusText: 'inactive',
    noAudienceText: 'No audience',
    beesCustomerSidebarText: 'BEES Customer',
    beesPartnerStorefrontText: 'Partner storefront',
    beesMiNegocioText: 'Mi Negocio',
    beesDeepLinkGeneratorText: 'Deep link generator',
    beesDeepLinkMonitoringText: 'Deep link monitoring dashboard',
    audienceBuilderSidebarText: 'Audience Builder',
    zoneConfigurationSidebarText: 'ZONE Configuration',
    paginationCounterText: 'of',
    rowsPerPageText: 'Items per page',
    editText: 'edit',
    optionalText: 'optional',
    defaultErrorNotificationText: 'Something went wrong! Try again.',
    reviewContentText: 'Review the content:',
    confirmText: 'Confirm',
    searchText: 'Search',
    typeText: 'Type',
    launchDateText: 'Launch Date',
    cancelText: 'Cancel',
    timezoneText: 'Timezone',
    deleteText: 'Delete',
    countryText: 'Country',
    vendorText: 'Vendor',
    nextText: 'Next',
    pathText: 'Path',
    createText: 'Create',
    backText: 'Back',
    selectStoreText: 'Select store',
    copyText: 'Copy',
    storeText: 'Store',
    parametersText: 'Parameters',
    saveText: 'Save',
    countries: {
      aq: 'Antarctica',
      ar: 'Argentina',
      be: 'Belgium',
      br: 'Brazil',
      ca: 'Canada',
      cl: 'Chile',
      co: 'Colombia',
      de: 'Germany',
      do: 'Dominican Republic',
      ec: 'Ecuador',
      es: 'Canary Islands',
      gh: 'Ghana',
      hn: 'Honduras',
      id: 'Indonesia',
      mx: 'Mexico',
      nl: 'Netherlands',
      pa: 'Panama',
      pe: 'Peru',
      ph: 'Philippines',
      py: 'Paraguay',
      sv: 'El Salvador',
      tz: 'Tanzania',
      ug: 'Uganda',
      us: 'United States',
      uy: 'Uruguay',
      gb: 'Great Britain',
      za: 'South Africa',
      kr: 'South Korea'
    },
    locales: {
      es: 'Spanish',
      zh: 'Chinese',
      en: 'English',
      fr: 'French',
      sw: 'Swahili',
      ko: 'Korean',
      pt: 'Portuguese',
      de: 'German',
      nl: 'Dutch'
    }
  }
}
