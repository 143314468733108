import React from 'react'
import { TSvgProps } from './types'

const ExternalLinkIcon = ({ width = 18, height = 18, viewBox = '0 0 18 18' }: TSvgProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0204 0.734694C11.0204 0.328934 11.3493 0 11.7551 0H17.2653C17.6711 0 18 0.328934 18 0.734694V6.2449C18 6.65066 17.6711 6.97959 17.2653 6.97959C16.8595 6.97959 16.5306 6.65066 16.5306 6.2449V2.5084L7.68277 11.3562C7.39586 11.6432 6.93068 11.6432 6.64376 11.3562C6.35684 11.0693 6.35684 10.6041 6.64376 10.3172L15.4916 1.46939H11.7551C11.3493 1.46939 11.0204 1.14045 11.0204 0.734694ZM2.57143 4.22449C2.27915 4.22449 1.99884 4.3406 1.79217 4.54727C1.5855 4.75394 1.46939 5.03425 1.46939 5.32653V15.4286C1.46939 15.7209 1.5855 16.0012 1.79217 16.2078C1.99884 16.4145 2.27915 16.5306 2.57143 16.5306H12.6735C12.9657 16.5306 13.2461 16.4145 13.4527 16.2078C13.6594 16.0012 13.7755 15.7209 13.7755 15.4286V9.91837C13.7755 9.51261 14.1044 9.18367 14.5102 9.18367C14.916 9.18367 15.2449 9.51261 15.2449 9.91837V15.4286C15.2449 16.1106 14.974 16.7646 14.4917 17.2468C14.0095 17.7291 13.3555 18 12.6735 18H2.57143C1.88944 18 1.23539 17.7291 0.753154 17.2468C0.270918 16.7646 0 16.1106 0 15.4286V5.32653C0 4.64455 0.270917 3.99049 0.753154 3.50826C1.23539 3.02602 1.88944 2.7551 2.57143 2.7551H8.08163C8.48739 2.7551 8.81633 3.08404 8.81633 3.4898C8.81633 3.89556 8.48739 4.22449 8.08163 4.22449H2.57143Z"
      fill="#141414"
    />
  </svg>
)

export default ExternalLinkIcon
