export enum UrlActions {
  edit = 'edit',
  view = 'view',
  createNew = 'create-new',
  invalidAction = 'invalid-action'
}

export const globalVars = {
  zoneConfiguration: 'AdminPortalCMS.All.SuperAdmin',
  headerHeight: 56,
  defaultZone: 'CA',
  localStorageZoneKey: 'user_zone',
  localStorageStoreKey: 'user_store_id',
  localStorageClusterKey: 'user_cluster',
  localStorageUserPreferencesPartialKey: ':preferences',
  localStorageErrorBoundaryKey: 'error_boundary_count',
  localStorageRawZoneKey: 'user_raw_zone',
  localStorageFilesUploadsKey: 'files_uploads',
  localStorageFilesUploadsBeforeUnloadKey: 'files_uploads_errors',
  actionQueryParam: 'action',
  createNewAction: UrlActions.createNew,
  editAction: UrlActions.edit,
  viewAction: UrlActions.view,
  formDevtools: 'devtools'
}

export const MI_NEGOCIO_STORE_ID = '22222'

export const DEFAULT_FORM_BASE_NAME = 'form'

export default globalVars
