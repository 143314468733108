import { SkeletonScreen } from '@Components/templates/EditorPage/components/utils/SkeletonScreen'
import globalVars from '@Utils/constants'
import React, { useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const LoadingScreen = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const queries = useMemo(
    () => ({
      guid: searchParams.get('guid'),
      action: searchParams.get('action'),
      zoneQuery: searchParams.get('zone'),
      storeQuery: searchParams.get('store_id'),
      redirectedFromQuery: searchParams.get('redirected_from')
    }),
    [searchParams]
  )
  const { guid, action, zoneQuery, storeQuery, redirectedFromQuery } = queries

  const queriesString = `${zoneQuery ? `&zone=${zoneQuery}` : ''}${
    storeQuery ? `&store_id=${storeQuery}` : ''
  }${redirectedFromQuery ? `&redirected_from=${redirectedFromQuery}` : ''}`

  useEffect(() => {
    if (action === globalVars.viewAction) {
      navigate(
        `/banners-launch-list/view-launch/${guid}?action=${globalVars.viewAction}${queriesString}`
      )
      return
    }

    navigate(
      `/banners-launch-list/edit-launch/${guid}?action=${globalVars.editAction}${queriesString}`
    )
  }, [navigate, action, guid, queriesString])

  return <SkeletonScreen />
}
