import { RootState } from '@Redux/store'
import {
  CmsBaseUrl,
  EndpointFunction,
  QueryFn
} from '@Utils/getEndpoints/getEndpointsDEPRECATED.types'

type GetContentListV2 = { zone: string; storeId: string } & CmsBaseUrl

const getContentListV2: EndpointFunction = (builder) => ({
  mock: '',
  real: builder.query<ContentV2[], GetContentListV2>({
    queryFn: async ({ cmsBaseUrl }, { getState }, _extraOptions, fetch) => {
      try {
        const { application } = getState() as RootState
        const { zone } = application

        const url = `${cmsBaseUrl}/contentedit/v2/zone_${zone.toLowerCase()}/allpages.json`
        const {
          data: { data }
        } = (await fetch(url)) as QueryFn<ContentV2>

        return { data }
      } catch (error) {
        const { message } = error as Error
        return { error: { status: 500, data: message } }
      }
    },
    providesTags: ['Content']
  }),
  transformResponse: ({ data }: { data: ContentV2[] }) => data
})

export default getContentListV2
