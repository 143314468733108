import { colors } from '@Styles/theme/colors'
import Pagination from '@mui/material/Pagination'
import styled, { css } from 'styled-components'
import { PaginationProps } from './Pagination.types'

const ColorVariations = (color: 'primary' | 'secondary') => {
  return {
    primary: css`
      color: ${colors?.white};
      background-color: ${colors?.black[900]};
    `,
    secondary: css`
      color: ${colors?.white};
      background-color: ${colors?.grey[600]};
    `
  }[color]
}

export const StyledPagination = styled(Pagination)<PaginationProps>`
  && .MuiPaginationItem-page {
    color: ${colors.blue[500]};
  }

  && .Mui-selected {
    ${({ color }) => css`
      ${!!color && ColorVariations(color)}
    `};
  }
`
