import { ZoneConfiguration } from '@Contexts/ZonesContext/ZonesContext.types'

const zoneConfiguration = {
  aq: {
    label: 'Antarctica',
    features: {
      hasCustomer: true,
      hasPartnerStorefront: true
    }
  },
  ar: {
    label: 'Argentina',
    features: {
      hasCustomer: true,
      hasPartnerStorefront: true
    }
  },
  be: {
    label: 'Belgium',
    features: {
      hasCustomer: true
    }
  },
  bo: {
    label: 'Bolivia',
    features: {
      hasCustomer: true,
      hasPartnerStorefront: true
    }
  },
  br: {
    label: 'Brazil',
    features: {
      hasMiNegocio: true,
      hasCustomer: true,
      hasPartnerStorefront: true
    }
  },
  ca: {
    label: 'Canada',
    features: {
      hasCustomer: true,
      hasPartnerStorefront: true
    }
  },
  cl: {
    label: 'Chile',
    features: {
      hasCustomer: false,
      hasPartnerStorefront: true
    }
  },
  co: {
    label: 'Colombia',
    features: {
      hasMiNegocio: true,
      hasCustomer: true,
      hasPartnerStorefront: true
    }
  },
  de: {
    label: 'Germany',
    features: {
      hasCustomer: true
    }
  },
  do: {
    label: 'Dominican Republic',
    features: {
      hasMiNegocio: true,
      hasCustomer: true,
      hasPartnerStorefront: true
    }
  },
  ec: {
    label: 'Ecuador',
    features: {
      hasMiNegocio: true,
      hasCustomer: true,
      hasPartnerStorefront: true
    }
  },
  es: {
    label: 'Canary Islands',
    features: {
      hasCustomer: true
    }
  },
  gh: {
    label: 'Ghana',
    features: {
      hasCustomer: true
    }
  },
  gb: {
    label: 'Great Britain',
    features: {
      hasCustomer: true,
      hasPartnerStorefront: true
    }
  },
  hn: {
    label: 'Honduras',
    features: {
      hasMiNegocio: true,
      hasCustomer: true
    }
  },
  id: {
    label: 'Indonesia',
    features: {
      hasPartnerStorefront: true
    }
  },
  kr: {
    label: 'South Korea',
    features: {
      hasCustomer: true,
      hasMiNegocio: true
    }
  },
  mx: {
    label: 'Mexico',
    features: {
      hasMiNegocio: true,
      hasCustomer: true,
      hasPartnerStorefront: true
    }
  },
  nl: {
    label: 'Netherlands',
    features: {
      hasCustomer: true
    }
  },
  pa: {
    label: 'Panama',
    features: {
      hasMiNegocio: true,
      hasCustomer: true
    }
  },
  pe: {
    label: 'Peru',
    features: {
      hasMiNegocio: true,
      hasCustomer: true,
      hasPartnerStorefront: true
    }
  },
  ph: {
    label: 'Philippines',
    features: {
      hasPartnerStorefront: true
    }
  },
  py: {
    label: 'Paraguay',
    features: {
      hasCustomer: true
    }
  },
  sv: {
    label: 'El Salvador',
    features: {
      hasMiNegocio: true,
      hasCustomer: true
    }
  },
  tz: {
    label: 'Tanzania',
    features: {
      hasCustomer: true
    }
  },
  ug: {
    label: 'Uganda',
    features: {
      hasCustomer: true
    }
  },
  us: {
    label: 'United States',
    features: {
      hasAdvancedFields: true,
      hasCustomer: true
    }
  },
  uy: {
    label: 'Uruguay',
    features: {
      hasCustomer: true
    }
  },
  za: {
    label: 'South Africa',
    features: {
      hasMiNegocio: true,
      hasCustomer: true,
      hasPartnerStorefront: true
    }
  }
} as ZoneConfiguration

export default zoneConfiguration
