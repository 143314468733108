import { UseQuery } from '@Api/api.types'
import { useApplicationInfo } from '@Api/hooks/useApplicationInfo'
import { useBuildQueryKeys } from '@Api/hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '@Api/hooks/usePrepareHeaders'
import { queryFn } from '@Api/utils/queryFn'
import { useApi } from '@Src/shared/api/hooks/useApi'
import { useQuery } from '@tanstack/react-query'

export interface IMiNegocioAuthorsResponse {
  data: IMiNegocioAuthor[]
}
export interface IMiNegocioAuthor {
  id: number
  createdAt: string
  updatedAt: string | null
  updatedBy: string | null
  guid: string
  name: string
  isEnabled: boolean
}

export const useGetAuthorsQuery = ({
  queryKeys,
  options
}: UseQuery<IMiNegocioAuthorsResponse, Error>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()
  const { api } = useApi()

  const itemsListQueryKeys = useBuildQueryKeys([...queryKeys])

  const response = useQuery<IMiNegocioAuthorsResponse, Error>(
    itemsListQueryKeys,
    queryFn(api, `${cmsCoreBackend}/contentedit/zone_${zone.toLowerCase()}/metadata/authors`, {
      headers
    }),
    options
  )

  return response
}
