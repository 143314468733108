import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { ContentUpdatePayload, ContentUpdateResponse } from './types/contentPayload.types'

export async function updateContent(
  payload: ContentUpdatePayload,
  cmsCoreBackend: string,
  zone: string,
  headers: AxiosRequestConfig['headers'],
  api: AxiosInstance
): Promise<ContentUpdateResponse> {
  const { data } = await api({
    url: `${cmsCoreBackend}/contentedit/zone_${zone.toLowerCase()}/content`,
    method: 'PUT',
    data: payload,
    headers
  })

  return data
}
