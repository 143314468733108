import {
  CmsBaseUrl,
  Content,
  EndpointFunction,
  _Response
} from '@Utils/getEndpoints/getEndpointsDEPRECATED.types'

type DeleteContent = Pick<Content, 'guid'> & CmsBaseUrl & { zone: string; userEmail: string }

const deleteContent: EndpointFunction = (builder) => ({
  mock: '',
  real: builder.mutation<_Response, DeleteContent>({
    query: ({ cmsBaseUrl, guid, zone, userEmail }) => ({
      url: `${cmsBaseUrl}/contentedit/zone_${zone?.toLowerCase()}/page/withmodules/${guid}/${userEmail}`,
      method: 'DELETE'
    }),
    invalidatesTags: ['Content']
  })
})

export default deleteContent
