import { CmsBaseUrl, EndpointFunction } from '@Utils/getEndpoints/getEndpointsDEPRECATED.types'

interface ModuleData {
  url: string
}

export interface GameChildModule {
  updated_by_user_email: string
  guid: string
  schema_name: string
  module_name: string
  vendor_ids: string[]
  campaign_ids: string[]
  ddc_ids: string[]
  engagement_ids: string[]
  is_default_display: boolean
  module_data: Record<string, ModuleData>
  page_sort: number
  immutable_guid: string
}

export interface GamePayloadModules {
  updated_by_user_email: string
  guid: string
  immutable_guid: string
  schema_name: string
  module_name: string
  vendor_ids: string[]
  ddc_ids: string[]
  module_data: {
    child_modules: Omit<GameChildModule, 'campaign_ids' | 'module_data'>[]
  }
}

export interface AddGameBody {
  guid: string
  immutable_guid: string
  page_name: string
  page_template: string
  user_email: string
  launch_time: string | Date
  store_id: string
  modules: GamePayloadModules[]
}

type AddGame = { body: AddGameBody; zone: string; pagePath: string } & CmsBaseUrl

const addGame: EndpointFunction = (builder) => ({
  mock: '',
  real: builder.mutation<unknown, AddGame>({
    query: ({ cmsBaseUrl, zone, body, pagePath }) => ({
      url: `${cmsBaseUrl}/contentedit/zone_${zone.toLowerCase()}/page/${pagePath}`,
      method: 'PUT',
      body
    }),
    invalidatesTags: ['Games']
  })
})

export default addGame
