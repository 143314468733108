import { Box } from '@BarrelComponents'
import globalVars from '@Utils/constants'
import React from 'react'
import { StyledSpinner } from './Spinner.styles'
import { SpinnerProps } from './Spinner.types'

export const Spinner = ({ $additionalCss, ...props }: SpinnerProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: `calc(100vh - ${globalVars.headerHeight}px)`,
        minWidth: '100vw'
      }}
      $additionalCss={$additionalCss}
      className="mfe-spinner"
    >
      <StyledSpinner {...props} />
    </Box>
  )
}
