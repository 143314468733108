import { Image, Text } from '@BarrelComponents'
import pxToRem from '@Utils/pxToRem/pxToRem'
import { getZoneLabelByCode } from '@Utils/zoneUtils/zoneUtils'
import React, { useEffect, useState } from 'react'
import missingFlag from '../../../../public/images/flags/missing.png'
import { StyledCountryFlagWrapper } from './CountryFlag.styles'
import { CountryFlagProps } from './CountryFlag.types'

export const CountryFlag = ({
  direction = 'row',
  justifyContent = 'center',
  alignItems = 'center',
  spacing = pxToRem(12),
  $padding,
  zone,
  ...props
}: Omit<CountryFlagProps, 'children'>) => {
  const [countryInfo, setCountryInfo] = useState({ country: '', code: '' })
  const [flag, setFlag] = useState('')
  const [hasFlag, setHasFlag] = useState(true)

  useEffect(() => {
    const countryInfo = getZoneLabelByCode(zone)
    setCountryInfo({ country: countryInfo.label, code: countryInfo.code })
  }, [zone])

  useEffect(() => {
    ;(async () => {
      try {
        const flag = (await import(`../../../../public/images/flags/${zone}.png`))?.default
        setFlag(flag)
      } catch (error) {
        /* istanbul ignore next */
        setFlag(missingFlag)
        /* istanbul ignore next */
        setHasFlag(false)
      }
    })()
  }, [zone])

  return (
    <StyledCountryFlagWrapper
      {...props}
      direction={direction}
      justifyContent={justifyContent}
      alignItems={alignItems}
      spacing={spacing}
    >
      <Image
        src={flag}
        alt={countryInfo.country}
        $width={hasFlag ? pxToRem(18) : /* istanbul ignore next */ pxToRem(20)}
        $height={!flag ? pxToRem(13) : undefined}
      />
      <Text sx={{ opacity: 0.55 }}>{countryInfo.country}</Text>
    </StyledCountryFlagWrapper>
  )
}
