import { useFeatureTogglesContext } from '@Src/shared/contexts/FeatureTogglesContext/FeatureTogglesContext'
import { useZonesContext } from '@Src/shared/contexts/ZonesContext/ZonesContext'

export function useWithSidebarToggles() {
  const {
    mn_audience_selector_enabled: [isMnAudienceSelectorEnabled],
    deep_link_generator_enabled: [isDeepLinkGeneratorEnabled],
    monitoring_dashboard_enabled: [isMonitoringDashboardEnabled]
  } = useFeatureTogglesContext()

  const { isAudienceSegmentationEnabledForCurrentZone } = useZonesContext()

  return {
    isMnAudienceSelectorEnabled,
    isDeepLinkGeneratorEnabled,
    isMonitoringDashboardEnabled,
    isAudienceSegmentationEnabledForCurrentZone
  }
}
