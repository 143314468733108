import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ContentState, Page } from './contentSlice.types'

const initialState: ContentState = {
  pages: []
}

export const { actions, reducer: contentSliceReducer } = createSlice({
  name: 'content',
  initialState,
  reducers: {
    fetchPages: (state, action: PayloadAction<Page[]>) => {
      state.pages = action.payload
    }
  }
})

export const { fetchPages } = actions

export default contentSliceReducer
