import { useFileUploadContext } from '@Contexts/FileUploadContext/FileUploadContext'
import pxToRem from '@Src/shared/utils/pxToRem/pxToRem'
import { Card, TextButton } from '@hexa-ui/components'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React, { useEffect, useRef, useState } from 'react'

export const FilesUploadProgress = () => {
  const { uploadsInProgress, dispatchFileUploadAction, fileUploadReducerActions } =
    useFileUploadContext()

  const uploadsWithError = uploadsInProgress?.filter(({ error }) => error)
  const finishedUploads = uploadsInProgress?.filter(({ progress }) => progress === 100)
  let finishedUploadsPercentage = 0

  if (uploadsInProgress && uploadsInProgress.length > 0) {
    finishedUploadsPercentage = Math.round(
      (finishedUploads.length * 100) / uploadsInProgress.length
    )
  }

  const [open, setOpen] = useState(false)
  const uploadsRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const closeUploadsCard = (e: MouseEvent) => {
      const target = e.target as HTMLElement
      if (uploadsRef.current && uploadsRef?.current?.contains(target)) return

      setOpen(false)
    }

    window.addEventListener('click', closeUploadsCard)

    return () => {
      window.removeEventListener('click', closeUploadsCard)
    }
  }, [])

  if (!uploadsInProgress?.length) return null

  return (
    <Stack sx={{ position: 'relative' }} ref={uploadsRef}>
      <TextButton onClick={() => setOpen(!open)}>
        <Stack direction="row" alignItems="center" gap={pxToRem(8)}>
          <Typography variant="caption" color="text.secondary" sx={{ whiteSpace: 'nowrap' }}>
            Uploads in progress
          </Typography>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
              variant="determinate"
              value={finishedUploadsPercentage}
              color={
                uploadsWithError.length
                  ? 'error'
                  : finishedUploadsPercentage === 100
                  ? 'success'
                  : 'primary'
              }
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ fontSize: '11px' }}
              >{`${finishedUploadsPercentage}%`}</Typography>
            </Box>
          </Box>
        </Stack>
      </TextButton>
      {open && (
        <Card
          border="medium"
          elevated="medium"
          css={{
            position: 'absolute',
            inset: 'auto auto auto 0',
            top: 'calc(100% + 10px)',
            maxWidth: '390px',
            zIndex: 100
          }}
        >
          <Stack
            gap={pxToRem(10)}
            sx={{
              padding: pxToRem(10)
            }}
          >
            <Stack
              gap={pxToRem(10)}
              sx={{
                padding: pxToRem(10),
                maxHeight: '200px',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '3px',
                  backgroundColor: 'lightgray',
                  borderRadius: '10px'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'darkgray',
                  borderRadius: '10px'
                }
              }}
            >
              {uploadsInProgress.map((upload) => {
                const { fileName, progress, error } = upload
                return (
                  <Stack
                    key={fileName}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={pxToRem(8)}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      flex={1}
                      gap={pxToRem(8)}
                    >
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          width: '260px',
                          overflow: 'hidden'
                        }}
                      >
                        {fileName}
                      </Typography>

                      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress
                          variant="determinate"
                          value={progress}
                          size={36}
                          color={error ? 'error' : progress === 100 ? 'success' : 'primary'}
                        />
                        <Box
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            sx={{ fontSize: '10px' }}
                          >{`${progress}%`}</Typography>
                        </Box>
                      </Box>
                    </Stack>
                    <TextButton
                      css={{
                        marginLeft: 'auto',
                        visibility: progress === 100 || error ? 'visible' : 'hidden'
                      }}
                      onClick={(e) => {
                        e.stopPropagation()
                        dispatchFileUploadAction({
                          type: fileUploadReducerActions.remove,
                          payload: upload
                        })
                      }}
                    >
                      &times;
                    </TextButton>
                  </Stack>
                )
              })}
            </Stack>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{
                alignSelf: 'flex-start',
                background: 'none',
                border: 'none',
                paddingLeft: pxToRem(10),

                '&:hover': {
                  textDecoration: 'underline',
                  textUnderlineOffset: '3px'
                }
              }}
              component="button"
              onClick={() => dispatchFileUploadAction({ type: fileUploadReducerActions.clear })}
            >
              clear
            </Typography>
          </Stack>
        </Card>
      )}
    </Stack>
  )
}
