import { CmsBaseUrl, EndpointFunction } from '@Utils/getEndpoints/getEndpointsDEPRECATED.types'

type GetContentModulesV2 = { guid: string; zone: string } & CmsBaseUrl

const getContentModulesV2: EndpointFunction = (builder) => ({
  mock: '',
  real: builder.query<ModulesV2, GetContentModulesV2>({
    query: ({ cmsBaseUrl, guid, zone }) => {
      return `${cmsBaseUrl}/contentedit/page/zone_${zone.toLowerCase()}/${guid}`
    }
  })
})
export default getContentModulesV2
