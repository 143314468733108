import { AxiosInstance, AxiosRequestConfig } from 'axios'

interface MutationFn {
  api: AxiosInstance
  url: string
  method: 'PUT' | 'POST' | 'DELETE' | 'PATCH'
  config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'method'>
}

export const mutationFn = <T, D>({ api, url, method, config }: MutationFn) => {
  return ({ data }: { data?: D }): Promise<T> => {
    return api({
      url,
      data,
      method,
      ...config
    })
  }
}
