import ErrorIcon from '@mui/icons-material/ErrorOutline'
import React from 'react'
import { StyledFormHelperText } from './FormHelperText.styles'
import { FormHelperTextProps } from './FormHelperText.types'

export const FormHelperText = ({
  StartIcon = <ErrorIcon />,
  error,
  children,
  ...props
}: FormHelperTextProps) => {
  if (!error || !children) return null
  return (
    <StyledFormHelperText {...props} error={error} data-testid="formHelperTextId">
      {StartIcon && StartIcon}
      {children}
    </StyledFormHelperText>
  )
}
