import { BasePropsStyles } from '@Styles/sharedStyles'
import pxToRem from '@Utils/pxToRem/pxToRem'
import Dialog from '@mui/material/Dialog'
import styled from 'styled-components'
import { ModalProps } from './Modal.types'

export const StyledModal = styled(Dialog)<ModalProps>`
  && {
    div[role='dialog'] {
      ${BasePropsStyles};
      overflow: visible;
      max-height: ${({ $maxHeight }) => $maxHeight};
      border-radius: 24px;
      max-width: ${pxToRem(687)};
    }
  }
`
