import { ZoneStore } from '@Src/shared/contexts/ZonesContext/ZonesContext.types'
import { StoreFeatures } from '@Src/shared/utils/storeUtils/class/StoreFeatures'

export class Store {
  id: string

  name: string

  defaultVendorId?: string | null

  isAbiStore?: boolean

  features?: StoreFeatures

  constructor(zoneStore: ZoneStore) {
    this.id = zoneStore.id
    this.name = zoneStore.name
    this.isAbiStore = zoneStore.isAbiStore
    this.features = zoneStore.features
    this.defaultVendorId = zoneStore.defaultVendorId
  }

  static findById(stores: Store[], storeId: string): Store | null {
    return stores.find((store) => store.id === storeId) || null
  }
}
