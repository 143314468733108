import { useAppDispatch, useAppSelector } from '@Src/shared/redux/hooks/reduxHooks'
import { apiUtils, tagTypes } from '@Src/shared/redux/slices/api/apiSlice'
import { changeStore } from '@Src/shared/redux/slices/application/applicationSlice'
import globalVars, { MI_NEGOCIO_STORE_ID } from '@Src/shared/utils/constants'
import { TagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { sidebarCustomEvents } from '../../WithSidebar.utils'

export function useMiNegocioPageChangeEvent() {
  const dispatch = useAppDispatch()
  const { cluster, rawZone: zone } = useAppSelector((state) => state.application)

  const navigate = useNavigate()

  useEffect(() => {
    const onMiNegocioPageChange = async () => {
      await dispatch(changeStore(MI_NEGOCIO_STORE_ID))

      const clusterKey = cluster !== 'default' ? `:${cluster}` : ''
      const localStorageKey = `${globalVars.localStorageStoreKey}:${zone}${clusterKey}`
      localStorage.setItem(localStorageKey, MI_NEGOCIO_STORE_ID)

      await dispatch(apiUtils.invalidateTags(tagTypes as TagDescription<any>))

      navigate('/mi-negocio-content-list')
    }

    window.addEventListener(sidebarCustomEvents.miNegocioPageChange, onMiNegocioPageChange)
    return () => {
      window.removeEventListener(sidebarCustomEvents.miNegocioPageChange, onMiNegocioPageChange)
    }
  }, [cluster, dispatch, navigate, zone])
}
