import TableContainer from '@mui/material/TableContainer'
import styled from 'styled-components'
import { TableContainerElementProps } from './TableContainer.types'

export const StyledTableContainer = styled(TableContainer)<TableContainerElementProps>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  margin: auto;
  display: table;
  border-top: 1px solid;
  border-color: ${({ theme }) => theme.palette.divider};
  border-collapse: collapse;
`
