import { palette } from '@Styles/theme/colors'
import { typography } from '@Styles/theme/typography'
import Typography from '@mui/material/Typography'
import styled, { css } from 'styled-components'
import { TitleProps } from './Title.types'

const LineVariations = (
  lineHeight: 'lineHeightXs' | 'lineHeightMd' | 'lineHeightSm' | 'lineHeightXl' | 'lineHeightXxl'
) => {
  return {
    lineHeightXs: css`
      line-height: ${typography.lineHeightXs};
    `,
    lineHeightMd: css`
      line-height: ${typography.lineHeightMd};
    `,

    lineHeightSm: css`
      line-height: ${typography.lineHeightSm};
    `,

    lineHeightXl: css`
      line-height: ${typography.lineHeightXl};
    `,

    lineHeightXxl: css`
      line-height: ${typography.lineHeightXxl};
    `
  }[lineHeight]
}

const ColorVariations = (color: 'primary' | 'secondary' | 'disabled') => {
  return {
    primary: css`
      color: ${palette.text?.primary};
    `,

    secondary: css`
      color: ${palette.text?.secondary};
    `,

    disabled: css`
      color: ${palette.text?.disabled};
    `
  }[color]
}

export const StyledTitle = styled(Typography)<TitleProps>`
  && {
    ${({ $lineHeight, color }) => css`
      ${!!$lineHeight && LineVariations($lineHeight)}
      ${!!color && ColorVariations(color)}
    `};
  }
`
