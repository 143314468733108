import { EnvZonesStores } from '../shared/contexts/ZonesContext/ZonesContext.types'

const dtcConfig = {
  sit: {
    ar: { stores: [{ name: 'ABI - AR:DTC', isAbiStore: true, id: '12345' }] },
    bo: { stores: [{ name: 'ABI - BO:DTC', isAbiStore: true, id: '12345' }] },
    co: { stores: [{ name: 'ABI - CO:DTC', isAbiStore: true, id: '12345' }] },
    do: { stores: [{ name: 'ABI - DO:DTC', isAbiStore: true, id: '12345' }] },
    ec: { stores: [{ name: 'ABI - EC:DTC', isAbiStore: true, id: '12345' }] },
    hn: { stores: [{ name: 'ABI - HN:DTC', isAbiStore: true, id: '12345' }] },
    mx: { stores: [{ name: 'ABI - MX:DTC', isAbiStore: true, id: '12345' }] },
    pa: { stores: [{ name: 'ABI - PA:DTC', isAbiStore: true, id: '12345' }] },
    pe: { stores: [{ name: 'ABI - PE:DTC', isAbiStore: true, id: '12345' }] },
    py: { stores: [{ name: 'ABI - PY:DTC', isAbiStore: true, id: '12345' }] },
    sv: { stores: [{ name: 'ABI - SV:DTC', isAbiStore: true, id: '12345' }] },
    za: { stores: [{ name: 'ABI - ZA:DTC', isAbiStore: true, id: '12345' }] }
  },
  uat: {
    ar: { stores: [{ name: 'ABI - AR:DTC', isAbiStore: true, id: '12345' }] },
    bo: { stores: [{ name: 'ABI - BO:DTC', isAbiStore: true, id: '12345' }] },
    co: { stores: [{ name: 'ABI - CO:DTC', isAbiStore: true, id: '12345' }] },
    do: { stores: [{ name: 'ABI - DO:DTC', isAbiStore: true, id: '12345' }] },
    ec: { stores: [{ name: 'ABI - EC:DTC', isAbiStore: true, id: '12345' }] },
    hn: { stores: [{ name: 'ABI - HN:DTC', isAbiStore: true, id: '12345' }] },
    mx: { stores: [{ name: 'ABI - MX:DTC', isAbiStore: true, id: '12345' }] },
    pa: { stores: [{ name: 'ABI - PA:DTC', isAbiStore: true, id: '12345' }] },
    pe: { stores: [{ name: 'ABI - PE:DTC', isAbiStore: true, id: '12345' }] },
    py: { stores: [{ name: 'ABI - PY:DTC', isAbiStore: true, id: '12345' }] },
    sv: { stores: [{ name: 'ABI - SV:DTC', isAbiStore: true, id: '12345' }] },
    za: { stores: [{ name: 'ABI - ZA:DTC', isAbiStore: true, id: '12345' }] }
  },
  prod: {
    ar: { stores: [{ name: 'ABI - AR:DTC', isAbiStore: true, id: '12345' }] },
    bo: { stores: [{ name: 'ABI - BO:DTC', isAbiStore: true, id: '12345' }] },
    co: { stores: [{ name: 'ABI - CO:DTC', isAbiStore: true, id: '12345' }] },
    do: { stores: [{ name: 'ABI - DO:DTC', isAbiStore: true, id: '12345' }] },
    ec: { stores: [{ name: 'ABI - EC:DTC', isAbiStore: true, id: '12345' }] },
    hn: { stores: [{ name: 'ABI - HN:DTC', isAbiStore: true, id: '12345' }] },
    mx: { stores: [{ name: 'ABI - MX:DTC', isAbiStore: true, id: '12345' }] },
    pa: { stores: [{ name: 'ABI - PA:DTC', isAbiStore: true, id: '12345' }] },
    pe: { stores: [{ name: 'ABI - PE:DTC', isAbiStore: true, id: '12345' }] },
    py: { stores: [{ name: 'ABI - PY:DTC', isAbiStore: true, id: '12345' }] },
    sv: { stores: [{ name: 'ABI - SV:DTC', isAbiStore: true, id: '12345' }] },
    za: { stores: [{ name: 'ABI - ZA:DTC', isAbiStore: true, id: '12345' }] }
  }
} as EnvZonesStores

export default dtcConfig
