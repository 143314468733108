import { TranslationsObjectContext } from '@Src/shared/contexts/I18nContext/I18nContext.types'

export const getContentTypesAndStatusTranslations = (
  sharedTranslations: TranslationsObjectContext['shared']
) => {
  const {
    brandCarouselTypeText,
    guestHeroCarouselTypeText,
    heroCarouselTypeText,
    mobileBannersTypeText,
    streamlinedBannersTypeText,
    liveStatusText,
    scheduledStatusText,
    finishedStatusText,
    webviewLinkTypeText,
    noAudienceText
  } = sharedTranslations

  return {
    'brand-carousel': brandCarouselTypeText,
    'guest-hero-carousel': guestHeroCarouselTypeText,
    'hero-carousel': heroCarouselTypeText,
    'mobile-banners': mobileBannersTypeText,
    'streamlined-banners': streamlinedBannersTypeText,
    'web-view-': webviewLinkTypeText,
    live: liveStatusText,
    scheduled: scheduledStatusText,
    past: finishedStatusText,
    noAudience: noAudienceText
  }
}
