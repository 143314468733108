import { Toast as HexaToast } from '@hexa-ui/components'
import React from 'react'
import ReactDOM from 'react-dom'
import { BaseFunction, RenderToastInsideDOM, ToastContainerProps } from './Toast.types'

export const ToastContainer = () => {
  return (
    <div
      id="hexa-toast-container"
      data-testid="hexa-toast-container"
      style={{ position: 'absolute', top: '0', right: '0' }}
    />
  )
}

export const ToastInit = () => {
  const getContainerHTMLElement = () => {
    return document.getElementById('hexa-toast-container') as HTMLElement
  }

  const unmountToast = () => {
    const toastNode = getContainerHTMLElement()
    if (toastNode) ReactDOM.unmountComponentAtNode(toastNode)
  }

  const ToastContainer = (props: ToastContainerProps) => (
    <HexaToast.Provider>
      <HexaToast.Root {...props} open position="top-right" showCloseButton onClose={unmountToast} />
    </HexaToast.Provider>
  )

  const renderToastInsideDOM: RenderToastInsideDOM = (
    message,
    type,
    props,
    dismissAfter = 5000
  ) => {
    ReactDOM.render(
      <ToastContainer message={message} type={type} {...props} />,
      getContainerHTMLElement()
    )
    if (dismissAfter) setTimeout(unmountToast, dismissAfter)
  }

  const baseFunction: BaseFunction = (toastType) => {
    return (message, props, dismissAfter = 5000) => {
      renderToastInsideDOM(message, toastType, props, dismissAfter)
    }
  }

  const success = baseFunction('success')
  const info = baseFunction('info')
  const warning = baseFunction('warning')
  const error = baseFunction('error')
  const dismiss = () => unmountToast()

  return { success, info, warning, error, dismiss }
}
