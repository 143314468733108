import pxToRem from '@Utils/pxToRem/pxToRem'
import { Heading } from '@hexa-ui/components'
import React, { forwardRef } from 'react'
import { StyledBoxRow } from './DndRow.styles'
import type { DndRowProps } from './DndRow.types'

export const DndRow = forwardRef(
  ({ children, $number, $isDraggingOver, ...props }: DndRowProps, ref) => {
    return (
      <StyledBoxRow {...props} className={$isDraggingOver ? 'isActive' : ''} ref={ref}>
        <Heading size="H5" className="boxNumber" css={{ fontSize: pxToRem(16) }}>
          {($number || 0) + 1}
        </Heading>
        {children}
      </StyledBoxRow>
    )
  }
)
