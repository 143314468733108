import Quill from 'quill'
import { hexToRGB } from './registerColors'

const BACKGROUND_ICON_SELECTOR = '.ql-picker-label svg rect'
const COLOR_ICON_SELECTOR = '.ql-picker-label svg g path:last-child'

const removeSelectedClass = (dropdownClass: string): void => {
  const items = document.querySelectorAll<HTMLElement>(`${dropdownClass} .ql-picker-item`)
  items.forEach((item) => item.classList.remove('ql-selected'))
}

const highlightPicker = (element: Element, selectedBackground: string) => {
  const backgroundIcon = element.querySelector(BACKGROUND_ICON_SELECTOR) as HTMLElement
  const colorIcon = element.querySelector(COLOR_ICON_SELECTOR) as HTMLElement

  if (element.classList.contains('ql-background')) {
    backgroundIcon.style.fill = selectedBackground
    removeSelectedClass('.ql-background')
    return
  }

  colorIcon.style.fill = selectedBackground
  removeSelectedClass('.ql-color')
}

export const addHighlightColorListener = (quillInstance: Quill): void => {
  const backgroundDropdown = document.querySelector('.ql-background')
  const colorDropdown = document.querySelector('.ql-color')
  const dropdowns = [backgroundDropdown, colorDropdown]

  quillInstance.on('selection-change', (range) => {
    if (!range || !backgroundDropdown || !colorDropdown) return

    const { background, color } = quillInstance.getFormat(range.index, range.length)
    backgroundDropdown.setAttribute('data-value', hexToRGB(background || '#ffffff'))
    colorDropdown.setAttribute('data-value', hexToRGB(color || '#000000'))
  })

  dropdowns.forEach((dropdown) => {
    if (!dropdown) return

    const colors = dropdown.querySelectorAll('.ql-picker-item')
    colors.forEach((color) => {
      color.addEventListener('click', () => {
        const colorValue = color.getAttribute('data-value') as string
        highlightPicker(dropdown, colorValue)
      })
    })
  })

  const observer = new MutationObserver((mutations) => {
    mutations.forEach(({ target }) => {
      const element = target as HTMLElement
      const selectedBackground = element.getAttribute('data-value') as string

      highlightPicker(element, selectedBackground)
      const selectedItem = element.querySelector(
        `.ql-picker-item[data-value="${selectedBackground}"]`
      )

      if (selectedItem) setTimeout(() => selectedItem.classList.add('ql-selected'), 300)
    })
  })

  dropdowns.forEach((i) => i && observer.observe(i, { attributes: true }))
}
