import { colors } from '@Styles/theme/colors'
import { typography } from '@Styles/theme/typography'
import pxToRem from '@Utils/pxToRem/pxToRem'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import styled, { createGlobalStyle } from 'styled-components'
import { TimePickerProps } from './TimePicker.types'

export const TimePickerStyles = createGlobalStyle`
    &&& {
        .time-picker {
            .Mui-focused fieldset,
            .Mui-focused.MuiInputBase-formControl:hover fieldset {
                border-width: 1px !important;
                border-color: black !important;
            }
            
            .MuiInputBase-formControl:hover fieldset {
                border-color: rgba(0,0,0,0.15) !important;
            }

            fieldset {
                border-radius: ${`${pxToRem(8)} !important`};
                border-color: rgba(0,0,0,0.15) !important;
            }

            .Mui-focused.Mui-error fieldset,
            .Mui-error.MuiInputBase-formControl fieldset,
            .Mui-focused.Mui-error.MuiInputBase-formControl:hover fieldset,
            .Mui-error fieldset {
                border-color: rgb(201,32,29) !important;
            }

            .MuiOutlinedInput-root {
                height: ${`${pxToRem(40)} !important`}
            }
            
            input {
                height: ${`${pxToRem(20)} !important`};
                border-radius: ${`${pxToRem(8)} !important`};
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
        }
    }
`

export const StyledTimePicker = styled(TimePicker)<TimePickerProps>``
export const TimePickerContainer = styled.div`
  .MuiPickersToolbar-penIconButton {
    display: none;
  }

  .MuiTypography-root {
    font-size: ${pxToRem(14)};
    font-weight: ${typography.fontWeightBold};
    color: ${colors.black[900]};
  }

  .MuiTimePickerToolbar-root {
    border-bottom: ${`${pxToRem(1)} solid ${colors.grey[400]}`};
    padding: ${pxToRem(10)} ${pxToRem(24)};
  }

  .MuiGrid-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MuiClockPicker-arrowSwitcher {
    display: none;

    & + div {
      button {
        display: none;
      }
      & > div {
        background: ${colors.grey[300]};
      }
      div div ~ div {
        background: ${colors.white};
        div {
          background: ${colors.white};
          border: ${pxToRem(16)} solid ${colors.white};
        }
      }
    }
  }

  div div ~ div .Mui-selected {
    color: ${colors.black[900]};
  }

  .MuiTimePickerToolbar-dateTitleContainer {
    & > button {
      display: none;
    }
  }
  .MuiTimePickerToolbar-hourMinuteLabel {
    margin-left: auto;
  }

  .MuiTimePickerToolbar-ampmSelection {
    flex-direction: row;
    margin: 0;
    margin-left: 15%;

    span {
      font-size: ${pxToRem(14)};
      padding: ${pxToRem(4)};
      color: ${colors.grey[400]};
    }

    button {
      margin-left: ${pxToRem(8)};
      border-radius: 50%;

      &:hover {
        background-color: ${colors.grey[300]};
        border-radius: 50%;
        color: ${colors.black[900]};
        span,
        span.Mui-selected {
          color: ${colors.black[900]};
        }
      }
    }
  }
`
