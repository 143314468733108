import pxToRem from '@Utils/pxToRem/pxToRem'
import FormHelperText from '@mui/material/FormHelperText'
import styled from 'styled-components'
import { FormHelperTextProps } from './FormHelperText.types'

export const StyledFormHelperText = styled(FormHelperText)<FormHelperTextProps>`
  position: absolute;
  margin: 0;
  margin-top: ${pxToRem(2)};
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: ${pxToRem(14)};

  svg {
    margin-right: 4px;
    font-size: ${pxToRem(12)};
  }
`
