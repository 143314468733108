import pxToRem from '@Utils/pxToRem/pxToRem'
import { DatePicker } from '@mui/x-date-pickers'
import styled, { createGlobalStyle } from 'styled-components'
import { DatePickerProps } from './DatePicker.types'

export const DatePickerStyles = createGlobalStyle`
    &&& {
        .date-picker {

            .Mui-focused fieldset,
            .Mui-focused.MuiInputBase-formControl:hover fieldset {
                border-width: 1px !important;
                border-color: black !important;
            }
            
            .MuiInputBase-formControl:hover fieldset {
                border-color: rgba(0,0,0,0.15) !important;
            }
            
            fieldset {
                border-radius: ${`${pxToRem(8)} !important`};
                border-color: rgba(0,0,0,0.15) !important;
            }

            .Mui-focused.Mui-error fieldset,
            .Mui-error.MuiInputBase-formControl fieldset,
            .Mui-focused.Mui-error.MuiInputBase-formControl:hover fieldset,
            .Mui-error fieldset {
                border-color: rgb(201,32,29) !important;
            }

            .MuiOutlinedInput-root {
                height: ${`${pxToRem(40)} !important`};
            }

            input {
                height: ${`${pxToRem(20)} !important`};
                border-radius: ${`${pxToRem(8)} !important`};
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
        }
    }
`
export const StyledDatePicker = styled(DatePicker)<DatePickerProps>``
