const decodeJwt = (jwt: string) => {
  try {
    const base64Url = jwt.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const decocedBase64 = atob(base64)
      .split('')
      .map((item) => `%${`00${item.charCodeAt(0).toString(16)}`.slice(-2)}`)
    const jsonPayload = decodeURIComponent(decocedBase64.join(''))

    return JSON.parse(jsonPayload)
  } catch (error) {
    return 'Invalid Jwt'
  }
}

export default decodeJwt
