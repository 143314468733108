import { useAuthenticationService } from 'admin-portal-shared-services'
import Axios, { AxiosInstance } from 'axios'

export const useApi = (): { api: AxiosInstance } => {
  const initialAxios = Axios.create()

  const authentication = useAuthenticationService()

  authentication.enhancedAxios(
    initialAxios as unknown as Parameters<typeof authentication.enhancedAxios>[0]
  )

  return { api: initialAxios }
}
