import { apiUtils, tagTypes } from '@Redux/slices/api/apiSlice'
import { TagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { InvalidateAndUpdateTagsProps } from '../ContentList.types'

export const invalidateAndUpdateTags = (props: InvalidateAndUpdateTagsProps) => {
  const { dispatch, rawZone, storeId, cmsBaseUrl } = props
  dispatch(apiUtils.invalidateTags(tagTypes as TagDescription<any>))
  dispatch(
    apiUtils.updateQueryData(
      'getContentListV2',
      { zone: rawZone, storeId: String(storeId), cmsBaseUrl },
      (result: any) => result
    )
  )
  dispatch(
    apiUtils.updateQueryData('getUsers', { zone: rawZone, cmsBaseUrl }, (result: any) => result)
  )
  dispatch(
    apiUtils.updateQueryData(
      'getContentTypes',
      { cmsBaseUrl, storeId: String(storeId) },
      (result: any) => result
    )
  )
}
