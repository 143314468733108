import { EndpointFunction } from '@Utils/getEndpoints/getEndpointsDEPRECATED.types'
import { GetVendorsParams } from './getVendors.types'

const getVendors: EndpointFunction = (builder) => ({
  mock: '',
  real: builder.query<GetVendors, GetVendorsParams>({
    query: ({ cmsBaseUrl, zone }) => ({
      url: `${cmsBaseUrl}/contentedit/vendors/zone_${zone.toLowerCase()}`,
      method: 'GET',
      headers: { zone }
    }),
    providesTags: ['Vendors']
  })
})

export default getVendors
