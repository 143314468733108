import { palette } from '@Styles/theme/colors'
import TextField from '@mui/material/TextField'
import styled from 'styled-components'
import { TextFieldProps } from './TextField.types'

export const StyledTextField = styled(TextField)<TextFieldProps>`
  & .MuiInputBase-input {
    padding: ${({ $padding }) => $padding};
    background: #ffffff;

    &::placeholder {
      color: ${palette.grey![600]};
      opacity: 1;
    }
  }
`
