import { useZonesContext } from '@Src/shared/contexts/ZonesContext/ZonesContext'
import { useGetEnv } from '@Src/shared/hooks/useGetEnv/useGetEnv'
import { useAppSelector } from '@Src/shared/redux/hooks/reduxHooks'
import globalVars, { MI_NEGOCIO_STORE_ID } from '@Src/shared/utils/constants'
import { getStoreDefaultVendorId } from '@Src/shared/utils/getStoreDefaultVendorId/getStoreDefaultVendorId'
import { isInDTC } from '@Src/shared/utils/isInDTC/isInDTC'
import { zonesWithMiNegocio } from '@Src/shared/utils/zoneUtils/zoneUtils'
import type { Zone } from '@Src/types/zone'
import { useSearchParams } from 'react-router-dom'
import type { ItemsParams } from '../useSidebarItems/useSidebarItems.types'
import { useWithSidebarToggles } from '../useWithSidebarToggles/useWithSidebarToggles'

export function useSidebarItemsParams(): ItemsParams {
  const {
    isAudienceSegmentationEnabledForCurrentZone,
    isDeepLinkGeneratorEnabled,
    isMnAudienceSelectorEnabled,
    isMonitoringDashboardEnabled
  } = useWithSidebarToggles()

  const [search] = useSearchParams()

  const { storeId, zone, userRole } = useAppSelector(({ application, user }) => ({
    storeId: String(application.storeId),
    zone: application.rawZone as Zone,
    userRole: user.roles
  }))

  const hasZoneConfiguration = userRole?.find((role) => role === globalVars.zoneConfiguration)

  const env = useGetEnv()

  const vendorId = getStoreDefaultVendorId({
    zone,
    storeId,
    env
  })

  const isDTC = isInDTC(window.location.origin)

  const { storesWithLabel } = useZonesContext()
  const ABIStore = storesWithLabel?.find((store) => store.isAbiStore)
  const partnerStores = storesWithLabel?.filter(
    (store) => !store.isAbiStore && store.value !== MI_NEGOCIO_STORE_ID
  )

  return {
    hasDeepLinkGenerator: !isDTC && isDeepLinkGeneratorEnabled,
    hasMonitoringDashboard: !isDTC && isMonitoringDashboardEnabled,
    hasABIStore: ABIStore?.isAbiStore || false,
    hasPartnerStore: !!partnerStores && partnerStores?.length > 0,
    hasMiNegocio: !isDTC && zonesWithMiNegocio().includes(zone),
    hasAudienceSegmentation:
      !isDTC && (isMnAudienceSelectorEnabled || isAudienceSegmentationEnabledForCurrentZone),
    hasZoneConfiguration: !!hasZoneConfiguration,
    storeId,
    action: search.get('action') || '',
    zone,
    vendorId: vendorId || ''
  }
}
