import { BasePropsStyles } from '@Styles/sharedStyles'
import { colors } from '@Styles/theme/colors'
import Select from '@mui/material/Select'
import styled from 'styled-components'
import { SelectProps } from './Select.types'

export const StyledSelect = styled(Select)<Partial<SelectProps>>`
  ${BasePropsStyles};
  padding: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;

  .MuiSelect-select {
    padding: ${({ $padding }) => $padding};
    padding-left: ${({ $paddingLeft }) => $paddingLeft};
    padding-right: ${({ $paddingRight }) => $paddingRight};
    padding-top: ${({ $paddingTop }) => $paddingTop};
    padding-bottom: ${({ $paddingBottom }) => $paddingBottom};
    color: ${({ value, defaultValue }) => {
      const checkValue = !value || value === defaultValue
      return checkValue && colors.grey[600]
    }};
  }

  input[disabled] {
    opacity: 0;
  }
`
