import { Stack, Text } from '@BarrelComponents'
import pxToRem from '@Utils/pxToRem/pxToRem'
import { Edit2 } from '@hexa-ui/icons'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import FileDownloadDoneOutlinedIcon from '@mui/icons-material/FileDownloadDoneOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import ReactDropzone from 'react-dropzone'
import { ThemeContext } from 'styled-components'
import { toast } from '../../../App'
import { StyledDropzone } from './Dropzone.styles'
import { DropzoneEvents, DropzoneProps } from './Dropzone.types'

const validator: DropzoneProps['validatorFn'] = (accept) => (file) => {
  const { type } = file
  const [fileType, fileExtension] = type.split('/')
  const isFileAccepted = accept.find((item) => {
    return item.startsWith(fileType) && (item.endsWith(fileExtension) || item.endsWith('*'))
  })

  if (isFileAccepted) return null
  return {
    code: 'file-type-not-allowed',
    message: 'file type not allowed'
  }
}

export const Dropzone = ({
  $height = pxToRem(180),
  $width = pxToRem(640),
  justifyContent = 'center',
  alignItems = 'center',
  className = '',
  text,
  accept = ['image/*'],
  maxSize,
  validatorFn = validator,
  defaultFilePreview,
  sx,
  file,
  setFile,
  $hexaUI,
  buttonText = 'Browse Files',
  maxFileSize = '3MB',
  alwaysShowDefaultFilePreview,
  ...props
}: DropzoneProps) => {
  const [filePreview, setFilepreview] = useState<string>('')
  const theme = useContext(ThemeContext)

  useEffect(() => () => URL.revokeObjectURL(filePreview), [filePreview])

  const onDrop: DropzoneEvents<'onDrop'> = useCallback(
    ([file]) => {
      try {
        URL.revokeObjectURL(filePreview)
        setFilepreview('')
        setFile(null)
        const { type } = file
        if (type.startsWith('image')) {
          const fileURL = URL.createObjectURL(file)
          setFilepreview(fileURL)
        }
        return
      } catch (error) {
        return null
      } finally {
        setFile(file)
      }
    },
    [filePreview, setFile]
  )

  const onDropRejected: DropzoneEvents<'onDropRejected'> = useCallback(
    (_fileRejections) => {
      toast.error(`Maximum file size limit of ${maxFileSize} exceeded`)
    },
    [maxFileSize]
  )

  return (
    <ReactDropzone
      onDrop={onDrop}
      onDropRejected={onDropRejected}
      validator={validatorFn(accept)}
      accept={accept}
      multiple={false}
      maxSize={maxSize}
    >
      {({ getRootProps, getInputProps }) => {
        const { ref, ...rootProps } = getRootProps()

        return (
          <StyledDropzone
            {...props}
            $hexaUI={$hexaUI}
            className={`${className} styled-dropzone ${
              file && !filePreview && !defaultFilePreview ? 'without-preview' : ''
            }`}
            itemRef={ref}
            {...rootProps}
            $height={$height}
            $width={$width}
            justifyContent={justifyContent}
            alignItems={alignItems}
            sx={{
              ...sx,
              backgroundImage: `url("${
                alwaysShowDefaultFilePreview
                  ? defaultFilePreview
                  : filePreview || defaultFilePreview
              }")`,
              ...((filePreview || defaultFilePreview) &&
                $hexaUI && {
                  border: `1px solid ${theme.palette.grey?.[200]} !important`
                })
            }}
            aria-label="file uploader"
          >
            <input {...getInputProps({ 'aria-hidden': true })} />
            {!filePreview && !defaultFilePreview && !file && !$hexaUI && <FileUploadOutlinedIcon />}
            {!filePreview && !defaultFilePreview && !file && <Text>{text}</Text>}
            {!filePreview && !defaultFilePreview && !file && $hexaUI && (
              <Text
                sx={{
                  fontWeight: '600 !important',
                  color: 'black !important',
                  marginTop: `${pxToRem(24)} !important`,
                  border: '1px solid black',
                  borderRadius: pxToRem(20),
                  padding: `${pxToRem(8)} ${pxToRem(16)}`
                }}
              >
                {buttonText}
              </Text>
            )}
            {file && (
              <Stack className="edit-icon" justifyContent="center" alignItems="center">
                {$hexaUI ? <Edit2 /> : <EditOutlinedIcon />}
              </Stack>
            )}
            {file && !filePreview && !defaultFilePreview && <FileDownloadDoneOutlinedIcon />}
            {file && !filePreview && !defaultFilePreview && <Text>{file.name}</Text>}
          </StyledDropzone>
        )
      }}
    </ReactDropzone>
  )
}
