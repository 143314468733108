import { Box } from '@Src/components/BarrelComponents'
import pxToRem from '@Src/shared/utils/pxToRem/pxToRem'
import React, { ComponentProps } from 'react'
import { ApplicationSidebar } from './components/ApplicationSidebar/ApplicationSidebar'

export function WithSidebar<T extends (props: any) => JSX.Element>(Component: T) {
  return function ComponentWithSidebar(props: ComponentProps<T>) {
    return (
      <Box
        id="with-sidebar-wrapper"
        sx={{
          paddingLeft: pxToRem(104)
        }}
      >
        <ApplicationSidebar />
        <Component {...props} />
      </Box>
    )
  }
}
