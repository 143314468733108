import { useSidebar, type SidebarUtilitiesItem } from 'admin-portal-shared-services'
import { useState } from 'react'
import { useSidebarItems } from '../../hooks/useSidebarItems/useSidebarItems'

export function ApplicationSidebar() {
  const { sidebarItems } = useSidebarItems()
  const [sidebarUtils] = useState<SidebarUtilitiesItem[]>(() => [])

  useSidebar({
    items: sidebarItems,
    utils: sidebarUtils
  })

  return null
}
