import React from 'react'
import { StyledTooltip } from './Tooltip.styles'
import { TooltipProps } from './Tooltip.types'

export const Tooltip = ({ className, children, ...props }: TooltipProps) => {
  // The tooltip needs to apply DOM event listeners to its child element. If the child is a custom React element, you need to make sure that it spreads its props to the underlying DOM element.
  // eslint-disable-next-line react/no-unstable-nested-components
  const Children = React.forwardRef((props, ref) => {
    return (
      <div
        {...props}
        ref={ref as React.RefObject<HTMLDivElement>}
        style={{ width: 'fit-content' }}
        className={className}
      >
        {children}
      </div>
    )
  })

  return (
    <StyledTooltip {...props} classes={{ popper: className }} className={className}>
      <Children />
    </StyledTooltip>
  )
}
