import { palette } from '@Styles/theme/colors'
import pxToRem from '@Utils/pxToRem/pxToRem'
import styled, { css } from 'styled-components'
import {
  ColoredStyledStatusIndicatorTextProps,
  StatusIndicatorProps,
  StatusIndicatorPropsCircle
} from './StatusIndicator.types'

enum StatusColors {
  scheduledIcon = '#0F7FFA',
  scheduledText = '#0B59AF',
  liveIcon = '#31BD54',
  liveText = '#22843B',
  finishedIcon = '#999999',
  finishedText = '#999999'
}

const typeTextColors = {
  live: StatusColors.liveText,
  scheduled: StatusColors.scheduledText,
  past: StatusColors.finishedText,
  new: palette.blue?.[600],
  active: palette.success?.dark,
  inactive: palette.black?.[700]
}

const typeIconColors = {
  live: StatusColors.liveIcon,
  scheduled: StatusColors.scheduledIcon,
  past: StatusColors.finishedIcon,
  new: palette.blue?.[600],
  active: palette.success?.main,
  inactive: palette.black?.[700]
}

export const StyledStatusIndicator = styled.div<Omit<StatusIndicatorProps, 'text'>>`
  display: flex;
  align-items: center;
  text-transform: capitalize;
`

export const StyledStatusIndicatorText = styled.span`
  line-height: 1;
  font-size: ${pxToRem(14)};
  color: #000;
`

export const ColoredStyledStatusIndicatorText = styled.span<ColoredStyledStatusIndicatorTextProps>`
  line-height: 1;
  font-size: ${pxToRem(14)};
  font-weight: 500;
  ${({ type }) => css`
    color: ${typeTextColors[type]};
  `};
`

export const StyledStatusIndicatorCircle = styled.span<StatusIndicatorPropsCircle>`
  display: block;
  content: '';
  width: ${pxToRem(8)};
  height: ${pxToRem(8)};
  border-radius: 100%;
  margin-right: ${pxToRem(8)};
  opacity: 0.9;
  transform: capitalize;

  ${({ type }) => css`
    background-color: ${typeIconColors[type]};
  `};
`
