import { CmsBaseUrl, EndpointFunction } from '@Utils/getEndpoints/getEndpointsDEPRECATED.types'

interface GameModuleData {
  url: string
  start_time?: string
  end_time?: string
}

interface GameChildModule extends GameModule {
  vendor_ids: string[]
  ddc_ids: string[]
  campaign_ids: string[]
  engagement_ids: string[]
  is_default_display: boolean
  module_data: Record<string, GameModuleData>
}

interface GameModule {
  comment: null
  created_at: string
  created_by_guid: string
  created_by_user_email: string
  deleted_at: string
  deleted_by_user_email: string
  guid: string
  immutable_guid: string
  id: number
  is_shared: boolean
  launch_time: string
  module_name: string
  page_sort: number
  schema_name: string
  updated_at: string
  updated_by_guid: string
  updated_by_user_email: string
  zone: string
  child_modules: GameChildModule[]
}

export interface GameModules {
  campaign_ids: string[]
  created_at: string
  created_by_guid: string
  created_by_user_email: string
  deleted_at: string
  deleted_by_user_email: string
  engagement_ids: string[]
  guid: string
  immutable_guid: string
  id: number
  is_cascade: boolean
  launch_time: string
  page_data: null
  page_name: string
  page_path: string
  page_template: string
  store_ids: string[]
  updated_at: string
  updated_by_guid: string
  updated_by_user_email: string
  vendors_ids: string[]
  zone: string
  modules: GameModule[]
  status: string
}

type GetGamesParams = { zone: string; storeId: string; guid: string } & CmsBaseUrl

const getGame: EndpointFunction = (builder) => ({
  mock: '',
  real: builder.query<GameModules, GetGamesParams>({
    query: ({ cmsBaseUrl, zone, guid }) => {
      return `${cmsBaseUrl}/contentedit/page/zone_${zone.toLowerCase()}/${guid}`
    },
    providesTags: ['Games']
  })
})

export default getGame
