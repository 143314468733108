import { RootState } from '@Redux/store'
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAuthUserCountry } from '@Utils/auth/getAuthUserCountry/getAuthUserCountry'
import getEndpoints from '@Utils/getEndpoints/getEndpointsDEPRECATED'
import { Builder } from '@Utils/getEndpoints/getEndpointsDEPRECATED.types'
import fetch from 'isomorphic-fetch'
import { v4 } from 'uuid'

export const tagTypes = [
  'Content',
  'Zones',
  'ContentTypes',
  'Users',
  'Modules',
  'Locales',
  'Vendors',
  'DDCs',
  'Games'
] as const

export const prepareHeaders = (
  headers: Headers,
  { getState }: Pick<BaseQueryApi, 'getState' | 'endpoint' | 'type' | 'forced'>
) => {
  const isMockServer = !!process.env.API_DEV_HOST && process.env.USE_MOCK_ENDPOINT === 'true'

  if (isMockServer) return headers

  const { Authorization, country } = getAuthUserCountry()
  const { application } = getState() as RootState
  const { zone, storeId, cluster } = application

  if (Authorization) headers.set('Authorization', Authorization)
  const zoneHeader = `${zone.toLowerCase()}${
    !cluster || cluster === 'default' ? '' : `-${cluster}`
  }`

  headers.set('country', country)
  headers.set('requestTraceId', v4())
  headers.set('zone', zoneHeader)
  headers.set('store_id', String(storeId))

  return headers
}

export const endpoints = (builder: Builder) => {
  const endpoints = getEndpoints(builder)
  return { ...endpoints }
}

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    fetchFn: fetch,
    prepareHeaders
  }),
  tagTypes,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  endpoints
})

export const {
  endpoints: apiEndpoints,
  util: apiUtils,
  useDeleteContentMutation,
  useGetContentTypesQuery,
  useLazyGetContentTypesQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetLocalesQuery,
  useLazyGetLocalesQuery,
  useImageUploadMutation,
  useGetVendorsQuery,
  useLazyGetVendorsQuery,
  useGetDDCListQuery,
  useLazyGetDDCListQuery,
  useGetGameQuery,
  useLazyGetGameQuery,
  useAddGameMutation,
  // v2 endpoints
  useAddContentV2Mutation,
  useGetContentModulesV2Query,
  useLazyGetContentModulesV2Query,
  useGetContentListV2Query,
  useLazyGetContentListV2Query
} = apiSlice
