import { createOptions } from './createOptions'

export const parseToolbarContent = (toolbarOptionsGroups: Array<any[]>) => {
  const parsedToolbarContent = toolbarOptionsGroups
    .map((optionsGroup) => {
      const groupContent = optionsGroup
        .map((option) => {
          if (typeof option === 'string') {
            return `<button class="ql-${option}"></button>`
          }

          const [key, value] = Object.entries(option)[0]

          if (typeof value === 'string') {
            return `<button class="ql-${key}" value="${value}"></button>`
          }

          return `
                <select class="ql-${key}">
                    <option selected></option>
                    ${createOptions(value as string[])}
                </select>
            `
        })
        .join('\n')

      return `
            <span class="ql-formats">
                ${groupContent}
            </span>
        `
    })
    .join('\n')

  return parsedToolbarContent
}
