import { Environment } from '@Src/types/environment'
import { Zone } from '@Src/types/zone'

export type Cluster = 'global' | 'europe' | 'us' | 'dtc'

type Env = {
  [env in Environment]: Cluster
}

type ZoneCluster = {
  [zone in Zone]: Env
}

export const zoneCluster: ZoneCluster = {
  aq: { sit: 'global', uat: 'europe', prod: 'europe' },
  ar: { sit: 'global', uat: 'global', prod: 'global' },
  be: { sit: 'global', uat: 'europe', prod: 'europe' },
  bo: { sit: 'global', uat: 'global', prod: 'global' },
  br: { sit: 'global', uat: 'global', prod: 'global' },
  ca: { sit: 'global', uat: 'global', prod: 'global' },
  cl: { sit: 'global', uat: 'global', prod: 'global' },
  co: { sit: 'global', uat: 'global', prod: 'global' },
  de: { sit: 'global', uat: 'europe', prod: 'europe' },
  do: { sit: 'global', uat: 'global', prod: 'global' },
  ec: { sit: 'global', uat: 'global', prod: 'global' },
  es: { sit: 'global', uat: 'europe', prod: 'europe' },
  gh: { sit: 'global', uat: 'global', prod: 'global' },
  gb: { sit: 'global', uat: 'europe', prod: 'europe' },
  hn: { sit: 'global', uat: 'global', prod: 'global' },
  id: { sit: 'global', uat: 'europe', prod: 'europe' },
  kr: { sit: 'global', uat: 'global', prod: 'global' },
  mx: { sit: 'global', uat: 'global', prod: 'global' },
  nl: { sit: 'global', uat: 'europe', prod: 'europe' },
  pa: { sit: 'global', uat: 'global', prod: 'global' },
  pe: { sit: 'global', uat: 'global', prod: 'global' },
  ph: { sit: 'global', uat: 'europe', prod: 'europe' },
  py: { sit: 'global', uat: 'global', prod: 'global' },
  sv: { sit: 'global', uat: 'global', prod: 'global' },
  tz: { sit: 'global', uat: 'global', prod: 'global' },
  ug: { sit: 'global', uat: 'global', prod: 'global' },
  us: { sit: 'global', uat: 'us', prod: 'us' },
  uy: { sit: 'global', uat: 'global', prod: 'global' },
  za: { sit: 'global', uat: 'global', prod: 'global' }
}
