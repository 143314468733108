import { useMutation } from '@tanstack/react-query'
import { UseMutation } from '../api.types'
import { useApi } from '../hooks/useApi'
import { useApplicationInfo } from '../hooks/useApplicationInfo'
import { useBuildQueryKeys } from '../hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'

export interface UseDeleteItemMutationFn {
  guid: string
  userEmail: string
}

export const useDeleteItemMutation = ({
  mutationKeys,
  options
}: UseMutation<void, Error, UseDeleteItemMutationFn>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()
  const { api } = useApi()

  const deleteItemKeys = useBuildQueryKeys(mutationKeys)

  const mutation = useMutation<void, Error, UseDeleteItemMutationFn>(deleteItemKeys, {
    mutationFn: ({ guid, userEmail }: UseDeleteItemMutationFn) => {
      return api({
        url: `${cmsCoreBackend}/contentedit/zone_${zone?.toLowerCase()}/page/withmodules/${guid}/${userEmail}`,
        method: 'DELETE',
        headers
      })
    },
    ...options
  })

  return mutation
}
