import b2bConfig from '@Configuration/b2bStoreConfiguration'
import dtcConfig from '@Configuration/dtcStoreConfiguration'
import { useTranslationsContext } from '@Contexts/I18nContext/I18nContext'
import { CountriesTranslations } from '@Contexts/I18nContext/I18nContext.types'
import { useGetEnv } from '@Hooks/useGetEnv/useGetEnv'
import { useAppSelector } from '@Redux/hooks/reduxHooks'
import { Zone } from '@Src/types/zone'
import globalVars from '@Utils/constants'
import getLocalStorage from '@Utils/getLocalStorage/getLocalStorage'
import { isInDTC } from '@Utils/isInDTC/isInDTC'
import { isInUS } from '@Utils/isInUS/isInUS'
import { getZoneLabelByCode } from '@Utils/zoneUtils/zoneUtils'
import React, { createContext, useContext, useMemo, useState } from 'react'
import { useFilteredUserActiveStores } from './hooks/useFilteredUserActiveStores'
import { IZonesContext, ZonesProviderProps, ZoneStore } from './ZonesContext.types'

const HIGHER_ENVIRONMENTS = ['uat', 'prod']

const ZonesContext = createContext<IZonesContext>({} as IZonesContext)

const ZonesProvider = ({ children }: ZonesProviderProps) => {
  const { origin } = window.location
  const isUsingDTC = isInDTC(origin)
  const isUsingUS = isInUS(origin)

  const env = useGetEnv()

  const filterCountries = (country: string) => {
    const isUSCountry = country.toLowerCase() === 'us'
    if (!HIGHER_ENVIRONMENTS.includes(env)) return true
    return !(isUSCountry && !isUsingUS)
  }

  const { zone, storeId, countries } = useAppSelector(({ user, application }) => ({
    countries: user.supportedCountries?.filter(filterCountries),
    zone: application.zone as Zone,
    storeId: application.storeId
  }))

  const [zonesSelect, setZonesSelect] = useState('')
  const storedCluster = getLocalStorage(globalVars.localStorageClusterKey, 'default')
  const [cluster, setCluster] = useState<string | undefined>(storedCluster)

  const {
    translations: { shared },
    preferredLanguage: { country: countryCode }
  } = useTranslationsContext()
  const country = Number.isNaN(Number(countryCode)) ? countryCode : 'us'

  const storesConfig = useMemo(() => {
    if (isUsingDTC) {
      return dtcConfig[env]
    }
    return b2bConfig[env]
  }, [isUsingDTC, env])

  const isAudienceSegmentationEnabledForCurrentZone = storesConfig[zone]?.stores.some((store) => {
    return (
      store.features?.hasAudienceSegmentation ||
      store.features?.hasAudienceSegmentationWithVendorsList
    )
  })

  const getIsAudienceSegmentationEnabled = () => {
    const store = storesConfig[zone]?.stores.find((store: ZoneStore) => store.id === storeId)
    return (
      !!store?.features?.hasAudienceSegmentation ||
      !!store?.features?.hasAudienceSegmentationWithVendorsList
    )
  }

  const isAudienceSegmentationEnabled = getIsAudienceSegmentationEnabled()

  const filteredUserActiveStores = useFilteredUserActiveStores()

  const zonesWithLabel = useMemo(() => {
    if (!countries) return

    const mappedCountries = countries?.map((country) => {
      const { label: countryLabel } = getZoneLabelByCode(country)
      const translatedCountry = shared.countries?.[country.toLowerCase() as CountriesTranslations]
      return {
        label: translatedCountry || countryLabel,
        value: `${country}:${country}${isUsingDTC ? ':dtc' : ''}`
      }
    })

    const alphabeticallyOrderedCountries = Array.from(mappedCountries).sort((a, b) =>
      a.label.localeCompare(b.label, country, { sensitivity: 'base' })
    )
    return alphabeticallyOrderedCountries
  }, [countries, isUsingDTC, shared.countries, country])

  const storesWithLabel = useMemo(() => {
    if (!zonesSelect || !Object.entries(storesConfig).length) return

    const zone = zonesSelect.toLowerCase().split(':')[0]

    const stores = storesConfig[zone as Zone]?.stores
      ?.filter((store: ZoneStore) => {
        if (store.features?.hasRestrictedStore) {
          return filteredUserActiveStores?.includes(store.id)
        }
        return true
      })
      .map(({ name, id, isAbiStore }: { name: string; id: string; isAbiStore?: boolean }) => {
        return {
          label: name,
          isAbiStore: !!isAbiStore,
          value: id
        }
      })

    const alphabeticallyOrderedStores = Array.from(stores || []).sort((a, b) =>
      a?.label?.localeCompare(b?.label, country, { sensitivity: 'base' })
    )

    return alphabeticallyOrderedStores
  }, [zonesSelect, storesConfig, filteredUserActiveStores, country])

  const contextValue = useMemo(
    () => ({
      zonesWithLabel,
      storesWithLabel,
      vendors: storesConfig,
      supportedCountries: countries,
      zonesSelect,
      setZonesSelect,
      cluster,
      setCluster,
      isAudienceSegmentationEnabled,
      isAudienceSegmentationEnabledForCurrentZone
    }),
    [
      countries,
      storesWithLabel,
      storesConfig,
      zonesSelect,
      zonesWithLabel,
      cluster,
      setCluster,
      isAudienceSegmentationEnabled,
      isAudienceSegmentationEnabledForCurrentZone
    ]
  )

  return <ZonesContext.Provider value={contextValue}>{children}</ZonesContext.Provider>
}

export default ZonesProvider

export const useZonesContext = () => useContext(ZonesContext)
