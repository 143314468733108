import { enUS as baseTranslationsObject } from '@I18n/languages/us/en'
import { usePreferredLanguageV2 } from 'admin-portal-shared-services'
import axios from 'axios'
import React, { createContext, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CheckLanguageAvailability,
  DetectSourceLanguage,
  GetAvailableLanguages,
  ITranslationsContext,
  Translate,
  TranslateDynamicText,
  TranslateDynamically,
  TranslationErrorObject,
  TranslationSuccessObject,
  TranslationsObjectContext,
  TranslationsProviderProps
} from './I18nContext.types'

const TranslationsContext = createContext<ITranslationsContext>({} as ITranslationsContext)
const interpolationRegExp = /{{\w+}}/g
export const translationAPI = axios.create({
  baseURL: 'https://libretranslate.de',
  headers: { accept: 'application/json' }
})

export const getAvailableLanguages: GetAvailableLanguages = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: availableLanguages } = await translationAPI.get('languages')
      if (availableLanguages.length) resolve(availableLanguages)
      else reject({ error: true, message: 'Languages not found.' })
    } catch (error) {
      reject(error)
    }
  })
}

export const detectSourceLanguage: DetectSourceLanguage = (text) => {
  return (availableLanguages) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: [sourceLanguage]
        } = await translationAPI.post('detect', {
          params: { q: text }
        })

        if ('language' in sourceLanguage) resolve({ sourceLanguage, availableLanguages })
        else reject({ error: true, message: 'Impossible to detect source language.' })
      } catch (error) {
        reject(error)
      }
    })
  }
}

export const checkLanguageAvailability: CheckLanguageAvailability = ({ from, to }) => {
  return ({ sourceLanguage, availableLanguages }) => {
    return new Promise((resolve, reject) => {
      try {
        const findLanguage = (languageToCheck: string | undefined) => {
          return availableLanguages.find(({ code }) => {
            return code === languageToCheck
          })
        }
        const isDetectedLanguageAvailable = findLanguage(sourceLanguage.language)
        const isSourceLanguageAvailable = findLanguage(from)
        const isTargetLanguageAvailable = findLanguage(to)

        if (!isDetectedLanguageAvailable || (from && !isSourceLanguageAvailable)) {
          reject({ error: true, message: 'Source language is not supported.' })
        }
        if (!isTargetLanguageAvailable) {
          reject({ error: true, message: 'Target language is not supported.' })
        }

        resolve({ sourceLanguage, availableLanguages })
      } catch (error) {
        reject(error)
      }
    })
  }
}

export const translateDynamicText: TranslateDynamicText = ({ text, from, to }) => {
  return ({ sourceLanguage, availableLanguages }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { data: translation } = await translationAPI.post('translate', {
          params: { q: text, source: from || sourceLanguage.language, target: to }
        })

        if ('translatedText' in translation) {
          resolve({
            translation: translation.translatedText,
            sourceLanguage: from || sourceLanguage,
            availableLanguages
          })
        } else {
          reject({ error: true, message: 'Impossible to translate text.' })
        }
      } catch (error) {
        reject(error)
      }
    })
  }
}

export const createTranslationSuccessObject = (translationObject: TranslationSuccessObject) => {
  return translationObject
}

export const createTranslationErrorObject = (originalText: string) => {
  return (errorObject: TranslationErrorObject) => {
    return {
      ...errorObject,
      translation: originalText
    }
  }
}

const DEFAULT_PREFERRED_LANGUAGE = 'en-US'

export const TranslationsProvider = ({ children }: TranslationsProviderProps) => {
  const { preferredLanguage = DEFAULT_PREFERRED_LANGUAGE, isLoading } = usePreferredLanguageV2()
  const [language, country] = useMemo(() => {
    if (isLoading) return DEFAULT_PREFERRED_LANGUAGE.toLowerCase().split('-')
    return preferredLanguage.toLowerCase().split('-')
  }, [isLoading, preferredLanguage])

  const { t } = useTranslation()
  const translate: Translate = useCallback(
    (key, options) => {
      return t(key, { ...options, ns: language, lng: country })
    },
    [country, language, t]
  )

  // gets the translations recursively based on the object param
  const getTranslations = useCallback(
    (object, parentKey = '') => {
      let pathToPreviousKey = ''
      const translations = Object.entries(object).reduce((acc, [key, value]) => {
        pathToPreviousKey += !pathToPreviousKey ? parentKey : `.${parentKey}`
        let translation = ''
        const keys = Array.from(new Set(`${pathToPreviousKey}.${key}`.split('.')))
        const pathToCurrentKey = keys.join('.')

        if (typeof value === 'string') {
          translation = translate(pathToCurrentKey)
          pathToPreviousKey = ''
        }

        if (value instanceof Object) {
          return {
            ...acc,
            [key]: getTranslations(value, !pathToPreviousKey ? key : `${pathToPreviousKey}.${key}`)
          }
        }

        return {
          ...acc,
          [key]: translation.match(interpolationRegExp)
            ? (interpolationObject: Record<string, string>) => {
                return translate(pathToCurrentKey, interpolationObject)
              }
            : translation
        }
      }, {}) as TranslationsObjectContext

      return translations
    },
    [translate]
  )

  const translateDynamically: TranslateDynamically = useCallback(async ({ text, from, to }) => {
    return getAvailableLanguages()
      .then(detectSourceLanguage(text))
      .then(checkLanguageAvailability({ from, to }))
      .then(translateDynamicText({ text, from, to }))
      .then(createTranslationSuccessObject)
      .catch(createTranslationErrorObject(text))
  }, [])

  const value: ITranslationsContext = useMemo(
    () => ({
      preferredLanguage: { country, language, fullLanguageCode: preferredLanguage },
      translations: getTranslations(baseTranslationsObject),
      translateDynamically
    }),
    [country, getTranslations, language, preferredLanguage, translateDynamically]
  )

  return <TranslationsContext.Provider value={value}>{children}</TranslationsContext.Provider>
}

export const useTranslationsContext = () => useContext(TranslationsContext)
