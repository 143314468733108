import { CmsBaseUrl, EndpointFunction } from '@Utils/getEndpoints/getEndpointsDEPRECATED.types'

type GetLocalesList = { zone: string } & CmsBaseUrl

const getLocaleslist: EndpointFunction = (builder) => ({
  mock: '',
  real: builder.query<string[], GetLocalesList>({
    query: ({ cmsBaseUrl, zone }) => `${cmsBaseUrl}/contentedit/zone_${zone.toLowerCase()}/locales`,
    providesTags: ['Locales']
  })
})

export default getLocaleslist
